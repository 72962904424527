import { configureStore } from "@reduxjs/toolkit";
import reducers from "../reducers/index";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import defMiddles from "./middlewares";
// import thunk from "redux-thunk";
import baseSliceReducer from "./slices";
// import testSliceReducer from "tests/testRouteWithSlice/testSlice";
import librarySlice from "app/routes/dashboard/routes/library/slice/librarySlice";
// import fleetSlice from "app/routes/dashboard/routes/fleet/slice/fleetSlice";

import tagSlice from "app/routes/dashboard/routes/tags/slice/tagSlice";

import airlineReducer from "app/routes/dashboard/routes/airlines/slice/airlinesSlice";

import modulesReducers from "./slices/moduleSlice/moduleSlice";

import adminReducer from "app/routes/dashboard/routes/airlines/admins/slices/adminSlice";

import userReducer from "app/routes/dashboard/routes/users/slice/userSlice";

import airlineUserReducer from "app/routes/dashboard/routes/airlines/users/slice/airlineUserSlice";
// import userSlice from "app/routes/dashboard/routes/users-management/slice/userSlice";
import bugReportedslice from "app/routes/dashboard/routes/ReportedBugs/slice/bugReportedSlice";

import helpersSlice from "app/routes/dashboard/routes/helper/slice/helpersSlice";

const history = createBrowserHistory();
const JamboReducers = reducers(history);
// const routeMiddleware = routerMiddleware(history);
// const middlewares = [routeMiddleware];
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const allMiddles = [...defMiddles];

// export default configureStore({
//   reducer: {
//     ...reducers(history),
//   },
//   middleware: (getDefaults) => getDefaults().concat(allMiddles),
// })

export default configureStore({
  reducer: {
    ...JamboReducers,
    ...baseSliceReducer,
    // ...testSliceReducer,
    ...librarySlice,
    // ...fleetSlice,
    ...tagSlice,
    ...airlineReducer,
    // ...userSlice,
    ...adminReducer,
    ...modulesReducers,
    ...userReducer,
    ...airlineUserReducer,
    ...bugReportedslice,
    ...helpersSlice,
  },
  middleware: (getDefaults) =>
    getDefaults({
      serializableCheck: {
        ignoredPaths: ["library", "users"],
      },
      immutableCheck: {
        ignoredPaths: ["library"],
      },
    }).concat(allMiddles),
});

export { history };
