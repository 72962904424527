import * as R from "ramda";

export function getItemsBySizeAndPage(size, page, items) {
  if (page === 1) return R.slice(0, size, items);

  const start = (page - 1) * size;
  return R.slice(start, start + size, items);
}

export const setPaginationByAmountAndSize = (size, amount) => {
  if (amount % size === 0) return amount / size;
  return Math.floor(amount / size) + 1;
};
