import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPaginationByAmountAndSize } from "util/list-fns";
import {
  selUserList,
  selUserListSize,
  selUserPage,
  selUserSortName,
  selUserSortOrder,
  selUserTotalItems,
  setUserListSize,
  setUserPage,
  setUserSortName,
  setUserSortOrder,
} from "../slice/userSlice";
import { getAllUsers } from "../slice/userThunk";
import UserTableCell from "./UserTableCell";

const useStyles = makeStyles(
  (theme) => ({
    headTitle: {
      fontWeight: "bold",
    },
    formControl: {
      minWidth: 140,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    iconEmpty: {
      height: 42,
      width: 80,
    },
    headerTitle: {
      marginTop: 12,
    },
  }),
  { index: 1 }
);

const UserTable = () => {
  const classes = useStyles();
  const users = useSelector(selUserList);
  const page = useSelector(selUserPage);
  const size = useSelector(selUserListSize);
  const totalItems = useSelector(selUserTotalItems);
  const dispatch = useDispatch();
  const pagination = {
    cur: page,
    all: setPaginationByAmountAndSize(size, totalItems),
  };
  const hangelChangePage = (e, page) => {
    dispatch(setUserPage(page));
    dispatch(getAllUsers());
  };

  const handleChangeListSize = (e) => {
    const { value } = e.target;
    dispatch(setUserListSize(value));
    dispatch(setUserPage(1));
    dispatch(getAllUsers());
  };

  return (
    <React.Fragment>
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <div className="row mb-md-3">
          <div className="col-12">
            <div className="jr-card">
              <div className="table-responsive-material">
                <table className="default-table table-unbordered table table-sm table-hover">
                  <thead className="th-border-b">
                    <tr>
                      <th></th>
                      <HeaderTH title={"Fullname"} name={"first_name"} />
                      <HeaderTH title={"Username"} name={"username"} />
                      <HeaderTH title={"Email"} name={"email"} />
                      {/* <th> Status </th> */}
                      <th> Last Login </th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user) => (
                      <UserTableCell user={user} key={user.id} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className=" d-flex flex-row justify-content-between">
              <div className=" d-flex mt-3">
                <Pagination
                  {...pagination}
                  count={pagination.all}
                  page={pagination.cur}
                  shape="rounded"
                  color="primary"
                  onChange={hangelChangePage}
                />
              </div>

              <FormControl className={`${classes.formControl}`}>
                <InputLabel id="demo-simple-select-label">
                  Shows per page
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={size}
                  onChange={handleChangeListSize}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserTable;

const HeaderTH = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { title, name, items } = props;
  const sort = useSelector(selUserSortName);
  const asc = useSelector(selUserSortOrder);
  const [showSort, setShowSort] = useState(false);

  const handleSortChange = () => {
    dispatch(setUserSortName(name));
    dispatch(setUserSortOrder(asc === ":asc" ? ":desc" : ":asc"));
    dispatch(getAllUsers());
  };

  useEffect(() => {
    setShowSort(sort === name);
  }, [sort]);

  return (
    <th onClick={handleSortChange} style={{ cursor: "pointer" }}>
      <div className={showSort ? classes.headTitle : ""}>
        {title}
        {!showSort ? (
          ""
        ) : asc === ":asc" ? (
          <i className={`ml-2 zmdi zmdi-long-arrow-up`} />
        ) : (
          <i className={`ml-2 zmdi zmdi-long-arrow-down`} />
        )}
      </div>
    </th>
  );
};
