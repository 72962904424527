import { mapFieldArrToObjSchema } from "hooks/useDValidation";

const generalForm = [
  ["first_name", "string"],
  ["last_name", "string"],
  // ["middle_name", "string"],
  ["telephone", "string"],
  ["cell_phone", "string"],
  ["username", "string"],
  ["email", "string"],
  ["gender", "string"],
  ["birth_place", "string"],
  ["birth_date", "string"],
  ["address", "string"],
  ["nationality", "string"],
];

const allGeneralForm = [...generalForm];

export const generalFormFields = mapFieldArrToObjSchema(generalForm);
export const AllUserFormFields = mapFieldArrToObjSchema(allGeneralForm);
