import loaderClient from "api/reduxLoaderApi";
import axios from "axios";
import { toast } from "react-toastify";
import { getDirectories, setDirectory } from "./helpersSlice";

export const createHelper = (data, callback = false) => async (
  dispatch,
  getState
) => {
  const client = loaderClient("post")(`/helpers`);
  const res = await client(data)({});
  if (res.error) return toast.error(res.message);
  return callback && callback(res.message.id);
};
export const editHelper = (data, directoryId, callback = false) => async (
  dispatch,
  getState
) => {
  const client = loaderClient("patch")(`/helpers/${directoryId}`);
  const res = await client(data)({});
  if (res.error) return toast.error(res.message);
  toast.info("Operation is success");
  callback && callback();
  return res.message;
};

export const assignHelper = (helperId, directoryId, callback = false) => async (
  dispatch,
  getState
) => {
  const client = loaderClient("patch")(`/front-end-directories/${directoryId}`);
  const res = await client({ helper: helperId })({});
  if (res.error) return toast.error(res.message);
  toast.info("Operation is success");
  return dispatch(getAllDirectory("page=1&limit=10"));
};

export const getDirectory = (directoryId, callback = false) => async (
  dispatch,
  getState
) => {
  const client = loaderClient("get")(`/front-end-directories/${directoryId}`);
  const res = await client({})({});
  if (res.error) return toast.error(res.message);
  return dispatch(setDirectory(res.message));
};
export const getAllDirectory = (params, callback = false) => async (
  dispatch,
  getState
) => {
  const client = loaderClient("get")(`/front-end-directories?${params} `);
  const res = await client({})({});
  if (res.error) return toast.error(res.message);
  dispatch(getDirectories(res));
  // return;
};

export const getDirectoryWithFilter = (search, callback = false) => async (
  dispatch,
  getState
) => {
  const client = loaderClient("get")(`/front-end-directories?search=${search}`);
  const res = await client({})({});
  if (res.error) return toast.error(res.message);
  dispatch(getDirectories(res));
};
export const createDirectories = (directories, callback = false) => async (
  dispatch,
  getState
) => {
  const { data } = await axios.post(
    "https://apidev.enrouteservice.com/api/front-end-directories",
    {
      directories,
    },
    {
      headers: {
        design: "Enroute Backoffice",
      },
    }
  );
};
