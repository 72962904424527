import React from "react";
import { createPortal } from "react-dom";
import SimpleLoader from "./SimpleLoader";

import { selLoaderLoading, selLoaderGlobalLoading } from "./loaderSlice";
import { DProvider, DuseSelector } from "./loaderStore";

export default function LoaderComp() {
  return createPortal(
    <>
      <DProvider>
        <LoaderApp />
      </DProvider>
    </>,
    document.getElementById("d_loader")
  );
}

const LoaderApp = (props) => {
  const loader = DuseSelector(selLoaderLoading);
  const globalLoader = DuseSelector(selLoaderGlobalLoading);
  if (!loader && !globalLoader) return <></>;
  return <SimpleLoader />;
};
