import { AppBar, Tab, Tabs } from "@material-ui/core";
import useValidation from "hooks/useDValidation";
import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import {
  AllUserFormFields,
  generalFormFields,
} from "../../slice/models/generalForm.model";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import GeneralForm from "../Form/generalForm";

const TabContainer = ({ children, dir }) => {
  return (
    <div dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </div>
  );
};

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const FullWidthTabs = (props) => {
  const [value, setValue] = useState(0);
  const { errors, checkFiled, checkValidation } = useValidation({
    data: AllUserFormFields,
    key: "userForm",
  });

  const [validations, setValidations] = useState({
    general: true,
  });
  function handleChange(event, value) {
    setValue(value);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }

  const generalValidation = useValidation({
    data: generalFormFields,
    key: "userForm",
  });

  const validators = {
    general: async function (fields) {
      const valid = await generalValidation.checkValidation(fields);
      return setValidations((prev) => ({ ...prev, ops: valid }));
    },
  };

  const { theme } = props;

  return (
    <div className="w-100">
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="on"
        >
          <Tab className="tab" label="General" />
          {/* <Tab className="tab" label="Rating" />
            <Tab className="tab" label="Other" /> */}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabContainer dir={theme.direction}>
          <GeneralForm
            validation={{ errors, checkFiled, checkValidation }}
            validators={validators}
          />
        </TabContainer>
      </SwipeableViews>
    </div>
  );
};

FullWidthTabs.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withStyles(null, { withTheme: true })(FullWidthTabs);
