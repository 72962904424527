import React from "react";
import * as rSentry from "@sentry/react";
import { useSelector } from "react-redux";
import { selUser } from "store/slices";
/* -------------------------------------------------------------------------- */
const env = process.env.NODE_ENV;
export function initSentry() {
  if (env !== "production") return;
  // Initialization
  console.group("Sentry Initialization", env);
  rSentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
  });
  console.log("Initialized");
  console.groupEnd();
  rSentry.setTag("version", env);
  rSentry.setTag("environment", env);
  /* -------------------------------------------------------------------------- */
  // Sentry.captureMessage("Info: Backoffice panel initiated");
}
export const Sentry = rSentry;

export const ErrorBoundary = ({ children }) => {
  const userAssignment = useSelector(selUser);
  console.log({ userAssignment });
  return (
    <Sentry.ErrorBoundary
      beforeCapture={(scope, error) => {
        // Capture route information
        const route = window.location.pathname;
        scope.setTag("route", route);
        // Extract component information from stack trace
        const stackLines = error?.stack?.split("\n");
        if (stackLines && stackLines.length > 1) {
          const componentName = stackLines[1].trim().replace(/^at /, "");
          scope.setTag("component", componentName);
        }
        // Capture error message
        if (error.message) {
          scope.setExtra("error_message", error.message);
        }
        if (userAssignment?.known_as)
          scope.setExtra(
            "user",
            `User Known as: ${userAssignment?.known_as} Airline code: ${userAssignment.airline?.unique_code}`
          ); // Include user data as extra in Sentry scope
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
