import { SvgIcon } from "@material-ui/core";
import React from "react";
/* -------------------------------------------------------------------------- */
export const PDFIcon = (props) => {
  return (
    <svg
      width="40px"
      height="40px"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.45172 25.8058H5.16133C3.37977 25.8058 1.93555 24.3616 1.93555 22.58C1.93555 21.5111 2.80211 20.6445 3.87102 20.6445H6.45164L6.45172 25.8058Z"
        fill="#BD4150"
      />
      <path
        d="M30.9671 0H7.09617L6.43164 1.29031V39.375H36.7736L38.0639 38.7097V7.0968L30.9671 0Z"
        fill="#ECEAEC"
      />
      <path
        d="M7.09695 36.7742V0C6.38437 0 5.80664 0.577656 5.80664 1.29031V38.7097C5.80664 39.4223 6.38437 40 7.09695 40H36.7744C37.487 40 38.0647 39.4223 38.0647 38.7097H9.03242C7.96344 38.7097 7.09695 37.8431 7.09695 36.7742Z"
        fill="#DAD8DB"
      />
      <path
        d="M38.0655 6.98602C38.0655 6.64383 37.9296 6.31562 37.6876 6.07359L31.9916 0.377891C31.7497 0.135937 31.4215 0 31.0793 0H30.9688V6.45164C30.9688 6.80789 31.2576 7.0968 31.6139 7.0968H38.0655V6.98602Z"
        fill="#DAD8DB"
      />
      <path
        d="M23.8703 24.5165H3.87031C3.64406 24.5165 3.4268 24.4777 3.22492 24.4062L2.60352 25.443V33.5941C2.60352 34.663 3.47008 35.5295 4.53898 35.5295H23.8703L25.1606 34.839V25.8068C25.1606 25.0941 24.583 24.5165 23.8703 24.5165Z"
        fill="#E15F78"
      />
      <path
        d="M3.22586 24.4073C2.47586 24.1407 1.93555 23.4236 1.93555 22.582V34.1949C1.93555 35.2638 2.80211 36.1304 3.87102 36.1304H23.871C24.5836 36.1304 25.1613 35.5527 25.1613 34.8401H5.16133C4.09242 34.8401 3.22586 33.9735 3.22586 32.9046V24.4073Z"
        fill="#DB4655"
      />
      <path
        d="M20.3497 29.6304H19.0833V28.4075H20.5148C20.8714 28.4075 21.1599 28.1186 21.1599 27.7623C21.1599 27.4061 20.8713 27.1172 20.5148 27.1172H18.4381C18.0815 27.1172 17.793 27.4061 17.793 27.7623V32.8859C17.793 33.2421 18.0816 33.531 18.4381 33.531C18.7947 33.531 19.0833 33.2421 19.0833 32.8859V30.9208H20.3497C20.7063 30.9208 20.9948 30.6319 20.9948 30.2756C20.9948 29.9194 20.7062 29.6304 20.3497 29.6304Z"
        fill="white"
      />
      <path
        d="M8.51594 27.0977H7.07883C6.90742 27.0977 6.74305 27.166 6.62203 27.2873C6.50164 27.4083 6.43406 27.5722 6.43406 27.7428H6.43359V32.9041C6.43359 33.2604 6.72219 33.5493 7.07875 33.5493C7.43531 33.5493 7.72391 33.2604 7.72391 32.9041V31.304C8.01336 31.3024 8.33156 31.301 8.51586 31.301C9.69023 31.301 10.646 30.3581 10.646 29.1995C10.6461 28.0405 9.69031 27.0977 8.51594 27.0977ZM8.51594 30.0106C8.3332 30.0106 8.0182 30.0119 7.73031 30.0134C7.72906 29.7158 7.72594 28.388 7.72594 28.388H8.51602C8.97156 28.388 9.35586 28.7597 9.35586 29.1995C9.35586 29.6392 8.97148 30.0106 8.51594 30.0106Z"
        fill="white"
      />
      <path
        d="M13.9266 27.1758H12.5084C12.337 27.1758 12.1727 27.2438 12.0516 27.3651C11.9307 27.4863 11.8627 27.6508 11.8633 27.8222L11.867 30.3197C11.867 31.0162 11.8734 32.832 11.8734 32.832C11.874 33.003 11.9427 33.1671 12.0643 33.2878C12.1846 33.4075 12.3484 33.4749 12.5186 33.4749H12.5211C12.5211 33.4749 13.5727 33.4709 13.9796 33.4639C15.4948 33.4371 16.5949 32.1172 16.5949 30.3254C16.5948 28.4415 15.5225 27.1758 13.9266 27.1758ZM13.9569 32.1735C13.7723 32.1766 13.4528 32.1795 13.1618 32.1811C13.1599 31.5905 13.1574 30.7455 13.1574 30.3196L13.1549 28.466H13.9267C15.2384 28.466 15.3046 30.0147 15.3046 30.3252C15.3045 31.2335 14.8881 32.1572 13.9569 32.1735Z"
        fill="white"
      />
      <path
        d="M15.463 21.8263C14.9652 21.8263 14.5154 21.5673 14.1991 21.0787C13.7291 20.3536 13.8527 19.5323 14.5375 18.8254C15.228 18.1121 16.6412 17.4411 18.3417 16.8933C18.8722 15.8935 19.4122 14.7232 19.9168 13.4293C20.2148 12.6648 20.4555 11.9755 20.6483 11.359C19.7984 9.8009 19.293 8.35027 19.4273 7.40832C19.564 6.45254 20.2778 5.91449 21.3388 5.97309C21.6538 5.99012 22.4055 6.15457 22.587 7.31192C22.7255 8.19684 22.4924 9.62731 22.0249 11.2529C22.9095 12.7451 24.1223 14.3435 25.3087 15.5702C27.1018 15.4609 28.6214 15.5916 29.3856 16.0528C29.9841 16.4138 30.2191 17.0025 30.0144 17.6272C29.6792 18.6482 28.8696 19.116 27.8464 18.8775C26.9852 18.6765 25.9179 17.9246 24.8355 16.8807C23.0947 17.0338 21.0458 17.3882 19.2483 17.9168C18.1753 19.8425 17.0154 21.3417 16.0414 21.714C15.8448 21.7894 15.6508 21.8263 15.463 21.8263ZM15.2816 20.3775C15.3893 20.5432 15.4485 20.5398 15.4763 20.5366C15.887 20.4947 16.5713 19.7856 17.3525 18.5869C16.8359 18.8084 16.3728 19.0484 15.9834 19.3061C15.0069 19.9529 15.2141 20.2723 15.2816 20.3775ZM26.6475 16.7954C27.1987 17.2264 27.711 17.5209 28.1395 17.6211C28.474 17.6989 28.646 17.6593 28.7884 17.2254C28.5981 16.9368 27.7885 16.7982 26.6475 16.7954ZM21.5234 12.8173C21.1031 14.0138 20.589 15.2527 20.0277 16.4139C21.2367 16.1102 22.5113 15.8711 23.7285 15.7193C22.9416 14.8275 22.1811 13.8257 21.5234 12.8173ZM21.2127 7.23707C20.7547 7.23707 20.7308 7.40879 20.7043 7.5902C20.6369 8.06684 20.8045 8.74473 21.1352 9.52629C21.427 8.13356 21.3677 7.39676 21.2259 7.23707C21.2216 7.23707 21.2172 7.23707 21.2127 7.23707Z"
        fill="#E15F78"
      />
    </svg>
  );
};
