import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: "init",
  vals: {},
  user: {},
  user_assignments: [],
  loading: false,
  token: null,
  refresh: null,
  countries: [],
};

const baseSlice = createSlice({
  name: "base",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setUserBase: (state, action) => {
      state.user = action.payload;
    },
    setUserAssignments: (state, action) => {
      state.user_assignments = action.payload || [];
    },

    setTokens: (state, action) => {
      const { token, refresh } = action.payload;
      state.token = token;
      state.refresh = refresh;
    },
    setCountries: (state, action) => {
      state.countries = action.payload;
    },
  },
});

export const { actions } = baseSlice;
export const {
  setLoading,
  setTokens,
  setUserBase,
  setUserAssignments,
  setCountries,
} = actions;

export const addTokens = ({ token, refresh }) => (dispatch) => {
  return dispatch(setTokens({ token, refresh }));
};

export const addUserBase = (user) => (dispatch) => {
  return dispatch(setUserBase(user));
};

// selectors

export const selUser = (state) => state.base.user;
export const selUserAssignments = (state) => state.base.user_assignments;
export const selBaseStatus = (state) => state.base.status;
export const selLoading = (state) => state.base.loading;
export const selToken = (state) => state.base.token;
export const selRefreshToken = (state) => state.base.refresh;
export const selAuthTokens = (state) => ({
  token: state.base.token,
  refresh: state.base.refresh,
});
export const selCountries = (state) => state.base.countries;

const reducer = {
  base: baseSlice.reducer,
};

export default reducer;
