import { downloadFileAPI } from "api/fileManagerApi";
import { PDFIcon } from "assets/images/svgs/pdf";
import React, { useEffect, useState } from "react";
/* -------------------------------------------------------------------------- */
export function Image({
  imageId,
  style,
  mimeTypeFile = "image/jpeg",
  onClick,
  width = "200px",
  height,
  objectFit = "contain",
  ...rest
}) {
  const [imageUrl, setImageUrl] = useState();
  let isClickable = Boolean(onClick);
  useEffect(() => {
    if (imageId)
      downloadFileAPI(imageId)
        .then((res) => {
          let url = URL.createObjectURL(res);
          setImageUrl(url);
        })
        .catch((err) => {});
  }, [imageId]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        ...(isClickable && { cursor: "pointer" }),
        ...style,
      }}
      onClick={onClick}
      {...rest}
    >
      {mimeTypeFile === "image/jpeg" ? (
        <img
          src={imageUrl}
          width={width}
          style={{ borderRadius: 4, width: "100%", height: "100%", objectFit }}
        />
      ) : mimeTypeFile === "application/pdf" ? (
        <div
          style={{
            width: "48px",
            height: "48px",
            borderRadius: "100%",
            background: "white",
          }}
        >
          <PDFIcon
            style={{ width: "32px !important", height: "32px !important" }}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
