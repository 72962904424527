import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
} from "../constants/ActionTypes";
import { setLoaderApp } from "store/loader/loaderSlice";

import loaderClient from "api/reduxLoaderApi";
import { addTokens, addUserBase, setUserAssignments } from "store/slices";
import { toast } from "react-toastify";
import axiosInstance from "api/axiosConfig";
// import { data } from "app/routes/dashboard/routes/ECommerce/data";
// import { useHistory } from "react-router";
const setLoading = setLoaderApp;

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const userSignUp = ({ name, email, password }) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axiosInstance
      .post("/auth/register", {
        email: email,
        password: password,
        name: name,
      })
      .then(({ data }) => {
        if (data.result) {
          localStorage.setItem(
            "token",
            JSON.stringify(data.token.access_token)
          );
          axiosInstance.defaults.headers.common["Authorization"] =
            "Bearer " + data.token.access_token;
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_TOKEN_SET, payload: data.token.access_token });
          dispatch({ type: USER_DATA, payload: data.user });
        } else {
          dispatch({ type: FETCH_ERROR, payload: "Network Error" });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error**:", error.message);
      });
  };
};

export const userSignIn2 = ({ email, password }) => async (dispatch) => {
  const signInUser = await loaderClient("post")("/auth/login")({
    credential: email,
    password: password,
  })({
    headers: {
      design: "Enroute Backoffice",
    },
  });

  // signInUser.message = signInUser;

  // return "";
  if (signInUser.error) {
    toast.error("wrong username or password");
    return;
  }

  const { message } = signInUser;
  console.log({ signInUser });

  const { user, user_assignments } = message;

  if (!user.is_superadmin) {
    return toast.error("just super admin can access");
  }

  localStorage.setItem("user", message.user.username);
  localStorage.setItem("token", "Bearer " + message.tokens.access.token);
  localStorage.setItem("refresh-token", message.tokens.refresh.token);

  dispatch(addUserBase(user));
  dispatch(setUserAssignments(user_assignments));

  return dispatch(
    addTokens({
      token: message.tokens.access.token,
      refresh: message.tokens.refresh.token,
    })
  );

  // return dispatch({
  //   type: USER_TOKEN_SET,
  //   payload: message.token,
  // });
};

export const logout = (callback = false) => async (dispatch) => {
  const obj = {
    refreshToken: localStorage.getItem("refresh-token"),
  };
  const client = loaderClient("post")("/auth/logout");
  const signOut = await client(obj)({
    headers: {
      design: "Enroute Backoffice",
    },
  });
  console.log(signOut);
  if (!signOut.error || signOut.status == 404) {
    localStorage.clear();
    window.location.reload();
    return callback && callback();
  }
  // dispatch({ type: FETCH_START });
};

export const userSignIn = ({ email, password }) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axiosInstance
      .post("api/auth/login", {
        username: email,
        password: password,
      })
      .then((response) => {
        if (response.data.error === false) {
          localStorage.setItem("user", response.data.message.user.username);
          localStorage.setItem(
            "token",
            "Bearer " + response.data.message.tokens.access.token
          );
          localStorage.setItem(
            "refresh-token",
            response.data.message.tokens.refresh.token
          );
          axiosInstance.defaults.headers.common["Authorization"] =
            "Bearer " + response.data.message.tokens.access.token;
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: USER_TOKEN_SET,
            payload: response.data.message.token,
          });
          window.location.reload(false);
        } else {
          // dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.response.data.error });
        console.log("Error**:", error.message);
      });
  };
};

export const getUser = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axiosInstance
      .post("/auth/me")
      .then(({ data }) => {
        console.log("userSignIn: ", data);
        if (data.result) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_DATA, payload: data.user });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error**:", error.message);
      });
  };
};
export const forgetPassword = (email, callback = false) => {
  axiosInstance
    .post("/auth/forgot-password", {
      email,
    })
    .then(({ data }) => {
      if (data.error) {
        return toast.error(data.error);
      }
      return callback && callback();
    })
    .catch((error) => {
      console.log("Error**:", error.message);
    });
};
