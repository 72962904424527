import React from "react";
import SettingCategoryItem from "./SettingCategoryItem";
import { makeStyles } from "@material-ui/styles";
import { Row } from "reactstrap";

const useStyles = makeStyles({
  header: {
    height: 136,
  },
  headerSection: {
    width: "100%",
  },
  headerTitle: {
    borderBottom: "1px solid black",
    width: "100%",
    paddingBottom: 16,
  },
  items: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
});

const SettingCategory = (props) => {
  const { item } = props;
  const classes = useStyles();

  const renderItems = () => {
    return item.childs.map((child, idx) => {
      return <SettingCategoryItem child={child} key={idx} />;
    });
  };
  return (
    <React.Fragment>
      <div
        className={`page-heading  d-sm-flex flex-row justify-content-sm-between ${classes.header}`}
      >
        <div
          className={`d-sm-flex flex-column justify-content-sm-between ${classes.headerSection}`}
        >
          <h4 className={`title mb-3 mb-sm-0 ${classes.headerTitle}`}>
            {item.title}
          </h4>
          <Row className={classes.items}>{renderItems()}</Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default SettingCategory;
