import React, { useState } from "react";
import Chip from "@material-ui/core/Chip";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import ConfirmToast from "components/ConfirmToast";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { sendTagForEdit } from "../slice/tagSlice";
import { deleteTags } from "../slice/tagThunks";
import { Tooltip } from "@material-ui/core";
import AlertModal from "../../../../../../components/AlertModal";

const SingleTag = (props) => {
  const dispatch = useDispatch();
  const { item = {}, disable = false } = props;
  const [open, setOpen] = useState(false);
  const { tag = "tag" } = item;
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onFinishDeleteTag = () => {
    toast.info("Delete tag is Success");
    setOpen(false);
  };

  const deleteTag = () => {
    return dispatch(deleteTags(item.id, onFinishDeleteTag));
  };

  const handleClick = () => {
    return dispatch(sendTagForEdit(item));
  };

  return (
    <>
      <Tooltip
        title={item.description ? item.description : ""}
        enterDelay={300}
      >
        <Chip
          className="mr-2 p-2"
          icon={<EditIcon />}
          label={tag}
          onClick={handleClick}
          onDelete={handleOpen}
          deleteIcon={<CloseIcon />}
          color={disable ? "default" : "primary"}
          size="medium"
        />
      </Tooltip>
      <AlertModal
        open={open}
        handleClose={handleClose}
        title="Delete Tag"
        subTitle={`Are you sure want delete ${tag}?`}
        onAccept={deleteTag}
      />
    </>
  );
};
export default SingleTag;
