import React from "react";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  primaryToast: {
    color: "white",
  },
  closeBtn: {
    color: "white",
  },
});

const ConfirmToast = (props) => {
  const classes = useStyles();
  const { confirm, message } = props;
  const closeToast = () => {
    toast.dismiss();
  };
  return (
    <div className={classes.primaryToast}>
      <div className="pt-3 pb-3">{message}</div>
      <Button color="primary" onClick={confirm}>
        Confirm
      </Button>
      <Button
        color="primary"
        className={classes.closeBtn}
        outline={true}
        onClick={closeToast}
      >
        Cancel
      </Button>
    </div>
  );
};

export default ConfirmToast;
