import { mapFieldArrToObjSchema } from "hooks/useDValidation";
import * as R from "ramda";
const userForm = [
  ["first_name", "string"],
  ["last_name", "string"],
  ["cell_phone", "string"],
  ["telephone", "string"],
  ["username", "string"],
  ["email", "string"],
  ["password", "string"],
  ["code", "string"],
];

const adminForm = [
  ["first_name", "string"],
  ["last_name", "string"],
  // ["cell_phone", "string"],
  // ["telephone", "string"],
  ["username", "string"],
  ["email", "string"],
  ["password", "string"],
  ["crew_id", "string"],
  ["code", "string"],
];

const allGeneralForm = [...userForm];

export const userFormFields = mapFieldArrToObjSchema(userForm);
export const AllUserFormFields = mapFieldArrToObjSchema(allGeneralForm);
export const AllAdminFormFields = mapFieldArrToObjSchema(adminForm);
