/* eslint-disable react/jsx-no-bind */
import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { makeStyles } from "@material-ui/styles";
import "date-fns";
import React, { useEffect, useState } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

import {
  Avatar,
  CircularProgress,
  ClickAwayListener,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  TextField,
  Tooltip,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { downloadFileAPI } from "api/fileManagerApi";
import ImageUpload from "app/components/cropper";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
  sortableHandle,
} from "react-sortable-hoc";
import { toast } from "react-toastify";
import { selCountries } from "store/slices";
import { getCountries } from "store/slices/thunks";
import { useDebounce } from "util/debounceRamda";
import { selAdminForm } from "../../admins/slices/adminSlice";
import { EDITAIRLINE, NEWAIRLINE } from "../../slice/airlineConstants";
import {
  getAirports,
  patchAirline,
  patchHomebase,
  postAirline,
  retrieveAirline,
} from "../../slice/airlineThunks";
import {
  addAirlineHomebase,
  removeAirlineHomebase,
  selAirlinesForm,
  selAirlinesStatus,
  selAirports,
  setAirlineFormFiled,
  setAirlineHomebase,
} from "../../slice/airlinesSlice";
import { debounce } from "lodash";
const useStyles = makeStyles({
  titleStatus: {
    fontWeight: "bold",
  },
  selectBox: {
    position: "absolute",
    backgroundColor: "white",
    zIndex: 100,
    width: "100%",
    border: "1px solid lightgrey",
    borderRadius: 8,
    padding: 8,
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    backgroundColor: "white",
    zIndex: 100,
    width: "100%",
    border: "1px solid lightgrey",
    borderRadius: 8,
    height: 60,
    alignItems: "center",
  },
  error: {
    color: "red",
    fontSize: 10,
  },
  logoImg: {
    width: "200px",
    height: "200px",
    borderRadius: "50%",
  },
  logoSetting: {
    textAlign: "center",
  },
  uploadLogo: {
    display: "flex",
    marginTop: "15px",
    justifyContent: "center",
  },
  autocomplete: {
    "& .MuiInputBase-root": {
      padding: "0px",
    },
  },
  airportPaper: {
    paddingBlock: "10px",
    marginTop: "6px",
    paddingLeft: "4px",
  },
  homebaseNumber: {
    display: "flex",
    alignItems: "center",
  },
});

const GeneralForm = (props) => {
  useEffect(() => {
    dispatch(getAirports());
  }, []);

  // init and hooks
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { id } = useParams();
  const [homebaseValue, setHomebaseValue] = useState("");
  const [homebaseObject, setHomebaseObject] = useState({});
  const [logoUrl, setLogoUrl] = useState();
  const userForm = useSelector(selAdminForm);
  // props
  const { validation, validators, valid } = props;
  const { errors, airlineCheckValidation, adminCheckValidation } = validation;
  // selectors
  const form = useSelector(selAirlinesForm);
  const formStatus = useSelector(selAirlinesStatus);
  const airports = useSelector(selAirports);
  // useEffects
  useEffect(() => {
    handleChangeField("known_as")(form.name);
    if (form.logo) {
      downloadFileAPI(form.logo)
        .then((res) => {
          let url = URL.createObjectURL(res);
          setLogoUrl(url);
        })
        .catch((err) => {});
    }
  }, [form.name]);
  // methods
  const handleChangeField = (name) => {
    return function (value) {
      // checkFiled(name, value);
      if (name === "logo") {
        downloadFileAPI(value)
          .then((res) => {
            let url = URL.createObjectURL(res);
            setLogoUrl(url);
          })
          .catch((err) => {});
      }
      return dispatch(setAirlineFormFiled({ name, value }));
    };
  };

  const handleDateField = (e, value) => {
    return dispatch(setAirlineFormFiled({ name: "expiration_date", value }));
  };

  // dispatch thunks
  const onFinishEdit = () => {
    dispatch(patchHomebase(id, form.homebases, onFinishHomebase));
    history.push("/dashboard/airline");
  };

  const onFinishAdd = () => {
    toast.info("Airline create successfully");
    history.push("/dashboard/airline");
  };

  const handleCreateAirline = async () => {
    await validators.airline(form);

    const validAirline = await airlineCheckValidation(form);

    // const valid = await checkValidation(form);
    // if (!valid) return "";

    if (formStatus === EDITAIRLINE) {
      if (!validAirline) return "";
      dispatch(patchAirline(onFinishEdit));
    } else {
      if (!validAirline) return "";
      dispatch(postAirline(onFinishAdd));
    }
  };

  const onFinishHomebase = () => {
    setHomebaseValue("");
    setHomebaseObject({});
    dispatch(retrieveAirline(id));
    toast.info("Operation success");
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const data = arrayMove(form.homebases, oldIndex, newIndex);
    dispatch(setAirlineHomebase(data));
  };
  const debounceFindIndex = debounce((value) => {
    const index = airports.findIndex(
      (option) =>
        `${option.municipality} ${option.name} (${option.icao_code}/${option.iata_code})` ===
        value
    );
    console.log("airport", airports);
    if (index === -1) {
      dispatch(getAirports(value));
      console.log("getAirports(value)", getAirports(value));
    }
  }, 1000);

  return (
    <>
      <Grid container>
        <Grid xs={6}>
          <Form className="mt-3">
            <FormField
              type={"string"}
              name={"Name*"}
              onChange={handleChangeField("name")}
              value={form.name}
              error={errors.name}
            />
            <FormField
              type={"string"}
              name={"Address*"}
              onChange={handleChangeField("address")}
              value={form.address}
              error={errors.address}
              // error={errors.name}
            />
            <FormField
              type={"string"}
              name={"Callsign*"}
              onChange={handleChangeField("callsign")}
              value={form.callsign}
              error={errors.callsign}
            />
            <FormField
              type={"email"}
              name={"Email*"}
              onChange={handleChangeField("email")}
              value={form.email}
              error={errors.email}
            />
          </Form>
        </Grid>
        <Grid xs={6}>
          <FormField
            type={"string"}
            name={"Unique Code*"}
            onChange={handleChangeField("unique_code")}
            value={form.unique_code}
            error={errors.unique_code}
          />
          <FormGroup row className={classes.formGroup}>
            <Label sm={5}>Expiration Date*</Label>
            <Col sm={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  autoOk
                  size="small"
                  variant="inline"
                  format="MM/dd/yyyy"
                  inputVariant="outlined"
                  InputAdornmentProps={{ position: "end" }}
                  value={form.expiration_date}
                  placeholder="mm/dd/yyyy"
                  error={false}
                  helperText={null}
                  disablePast={true}
                  // disableFuture={true}
                  onChange={handleDateField}
                />
                {errors.expiration_date && (
                  <span className={classes.error}>
                    {errors.expiration_date}
                  </span>
                )}
              </MuiPickersUtilsProvider>
            </Col>
          </FormGroup>
          <FormField
            type={"string"}
            name={"ICAO code*"}
            onChange={handleChangeField("icao_code")}
            value={form.icao_code}
            error={errors.icao_code}
          />
          <FormField
            type={"string"}
            name={"IATA code*"}
            onChange={handleChangeField("iata_code")}
            value={form.iata_code}
            error={errors.iata_code}
          />
          <PhoneSelect
            type={"phonenumber"}
            name={"Phone number*"}
            onChange={handleChangeField("telephone")}
            value={form.telephone}
            error={errors.telephone}
          />
        </Grid>
        <Grid sm={6}>
          <div className={classes.logoSetting}>
            {!!form.logo ? (
              <img alt="logo" className={classes.logoImg} src={logoUrl} />
            ) : (
              <p>Please click to upload logo</p>
            )}

            <FormFieldUpdload
              type={"string"}
              onChange={handleChangeField("logo")}
              value={form.logo}
              // error={errors.logo}
            />
          </div>
        </Grid>
        <Grid sm={6}>
          <FormGroup row style={{ marginTop: "20px" }}>
            <Label sm={2}> Home Bases* </Label>
            <Col sm={8}>
              <Autocomplete
                className={classes.autocomplete}
                options={airports}
                inputValue={homebaseValue}
                disableClearable={true}
                onInputChange={(e, value) => {
                  debounceFindIndex(value);
                  setHomebaseValue(value);

                  // dont call api when select option, just call when typing+
                }}
                clearOnBlur={false}
                onChange={(event, newValue) => {
                  setHomebaseObject(newValue);
                }}
                getOptionLabel={(option) =>
                  `${option.municipality} ${option.name}(${option.icao_code}/${option.iata_code})`
                }
                renderOption={(option) =>
                  `${option.municipality} ${option.name}(${option.icao_code}/${option.iata_code})`
                }
                renderInput={(params) => (
                  <TextField
                    style={{ height: "35px" }}
                    {...params}
                    variant="outlined"
                  />
                )}
              />
              {errors.homebases && (
                <span className={classes.error}>{errors.homebases}</span>
              )}
            </Col>
            <IconButton
              onClick={() => {
                if (formStatus !== NEWAIRLINE) {
                  if (!!homebaseObject.id) {
                    dispatch(addAirlineHomebase(homebaseObject));
                    setHomebaseValue("");
                    setHomebaseObject({});
                  } else {
                    toast.error("Please select airport");
                  }
                } else if (
                  formStatus === NEWAIRLINE &&
                  form.homebases.length >= 1
                ) {
                  toast.error("can not add more than one item in add mode");
                } else {
                  if (!!homebaseObject.id) {
                    dispatch(addAirlineHomebase(homebaseObject));
                    setHomebaseValue("");
                    setHomebaseObject({});
                  } else {
                    toast.error("Please select airport");
                  }
                }
              }}
            >
              <CheckIcon style={{ color: "green" }} />
            </IconButton>
          </FormGroup>
          <FormGroup style={{ marginTop: "20px" }}>
            <DragAndDropZone
              onSortEnd={onSortEnd}
              useDragHandle={true}
              form={form}
              id={id}
            />
          </FormGroup>
        </Grid>
      </Grid>

      <Row style={{ marginTop: "20px" }}>
        {formStatus === EDITAIRLINE && (
          <Button
            className="mr-2"
            variant="contained"
            color="primary"
            onClick={handleCreateAirline}
          >
            edit
          </Button>
        )}

        {formStatus !== EDITAIRLINE && (
          <Button
            className="mr-2"
            variant="contained"
            color="primary"
            onClick={handleCreateAirline}
          >
            save
          </Button>
        )}

        <Link to={"/dashboard/airline"}>
          <Button className="mr-2" variant="contained">
            cancel
          </Button>
        </Link>
        {/* <Button className="mr-2" variant="contained" onClick={resetForm}>
          Reset
        </Button> */}
      </Row>
    </>
  );
};

const DragHandle = sortableHandle(() => (
  <Tooltip title="drag and drop to sort">
    <IconButton style={{ marginRight: "6px", padding: "0px" }}>
      <DragIndicatorIcon />
    </IconButton>
  </Tooltip>
));

const FormSelect = React.memo((props) => {
  const { items, name, value, onChange } = props;

  function handleChange(e) {
    const val = e.target.value;
    onChange(val);
  }

  return (
    <FormGroup row>
      <Label sm={5}>{name}</Label>
      <Col sm={6}>
        <Input
          type={"select"}
          name={name}
          value={value}
          onChange={handleChange}
          placeholder=""
        >
          {items.map((item, idx) => {
            return <option key={idx}>{item}</option>;
          })}
        </Input>
      </Col>
    </FormGroup>
  );
});

const FormField = React.memo((props) => {
  const classes = useStyles();
  const {
    error = false,
    type,
    name,
    value,
    onChange,
    readOnly = false,
  } = props;

  function handleChange(e) {
    const val = e.target.value;
    onChange(val);
  }

  return (
    <FormGroup row>
      <Label sm={5}>{name}</Label>
      <Col sm={6}>
        <Input
          type={type}
          name={name}
          value={value}
          onChange={handleChange}
          placeholder=""
          readOnly={readOnly}
        />
        {error && <span className={classes.error}>{error}</span>}
      </Col>
    </FormGroup>
  );
});

const DragAndDropZone = SortableContainer(({ form, id }) => {
  return (
    <div>
      {form?.homebases.map((el, index) => (
        <HomeBaseItem
          index={index}
          form={form}
          homebase={el}
          number={index}
          id={id}
          key={`key-${index}`}
        />
      ))}
    </div>
  );
});

const HomeBaseItem = SortableElement(
  ({ homebase, id, number, index, form }) => {
    const classes = useStyles();
    // console.log(props);
    const onFinishHomebase = () => {
      dispatch(retrieveAirline(id));
      toast.info("Operation success");
    };
    const dispatch = useDispatch();
    const formStatus = useSelector(selAirlinesStatus);
    return (
      <Row>
        <Col sm={10}>
          <div>
            <Paper elevation={6} className={classes.airportPaper}>
              <Grid alignItems="center" container sm={12}>
                <Grid item xs={12}>
                  <DragHandle />
                  <Typography variant="caption">
                    {number + 1}. {homebase?.name} ({homebase.icao_code}/
                    {homebase.iata_code})
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </Col>

        <Col sm={1} className={classes.homebaseNumber}>
          <Tooltip title="Delete airport">
            <IconButton
              onClick={() => {
                if (formStatus === EDITAIRLINE) {
                  if (form.homebases.length <= 1) {
                    toast.error("one home base is required for airline");
                  } else {
                    // const homebaseList = [...form.homebases];
                    // homebaseList.splice(number, 1);
                    // dispatch(patchHomebase(id, homebaseList, onFinishHomebase));
                    dispatch(removeAirlineHomebase(number));
                  }
                } else {
                  dispatch(removeAirlineHomebase(number));
                }
              }}
              size="small"
            >
              <ClearIcon color="secondary" />
            </IconButton>
          </Tooltip>
        </Col>
        {/* <li> {index + 1} d </li> */}
      </Row>
    );
  }
);

const FormFieldUpdload = React.memo((props) => {
  const classes = useStyles();
  const {
    error = false,
    type,
    name,
    value,
    onChange,
    readOnly = false,
  } = props;

  function handleGetLogo(data) {
    onChange(data.message.id);
  }

  return (
    <>
      <FormGroup row className={classes.uploadLogo}>
        <ImageUpload onSubmit={handleGetLogo} />
      </FormGroup>
      {error && (
        <span style={{ fontSize: "16px" }} className={classes.error}>
          Upload logo {error}
        </span>
      )}
    </>
  );
});

export default GeneralForm;

const PhoneSelect = React.memo((props) => {
  const {
    error = false,
    type,
    name,
    value,
    onChange,
    readOnly = false,
    maxLength,
    onSelect,
  } = props;
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [code, setCode] = useState("");
  const [number, setNumber] = useState("");
  const countries = useSelector(selCountries);
  const dispatch = useDispatch();

  const handleNumber = (e) => {
    setNumber(e.target.value);
    handlePhoneChange(e.target.value, code);
  };

  const handlePhoneChange = (number, code) => {
    onChange({ number, code });
  };

  function setterLoading(cond) {
    setLoading(cond);
  }

  const debouncedSearch = useDebounce(onSearch, 500);

  function handleSearch(e) {
    const { value } = e.target;
    setCode(value);
    if (value === "") {
      // onSelect("", name);
      setCode("");
    }
    return debouncedSearch(value);
  }

  const handleCloseShow = () => {
    setShow(false);
  };

  async function onSearch(payload) {
    dispatch(getCountries(setterLoading, payload));
    setShow(true);
  }

  useEffect(() => {
    setCode(value?.code);
    setNumber(value?.number);
  }, [value]);

  const classes = useStyles();
  return (
    <FormGroup className={classes.formGroup} row>
      <Label sm={5}>{name}</Label>
      <Col sm={6}>
        <Row>
          <Col sm={4}>
            <Input
              type={type}
              name={name}
              value={code}
              onChange={handleSearch}
              placeholder=""
              readOnly={readOnly}
            />
          </Col>
          <Col sm={8}>
            <Input
              type={type}
              name={name}
              onChange={handleNumber}
              value={number}
              placeholder=""
              readOnly={readOnly}
            />
          </Col>
        </Row>
        {error && <span className={classes.error}>{error}</span>}
        {loading ? (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        ) : (
          show &&
          (countries.length > 0 ? (
            <ClickAwayListener onClickAway={handleCloseShow}>
              <List className={classes.selectBox}>
                {countries.map((item) => {
                  return (
                    <ListItem
                      button
                      key={item.id}
                      onClick={() => {
                        setShow(false);
                        // onSelect(item.name, name);
                        setCode(`+${item.callingCodes[0]}`);
                        handlePhoneChange(number, `+${item.callingCodes[0]}`);
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar alt={item.name} src={item.flag} />
                      </ListItemAvatar>
                      <ListItemText
                        classes={{ primary: classes.listItemPrimary }}
                        primary={`${item.name}(+${item.callingCodes[0]})`}
                        secondary={`Region: ${item.region}`}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </ClickAwayListener>
          ) : (
            <ClickAwayListener onClickAway={handleCloseShow}>
              <List className={classes.selectBox}>
                <ListItem>
                  <ListItemText
                    classes={{ primary: classes.listItemPrimary }}
                    primary="No results found"
                  />
                </ListItem>
              </List>
            </ClickAwayListener>
          ))
        )}
      </Col>
    </FormGroup>
  );
});
