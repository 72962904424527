import React, { useEffect } from "react";
import FullWidthTabs from "../components/UIs/Tabs";
import CardBox from "components/CardBox";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { retrieveAirline } from "../slice/airlineThunks";
import { setAirlineFormInit } from "../slice/airlinesSlice";
import { getAdminPermissionGroup } from "../admins/slices/adminThunks";

const AirlineEdit = ({ props }) => {
  let { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!id) {
      dispatch(retrieveAirline(id));
      dispatch(getAdminPermissionGroup(id));
    } else {
      dispatch(setAirlineFormInit());
    }
  }, [id]);

  return (
    <React.Fragment>
      <CardBox>
        <FullWidthTabs />
      </CardBox>
    </React.Fragment>
  );
};
export default AirlineEdit;
