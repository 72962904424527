import React, { useEffect, useState } from "react";

import UserTableCell from "./AdminTableCell";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  selAdminAsc,
  selAdminGroup,
  selAdminPage,
  selUsersList,
  selAdminSort,
  selAdminsSize,
  selAdminTotalItems,
} from "../slices/adminSlice";
import * as R from "ramda";
import {
  setAdminsList,
  setAdminPage,
  sortAdminListBy,
  setAdminListSize,
} from "../slices/adminSlice";
import { getAirlineAdmins } from "../slices/adminThunks";
import { useParams } from "react-router";
import { setPaginationByAmountAndSize } from "util/list-fns";
import { AirlineLogo } from "../../airlineList/AilrlineLogo";

const useStyles = makeStyles(
  (theme) => ({
    headTitle: {
      fontWeight: "bold",
    },
    formControl: {
      minWidth: 140,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    iconEmpty: {
      height: 42,
      width: 80,
    },
  }),
  { index: 1 }
);

const UserTable = (props) => {
  const classes = useStyles();
  //   const userList = useSelector(setUsersList);
  const { user } = props;
  const totalItems = useSelector(selAdminTotalItems);
  const userList = useSelector(selUsersList);
  const size = useSelector(selAdminsSize);
  const page = useSelector(selAdminPage);
  const listSort = useSelector(selAdminSort);
  const listasc = useSelector(selAdminAsc);
  const dispatch = useDispatch();
  const group = useSelector(selAdminGroup);
  const { id } = useParams();
  const onClickPage = (e, page) => {
    dispatch(getAirlineAdmins(id, group.id, page, size));
  };

  const sortBy = (by) => {
    dispatch(sortAdminListBy(by));
  };

  const onChangeSize = (e) => {
    const { value } = e.target;
    dispatch(getAirlineAdmins(id, group.id, 1, value));
  };

  const pagination = {
    cur: page,
    all: setPaginationByAmountAndSize(size, totalItems),
  };
  console.log(8889, user);
  return (
    <div className="dashboard animated slideInUpTiny animation-duration-3">
      <div className="row mb-md-3">
        <div className="col-12">
          <div className="jr-card">
            <div className="table-responsive-material">
              <table className="default-table table-unbordered table table-sm table-hover">
                <thead className="th-border-b">
                  <tr>
                    <HeaderTH
                      name={"Logo"}
                      onClick={sortBy}
                      sorter={listSort}
                      asc={listasc}
                      title={"Logo"}
                    />
                    <HeaderTH
                      name={"FullName"}
                      onClick={sortBy}
                      sorter={listSort}
                      asc={listasc}
                      title={"FullName"}
                    />
                    <HeaderTH
                      name={"UsernName"}
                      onClick={sortBy}
                      sorter={listSort}
                      asc={listasc}
                      title={"UserName"}
                    />
                    <HeaderTH
                      name={"known_as"}
                      onClick={sortBy}
                      sorter={listSort}
                      asc={listasc}
                      title={"Known as"}
                    />
                    <HeaderTH
                      name={"Code"}
                      onClick={sortBy}
                      sorter={listSort}
                      asc={listasc}
                      title={"Code"}
                    />
                    <HeaderTH
                      name={"Email"}
                      onClick={sortBy}
                      sorter={listSort}
                      asc={listasc}
                      title={"Email"}
                    />
                    <HeaderTH
                      name={"Status"}
                      onClick={sortBy}
                      sorter={listSort}
                      asc={listasc}
                      title={"Status"}
                    />
                    <HeaderTH
                      name={"LastLogin"}
                      onClick={sortBy}
                      sorter={listSort}
                      asc={listasc}
                      title={"LastLogin"}
                    />
                  </tr>
                </thead>
                <tbody>
                  {!user ? (
                    ""
                  ) : (
                    <tr tabIndex={-1}>
                      <td>
                        <div>
                          <AirlineLogo imageId={user?.user?.image} />
                        </div>
                      </td>
                      <td>
                        <div className="user-detail">
                          <h5 className="user-name">
                            {user?.user?.first_name}
                            {user?.user?.last_name}
                          </h5>
                        </div>
                      </td>
                      <td>
                        <div className="user-detail">
                          <h5 className="user-name">
                            {" "}
                            {user?.user?.username}{" "}
                          </h5>
                        </div>
                      </td>
                      <td>
                        <div className="user-detail">
                          <h5 className="user-name"> {user?.known_as} </h5>
                        </div>
                      </td>
                      <td>
                        <div className="user-detail">
                          <h5 className="user-name"> {user?.code} </h5>
                        </div>
                      </td>
                      <td>
                        <div className="user-detail">
                          <h5 className="user-name"> {user?.email} </h5>
                        </div>
                      </td>
                      <td>
                        <div className="user-detail">
                          <Chip
                            size="small"
                            className={
                              user.is_active
                                ? classes.activeChip
                                : classes.deletedChip
                            }
                            label={
                              R.prop("is_active")(user) ? "Active" : "Inactive"
                            }
                            color={
                              R.prop("is_active")(user) ? "primary" : "default"
                            }
                          />
                        </div>
                      </td>
                      <td>
                        <div className="user-detail">
                          <h5>{user?.user?.last_login}</h5>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const HeaderTH = (props) => {
  const classes = useStyles();
  const { title, name, sorter, asc, onClick } = props;
  const showSort = name === sorter;

  function onHeaderClick() {
    return onClick([name]);
  }
  return (
    <th onClick={onHeaderClick}>
      <div className={showSort ? classes.headTitle : ""}>
        {title}
        {!showSort ? (
          ""
        ) : asc ? (
          <i className={`ml-2 zmdi zmdi-long-arrow-up`} />
        ) : (
          <i className={`ml-2 zmdi zmdi-long-arrow-down`} />
        )}
      </div>
    </th>
  );
};

export default UserTable;
