import { BASE_URL } from "api/axiosConfig";
import React from "react";
import img from "../../../../../../../../assets/images/placeholder.jpg";
import * as R from "ramda";
import { Avatar, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  iconEmpty: {
    height: 42,
    width: 80,
  },
  deletedChip: {
    width: 60,
  },
  activeChip: {
    backgroundColor: "#3fa83f !important",
    width: 60,
  },
}));
const PendUserTableCell = ({ user }) => {
  const propOrNA = R.propOr("N/A");
  const classes = useStyles();
  const history = useHistory();

  const handleDetailPage = (id) => {
    history.push(`/dashboard/user/${id}`);
  };
  return (
    <tr tabIndex={-1}>
      <td>
        <div>
          <Avatar
            alt={user.name}
            src={
              user.image
                ? `${BASE_URL}filemanager/download/public/${user.image}`
                : img
            }
          />
        </div>
      </td>
      <td>
        <div className="user-detail">
          <h5 className="user-name">
            {propOrNA("first_name")(user)}{" "}
            {user.middle_name ? user.middle_name : ""}{" "}
            {propOrNA("last_name")(user)}{" "}
          </h5>
        </div>
      </td>
      <td>
        <div className="user-detail">
          <h5 className="user-name"> {propOrNA("username")(user)} </h5>
        </div>
      </td>
      <td>
        <div className="user-detail">
          <h5 className="user-name"> {propOrNA("known_as")(user)} </h5>
        </div>
      </td>
      <td>
        <div className="user-detail">
          <h5 className="user-name"> {propOrNA("email")(user)} </h5>
        </div>
      </td>
      {/*
      <td>
         <div className="user-detail">
          <Chip
            size="small"
            className={
              data.is_active ? classes.activeChip : classes.deletedChip
            }
            label={R.prop("is_active")(data) ? "Active" : "Inactive"}
            color={R.prop("is_active")(data) ? "primary" : "default"}
          />
        </div>
      </td>
         */}
      <td>
        <Tooltip title="user detail">
          <IconButton onClick={() => handleDetailPage(user.id)} color="primary">
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      </td>
    </tr>
  );
};

export default PendUserTableCell;
