import React, { useEffect, useState } from "react";
import * as randomstring from "randomstring";
import * as R from "ramda";
import "date-fns";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Label, FormGroup, Input, Col, Form, Row } from "reactstrap";
import { makeStyles } from "@material-ui/styles";
import { useDebounce } from "util/index";
import { loaderWithSetter } from "api/reduxLoaderApi";

import { useDispatch, useSelector } from "react-redux";

import { Link, useHistory, useParams } from "react-router-dom";
import {
  selAdminForm,
  onAdminFormChange,
  selAdminFormStatus,
  setActiveTabIndex,
} from "../../slices/adminSlice";
import { EDITUSER } from "../../slices/adminConstants";
import { createAdmin, editAdmin } from "../../slices/adminThunks";
import { toast } from "react-toastify";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Avatar,
  Chip,
  ClickAwayListener,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
} from "@material-ui/core";
import { selAirlinesCurrent } from "../../../slice/airlinesSlice";
import { selCountries } from "store/slices";
import { getCountries } from "store/slices/thunks";

const useStyles = makeStyles({
  titleStatus: {
    fontWeight: "bold",
  },
  selectBox: {
    position: "absolute",
    backgroundColor: "white",
    zIndex: 100,
    width: "100%",
    border: "1px solid lightgray",
    borderRadius: 8,
    padding: 8,
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    backgroundColor: "white",
    zIndex: 100,
    width: "100%",
    border: "1px solid lightgray",
    borderRadius: 8,
    height: 60,
    alignItems: "center",
  },
  error: {
    color: "red",
    fontSize: 10,
  },
});

// const formFacilities = ["Suitable"];
// const permissions = ["admin", "ops", "crew", "user"];
const GeneralForm = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const { validators, validation } = props;
  const { errors, checkFiled, checkValidation } = validation;
  const dispatch = useDispatch();
  const userForm = useSelector(selAdminForm);
  const classes = useStyles();
  const formStatus = useSelector(selAdminFormStatus);
  const airline = useSelector(selAirlinesCurrent);
  const onChange = (name) => {
    return function (value) {
      checkFiled(value);
      dispatch(onAdminFormChange(name, value));
    };
  };

  const onChangeAirport = (airport, item) => {
    console.log(item);
    checkFiled(item, airport.icao_code);
    dispatch(onAdminFormChange(item, airport.icao_code));
    // dispatch(setAirport(airport));
  };

  const onFinishAdd = (res) => {
    history.push(`/dashboard/airline-edit/${id}`);
    toast.info("User create success");
  };
  const onFinishEdit = (res) => {
    toast.info("User edited");
    // history.push(`/dashboard/airline-edit/${id}`);
  };

  const onCreateUser = async () => {
    validators.general(userForm);
    const valid = await checkValidation(userForm);

    if (!valid) {
      return "";
    }

    //TODO
    const crew_id = randomstring.generate({
      length: 3,
      charset: "numeric",
    });

    if (formStatus === EDITUSER) {
      dispatch(editAdmin(userForm, onFinishEdit));
    } else {
      const finalUser = R.omit(["homebase_one", "job_title", "code"], userForm);
      const assignmentBody = {
        crew_id,
        code: code.toUpperCase(),
        known_as: `${finalUser.first_name} ${finalUser.last_name}`,
        email: userForm.email,
        homebases: [userForm.homebase_one],
      };

      dispatch(createAdmin(id, assignmentBody, finalUser, onFinishAdd));
    }
    //   ? dispatch(editUser(userForm, onfinshEdit))
    //   : dispatch(createUser(userForm, onfinshAdd));
  };

  const {
    first_name,
    middle_name,
    last_name,
    email,
    password,
    code,
    cell_phone,
    telephone,
    username,
    homebase_one,
    gender,
  } = userForm;

  // const [homebase_one_value, setHomebase_one_value] = useState({});
  // const [homebase_two_value, setHomebase_two_value] = useState({});
  // const airports = useSelector(selAirports);
  // function onChangeAirport(airport, item) {
  //   console.log(item);
  //   checkFiled(item, airport.icao_code);
  //   dispatch(onUserFormChange(item, airport.icao_code));
  //   // dispatch(setAirport(airport));
  // }

  useEffect(() => {}, []);
  return (
    <>
      <Grid xs={12}>
        <h3>Person</h3>
        <Divider className="mb-3" />
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <Form className="mt-3">
            <FormField
              type={"string"}
              name={"First Name*"}
              onChange={onChange("first_name")}
              value={first_name}
              error={errors.first_name}
            />
            <FormField
              type={"string"}
              name={"Middle Name"}
              onChange={onChange("middle_name")}
              value={middle_name}
              error={errors.middle_name}
            />
            <FormField
              type={"string"}
              name={"Last Name*"}
              onChange={onChange("last_name")}
              value={last_name}
              error={errors.last_name}
            />
            <FormField
              type={"string"}
              name={"Username*"}
              onChange={onChange("username")}
              value={username}
              error={errors.username}
            />
            {formStatus !== EDITUSER ? (
              <FormField
                type={"password"}
                name={"Password*"}
                onChange={onChange("password")}
                value={password}
                error={errors.password}
                readOnly={formStatus === EDITUSER}
              />
            ) : null}
            {formStatus !== EDITUSER ? (
              <FormField
                type={"string"}
                name={"Code*"}
                onChange={onChange("code")}
                value={code}
                error={errors.password}
                readOnly={formStatus === EDITUSER}
              />
            ) : null}
          </Form>
        </Grid>
        <Grid item xs={6}>
          <Form className="mt-3">
            <FormSelect
              name={"Gender*"}
              onChange={onChange("gender")}
              value={gender}
              items={["Female", "Male"]}
              values={["female", "male"]}
              error={errors.gender}
            />
            {formStatus !== EDITUSER ? (
              <FormSelect
                name={"Home Base One*"}
                onChange={onChange("homebase_one")}
                value={homebase_one}
                values={airline.homebases.map((el) => el.id)}
                items={airline.homebases.map(
                  (el) => `${el.name} (${el.icao_code}/${el.iata_code})`
                )}
                error={errors.homebase_one}
              />
            ) : null}
            <PhoneSelect
              type={"string"}
              name={"CellPhone*"}
              onChange={onChange("cell_phone")}
              value={cell_phone}
              error={errors.cell_phone}
            />
            <PhoneSelect
              type={"string"}
              name={"Phone*"}
              onChange={onChange("telephone")}
              value={telephone}
              error={errors.telephone}
            />
            <FormField
              type={"string"}
              name={"Email"}
              onChange={onChange("email")}
              value={email}
              error={errors.email}
            />
          </Form>
        </Grid>
      </Grid>

      {formStatus === EDITUSER && (
        <Button
          className="mr-2"
          variant="contained"
          color="primary"
          onClick={onCreateUser}
        >
          edit
        </Button>
      )}

      {formStatus !== EDITUSER && (
        <Button
          className="mr-2 "
          variant="contained"
          color="primary"
          onClick={onCreateUser}
        >
          save
        </Button>
      )}

      <Button
        onClick={() => {
          dispatch(setActiveTabIndex(1));
          history.push(`/dashboard/airline-edit/${id}`);
        }}
        className="mr-2"
        variant="contained"
      >
        cancel
      </Button>

      {/* <Button className="mr-2" variant="contained" onClick={resetForm}>
          Reset
        </Button> */}
    </>
  );
};
async function searchAirport(setter, val) {
  if (val.length < 3) return [];
  const res = await loaderWithSetter(setter)("get")(`/airports`)({
    params: { search: val },
  })({});
  if (!res.message) return [];
  return res.message;
}

const LoaderSelect = (props) => {
  const classes = useStyles();
  const { onSelect, name, value } = props;
  const [show, setShow] = useState(false);
  const [inputVal, setInputVal] = useState(value);
  const [airports, setAirports] = useState([]);

  const [loading, setLoading] = useState(false);
  function setterLoading(cond) {
    setLoading(cond);
  }

  useEffect(() => {
    setInputVal(value);
  }, [value]);

  async function onSearch(payload) {
    const items = await searchAirport(setterLoading, payload);
    setAirports(items);
    setShow(true);
  }
  const debouncedSearch = useDebounce(onSearch, 500);

  function handleSearch(e) {
    const { value } = e.target;
    setInputVal(value);
    return debouncedSearch(value);
  }

  return (
    <React.Fragment>
      <Input
        type={"string"}
        name={name}
        value={inputVal}
        onChange={handleSearch}
        placeholder=""
      />
      {loading ? (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      ) : airports.length && show > 0 ? (
        <div className={classes.selectBox}>
          {airports.map((item) => {
            return (
              <div
                key={item.id}
                onClick={() => {
                  setShow(false);
                  // debugger;
                  onSelect(item, name);
                }}
              >
                {`${item.name}--${item.icao_code}`}
              </div>
            );
          })}
        </div>
      ) : (
        false
      )}
    </React.Fragment>
  );
};

function ComboBox(props) {
  const { countries, onCountryBoxChange } = props;
  return (
    <Autocomplete
      onChange={onCountryBoxChange}
      autoComplete={true}
      id="combo-box-demo"
      options={countries}
      getOptionLabel={(option) => `${option.name}-${option.alpha2Code}`}
      style={{ width: "100%" }}
      renderInput={(params) => <TextField {...params} variant="outlined" />}
    />
  );
}

const FormSelect = React.memo((props) => {
  const {
    error = false,
    items,
    values,
    name,
    value,
    onChange,
    disabled = false,
  } = props;

  const classes = useStyles();

  function handleChange(e) {
    const val = e.target.value;
    // console.log(val);
    onChange(val);
  }

  return (
    <FormGroup row>
      <Label sm={5}>{name}</Label>
      <Col sm={6}>
        <Input
          type={"select"}
          disabled={disabled}
          name={name}
          value={value}
          onChange={handleChange}
        >
          <option value="" disabled>
            Please Select
          </option>
          {items.map((item, idx) => {
            return (
              <option value={values[idx]} key={idx}>
                {item}
              </option>
            );
          })}
        </Input>
        {error && <span className={classes.error}>{error}</span>}
      </Col>
    </FormGroup>
  );
});

const FormField = React.memo((props) => {
  const classes = useStyles();
  const {
    error = false,
    type,
    name,
    value,
    onChange,
    readOnly = false,
  } = props;

  function handleChange(e) {
    const val = e.target.value;
    onChange(val);
  }

  return (
    <FormGroup row>
      <Label sm={5}>{name}</Label>
      <Col sm={6}>
        <Input
          type={type}
          name={name}
          value={value}
          onChange={handleChange}
          placeholder=""
          readOnly={readOnly}
        />
        {error && <span className={classes.error}>{error}</span>}
      </Col>
    </FormGroup>
  );
});

const PhoneSelect = React.memo((props) => {
  const {
    error = false,
    type,
    name,
    value,
    onChange,
    readOnly = false,
  } = props;
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [code, setCode] = useState("");
  const [number, setNumber] = useState("");
  const countries = useSelector(selCountries);
  const dispatch = useDispatch();

  const handleNumber = (e) => {
    setNumber(e.target.value);
    handlePhoneChange(e.target.value, code);
  };

  const handlePhoneChange = (number, code) => {
    onChange({ number, code });
  };

  function setterLoading(cond) {
    setLoading(cond);
  }

  const debouncedSearch = useDebounce(onSearch, 500);

  function handleSearch(e) {
    const { value } = e.target;
    setCode(value);
    if (value === "") {
      // onSelect("", name);
      setCode("");
    }
    return debouncedSearch(value);
  }

  const handleCloseShow = () => {
    setShow(false);
  };

  async function onSearch(payload) {
    dispatch(getCountries(setterLoading, payload));
    setShow(true);
  }

  useEffect(() => {
    setCode(value?.code);
    setNumber(value?.number);
  }, [value]);

  const classes = useStyles();
  return (
    <FormGroup className={classes.formGroup} row>
      <Label sm={5}>{name}</Label>
      <Col sm={6}>
        <Row>
          <Col sm={4}>
            <Input
              type={type}
              name={name}
              value={code}
              onChange={handleSearch}
              placeholder=""
              readOnly={readOnly}
            />
          </Col>
          <Col sm={8}>
            <Input
              type={type}
              name={name}
              onChange={handleNumber}
              value={number}
              placeholder=""
              readOnly={readOnly}
            />
          </Col>
        </Row>
        {error && <span className={classes.error}>{error}</span>}
        {loading ? (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        ) : (
          show &&
          (countries?.length > 0 ? (
            <ClickAwayListener onClickAway={handleCloseShow}>
              <List className={classes.selectBox}>
                {countries.map((item) => {
                  return (
                    <ListItem
                      button
                      key={item.id}
                      onClick={() => {
                        setShow(false);
                        // onSelect(item.name, name);
                        setCode(`+${item.callingCodes[0]}`);
                        handlePhoneChange(number, `+${item.callingCodes[0]}`);
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar alt={item.name} src={item.flag} />
                      </ListItemAvatar>
                      <ListItemText
                        classes={{ primary: classes.listItemPrimary }}
                        primary={`${item.name}(+${item.callingCodes[0]})`}
                        secondary={`Region: ${item.region}`}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </ClickAwayListener>
          ) : (
            <ClickAwayListener onClickAway={handleCloseShow}>
              <List className={classes.selectBox}>
                <ListItem>
                  <ListItemText
                    classes={{ primary: classes.listItemPrimary }}
                    primary="No results found"
                  />
                </ListItem>
              </List>
            </ClickAwayListener>
          ))
        )}
      </Col>
    </FormGroup>
  );
});

export default GeneralForm;
