import { Chip, makeStyles } from "@material-ui/core";
import CardBox from "components/CardBox";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Col, Row } from "reactstrap";
import ContainerHeader from "../components/UIs/ContainerHeader";
import { selCurrentAdmin } from "../slices/adminSlice";
import { getAdminById } from "../slices/adminThunks";
import * as R from "ramda";
const useStyles = makeStyles({
  deletedChip: {
    width: 60,
  },
  activeChip: {
    backgroundColor: "#3fa83f !important",
    width: 60,
  },
  keyName: {
    fontWeight: "bold",
  },
  itemsSize: {
    fontSize: 18,
  },
});

const AdminDetail = () => {
  let { id, userId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAdminById(userId));
  }, []);

  const user = useSelector(selCurrentAdmin);

  const classes = makeStyles();
  const propOrNA = R.propOr("N/A");
  return (
    <>
      <ContainerHeader
        title="User Detail"
        btnTitle="Back"
        url={`/dashboard/airline-edit/${id}`}
        icon={false}
        showFilters={false}
      />
      {user ? (
        <CardBox>
          <Row>
            <Col xs="4">
              <div className={classes.itemsSize}>
                <div className="mt-2">
                  <span className={classes.keyName}>First Name:</span>{" "}
                  {propOrNA("first_name")(user)}
                </div>
                <div className="mt-2">
                  <span className={classes.keyName}>Family:</span>
                  <span> {propOrNA("last_name")(user)}</span>
                </div>
                <div className="mt-2">
                  <span className={classes.keyName}>Username:</span>
                  <span> {propOrNA("username")(user)}</span>
                </div>
                <div className="mt-2">
                  <span className={classes.keyName}>Gender:</span>
                  <span> {propOrNA("gender")(user)}</span>
                </div>

                {/* <div className="mt-2">
                  <span className={classes.keyName}>Place of Birth:</span>{" "}
                  {propOrNA("birth_place")(user)}
                </div> */}
                {/* <div className="mt-2">
                  <span className={classes.keyName}>Date of Birth:</span>{" "}
                  {propOrNA("birth_date")(user)}
                </div> */}
              </div>
            </Col>
            <Col xs="8" className={classes.itemsSize}>
              <div className="mt-2">
                <span className={classes.keyName}>Phone:</span>{" "}
                {user?.telephone?.number
                  ? user?.telephone?.code + user?.telephone?.number
                  : "N/A"}
              </div>
              <div className="mt-2">
                <span className={classes.keyName}>Cell Phone:</span>{" "}
                {user?.cell_phone?.number
                  ? user?.cell_phone?.code + user?.cell_phone?.number
                  : "N/A"}
              </div>
              <div className="mt-2">
                <span className={classes.keyName}>Email:</span>{" "}
                {propOrNA("email")(user)}
              </div>

              <div className="mt-2">
                <span className={classes.keyName}>Status:</span>
                <Chip
                  size="small"
                  className={
                    user.is_deleted ? classes.deletedChip : classes.activeChip
                  }
                  label={R.prop("is_deleted")(user) ? "Deleted" : "Active"}
                  color={R.prop("is_deleted")(user) ? "secondary" : "primary"}
                />
              </div>
            </Col>
          </Row>
        </CardBox>
      ) : (
        false
      )}
    </>
  );
};

export default AdminDetail;
