import {
  FormControl,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import { getAllUsers } from "app/routes/dashboard/routes/users/slice/userThunk";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { useDebounce } from "util/debounceRamda";
import { setAirlineFilterSearch } from "../../../slice/airlinesSlice";
import {
  selAirlineUserFilterGroup,
  selAirlineUserFilterSearch,
  selAirlineUserFilterStatus,
  selAirlineUsersGroup,
  setAirlineFilterGroup,
  setAirlineFilterPage,
  setAirlineFilterStatus,
  setAirlineUserFilterSearch,
} from "../../slice/airlineUserSlice";
import { getAirlineUsers } from "../../slice/airlineUserThunk";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  item: {
    fontSize: 18,
    cursor: "pointer",
  },
}));

const ContainerHeader = ({ title, url, showFilters, btnTitle }) => {
  const history = useHistory();
  const classes = useStyles();
  const groups = useSelector(selAirlineUsersGroup);
  const dispatch = useDispatch();
  const selectedGroup = useSelector(selAirlineUserFilterGroup);
  const status = useSelector(selAirlineUserFilterStatus);
  const search = useSelector(selAirlineUserFilterSearch);
  const { id } = useParams();

  const onSearch = (payload) => {
    console.log(payload);
    dispatch(setAirlineFilterPage(1));
    dispatch(getAirlineUsers(id));
  };

  const handleStatus = (e) => {
    const { value } = e.target;
    dispatch(setAirlineFilterStatus(value));
    dispatch(setAirlineFilterPage(1));
    dispatch(getAirlineUsers(id));
  };

  const debouncedSearch = useDebounce(onSearch, 400);

  function handleSearch(e) {
    const { value } = e.target;
    dispatch(setAirlineUserFilterSearch(value));
    debouncedSearch(value);
  }

  const handleGroup = (e) => {
    const { value } = e.target;
    dispatch(setAirlineFilterGroup(value));
    dispatch(setAirlineFilterPage(1));
    dispatch(getAirlineUsers(id));
  };

  return (
    <div className="page-heading d-sm-flex flex-row justify-content-sm-between z-index-0">
      <div className=" d-sm-flex flex-column justify-content-sm-between align-self-end">
        <h2 className="title">{title}</h2>
      </div>
      <div className="d-flex flex-column align-items-start">
        <div className="d-sm-flex flex-row justify-content-sm-between">
          {showFilters ? (
            <React.Fragment>
              <FormControl className={`${classes.formControl} mr-3`}>
                <InputLabel id="demo-simple-select-label">Filter By</InputLabel>
                <Input
                  placeholder="Search"
                  value={search}
                  onChange={handleSearch}
                />
              </FormControl>

              <FormControl className={`${classes.formControl} mr-3`}>
                <InputLabel id="demo-simple-select-label">Groups</InputLabel>
                <Select value={selectedGroup} onChange={handleGroup}>
                  <MenuItem value={"all"}>All</MenuItem>
                  {groups.map((item) => (
                    <MenuItem value={item.id}>{item.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl className={`${classes.formControl} mr-3`}>
                <InputLabel id="demo-simple-select-label">
                  User Status
                </InputLabel>
                <Select value={status} onChange={handleStatus}>
                  <MenuItem value={"all"}>All</MenuItem>
                  <MenuItem value={"is_active"}>Active</MenuItem>
                  <MenuItem value={"is_deleted"}>Deleted</MenuItem>
                </Select>
              </FormControl>
            </React.Fragment>
          ) : (
            false
          )}
        </div>
      </div>
    </div>
  );
};

export default ContainerHeader;
