import loaderClient from "api/reduxLoaderApi";
import moment from "moment";
import { toast } from "react-toastify";
import { setCurrentUser, setTotalItems, setUserList } from "./userSlice";

export const getAllUsers = () => async (dispatch, getState) => {
  const { users } = getState();
  const { filter, sort } = users;
  let params = `?limit=${filter.size}&page=${filter.page}`;
  if (!!filter.search) {
    params += `&search=${filter.search}`;
  }

  // if (!!filter.status && filter.status !== "all") {
  //   params += `&is_deleted=${filter.stats}`;
  // }

  if (!!sort.name && !!sort.order) {
    params += `&sortBy=${sort.name}${sort.order}`;
  }

  const client = loaderClient("get")(`/users${params}`);
  const res = await client({})({});
  if (res.error) return toast.error(res.message);
  dispatch(setTotalItems(res.result_number));
  return dispatch(setUserList(res.message));
};

export const getUserById = (id) => async (dispatch) => {
  const client = loaderClient("get")(`/users/${id}`);
  const res = await client({})({});
  if (res.error) return toast.error(res.message);
  return dispatch(
    setCurrentUser({
      ...res.message,
      user: {
        ...res.message.user,
        birth_date: moment(res.message.user.birth_date).format("MM/DD/YYYY"),
      },
    })
  );
};

export const editUser =
  (data, id, callback = false) =>
  async (dispatch) => {
    const client = loaderClient("patch")(`/users/${id}`);
    const res = await client(data)({});
    if (res.error) return toast.error(res.message);
    return callback && callback();
  };
