import axios, { axiosTokenInstance, invalidateUser } from "./axiosConfig";
import { isBlob } from "react-pdf/dist/umd/shared/utils";

import * as R from "ramda";
// import { toast } from "react-toastify";

// const notify = (message, opt = {}) =>
//   toast(message, {
//     position: "top-right",
//     autoClose: 2000,
//     hideProgressBar: false,
//     closeOnClick: true,
//     progress: undefined,
//     ...opt,
//   });

// const notify = console.log;

export async function getNewAccessToken() {
  try {
    const refreshToken = localStorage.getItem("refresh-token");
    const res = await axiosTokenInstance.post(
      `/auth/refresh-tokens`,
      {
        refreshToken,
      },
      {
        headers: {
          design: "Enroute Backoffice",
        },
      }
    );
    const { message } = res.data;
    localStorage.setItem("token", "Bearer " + message.access.token);
    localStorage.setItem("refresh-token", message.refresh.token);
  } catch (error) {
    invalidateUser();
  }
}
//
const isFormData = (data) => data instanceof FormData;
export const masterApi = async (method, url, data, config) => {
  if (
    method !== "get" &&
    (!data || (!isFormData(data) && !Object.keys(data)?.length))
  )
    data = config;
  let res = null;
  try {
    if (method === "get") {
      config = data;
      console.log({ url, config, data });
      res = await axios[method](url, config);
    } else if (method === "delete") {
      res = await axios[method](url, { data }); //#TODO
    } else {
      res = await axios[method](url, data, config);
    }
    // Use the headers as needed
    return isBlob(res.data) ? res.data : { ...res.data, status: res.status };
  } catch (error) {
    const { response } = error;
    return { ...response?.data, status: response?.status };
  }
};

export const masterApiWithOptions = async ({ method, url, data, params }) => {
  method = method.toLowerCase();
  let res = { data: null };
  try {
    res = await axios[method](url, data);
    return res.data;
  } catch (error) {
    return error.response && error.response;
  }
};

export const catcher = async (
  method,
  url,
  inData,
  params,
  axiosConfig,
  ...args
) => {
  const res = await masterApi(method, url, inData, params, ...args);

  if (!res) return { data: null };
  // res.message && notify(res.message);

  const { status } = res;

  switch (status) {
    case "axios_failed":
      // const notifyConcatKeyValue = (value, key) =>
      //   notify(key + ":" + R.join("|", value));
      // R.forEachObjIndexed(notifyConcatKeyValue, error);

      return { data: null, status: "axios_failed" };

    case "success":
      return { ...res, status: "success" };

    case "failed":
      return { ...res, status: "failed" };

    default:
      // notify("bad request Error ");
      return { ...res };
  }
};

export const client = async (method, url, data, params) => {
  const res = await masterApi(method, url, data, params);
  return { ...res };
};

const CatcherLoadingRedux = async (
  dispatch,
  loaderAction,
  method,
  link,
  data,
  params
) => {
  dispatch(loaderAction(true));
  const res = await client(method, link, data, params);
  dispatch(loaderAction(false));
  return { ...res };
};

const curClientLoader = R.curryN(6, CatcherLoadingRedux);

let curClient = R.curryN(4, client);

export { curClientLoader, curClient };
