import { mapFieldArrToObjSchema } from "hooks/useDValidation";

import * as R from "ramda";

export const model = {
  name: "",
  // known_as: "",
  callsign: "",
  icao_code: "",
  iata_code: "",
  logo: "",
  email: "",
  address: "",
  // phone: "",
  unique_code: "",
  expiration_date: null,
  homebases: [],
  telephone: "",
};

const formValidationsArr = [
  ["name", "string"],
  // ["known_as", "string"],
  ["callsign", "string"],
  ["icao_code", "string"],
  ["iata_code", "string"],
  // ["logo", "string"],
  ["email", "string"],
  // ["homebases", "string"],
  ["address", "string"],
  // ["phone", "string"],
  ["unique_code", "string"],
  ["expiration_date", "string"],
  ["telephone", "string"],
];
export const airLineReqFields = mapFieldArrToObjSchema(formValidationsArr);

export const pickerPropAirlines = R.pick([
  "name",
  // "known_as",
  "callsign",
  "icao_code",
  "iata_code",
  "logo",
  "email",
  "address",
  "telephone",
  "unique_code",
  "expiration_date",
  "homebases",
]);
