const { createSlice } = require("@reduxjs/toolkit");
const { userFormModel } = require("./models/userModel");

const initialState = {
  id: "",
  // list
  list: [],
  airports: [],
  //
  tags: [],
  countries: [],
  //  form
  form: userFormModel,
  current: {
    user: {},
    user_assignments: [],
  },
  sort: {
    order: ":asc",
    name: "date",
  },
  filter: {
    page: 1,
    size: 5,
    search: "",
    status: "all",
  },
  totalItems: 0,
  groups: [],
};

// const userSlice =
const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUserList: (state, action) => {
      state.list = action.payload;
    },
    setUserPage: (state, action) => {
      state.filter.page = action.payload;
    },
    setUserListSize: (state, action) => {
      state.filter.size = action.payload;
    },
    setTotalItems: (state, action) => {
      state.totalItems = action.payload;
    },
    setUserStatusFilter: (state, action) => {
      state.filter.status = action.payload;
    },
    setUserSearch: (state, action) => {
      state.filter.search = action.payload;
    },
    setUserSortName: (state, action) => {
      state.sort.name = action.payload;
    },
    setUserSortOrder: (state, action) => {
      state.sort.order = action.payload;
    },
    setCurrentUser: (state, action) => {
      state.current = action.payload;
      state.form = action.payload.user;
    },
    handleUserFormChange: (state, action) => {
      const { key, value } = action.payload;
      state.form[key] = value;
    },
  },
});

const { actions } = userSlice;

export const {
  setUserList,
  setUserListSize,
  setUserPage,
  setTotalItems,
  setUserStatusFilter,
  setUserSearch,
  setUserSortName,
  setUserSortOrder,
  setCurrentUser,
  handleUserFormChange,
} = actions;

export const onUserFormChange = (key, value) => (dispatch) => {
  return dispatch(handleUserFormChange({ key, value }));
};

//selectors
export const selUserList = (state) => state.users.list;
export const selUserPage = (state) => state.users.filter.page;
export const selUserListSize = (state) => state.users.filter.size;
export const selUserTotalItems = (state) => state.users.totalItems;
export const selUserFilterStatus = (state) => state.users.filter.status;
export const selUserFilterSearch = (state) => state.users.filter.search;
export const selUserSortName = (state) => state.users.sort.name;
export const selUserSortOrder = (state) => state.users.sort.order;
export const selCurrentUser = (state) => state.users.current;
export const selUserForm = (state) => state.users.form;

const reducer = {
  users: userSlice.reducer,
};

export default reducer;
