import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import ContainerHeader from "../components/UIs/ContainerHeader";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import EmailIcon from "@material-ui/icons/EmailRounded";
import PhoneIcon from "@material-ui/icons/PhoneRounded";
import HomeIcon from "@material-ui/icons/Home";
import { retrieveAirline } from "../slice/airlineThunks";
import { selAirlinesCurrent } from "../slice/airlinesSlice";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import DetailTabs from "../components/UIs/DetailTabs";

const AirlineDetail = ({}) => {
  return (
    <React.Fragment>
      <DetailTabs />
    </React.Fragment>
  );
};
export default AirlineDetail;
