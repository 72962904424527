const { createSlice } = require("@reduxjs/toolkit");
const { airlineUserFormModel } = require("./models/airlineUserModel");

const initialState = {
  id: "",
  // list
  list: [],
  airports: [],
  //
  tags: [],
  countries: [],
  //  form
  form: airlineUserFormModel,
  current: {
    user: {},
    user_assignments: [],
  },
  sort: {
    order: ":asc",
    name: "date",
  },
  filter: {
    page: 1,
    size: 5,
    search: "",
    status: "all",
    group: "all",
  },
  totalItems: 0,
  groups: [],
  pendingList: [],
  pendUserFilter: {
    page: 1,
    size: 5,
  },
  pendUserTotalItems: 0,
};

const airlineUserSlice = createSlice({
  name: "airlineUsers",
  initialState,
  reducers: {
    setAirlineUsersList: (state, action) => {
      state.list = action.payload;
    },
    setAirlineUsersGroup: (state, action) => {
      const groups = [...action.payload];
      const groupsWithoutUser = groups.filter(
        (group) => group.title !== "user"
      );
      state.groups = groupsWithoutUser;
    },
    setAirlineUsersTotalItems: (state, action) => {
      state.totalItems = action.payload;
    },
    setAirlineFilterPage: (state, action) => {
      state.filter.page = action.payload;
    },
    setAirlineFilterSize: (state, action) => {
      state.filter.page = action.payload;
    },
    setAirlineFilterStatus: (state, action) => {
      state.filter.status = action.payload;
    },
    setAirlineFilterGroup: (state, action) => {
      state.filter.group = action.payload;
    },
    setAirlineUserFilterSearch: (state, action) => {
      state.filter.search = action.payload;
    },
    setAirlinePendUserList: (state, action) => {
      state.pendingList = action.payload;
    },
    setAirlinePendUserTotalItems: (state, action) => {
      state.pendUserTotalItems = action.payload;
    },
    setAirlinePendUserPage: (state, action) => {
      state.pendUserFilter.page = action.payload;
    },
    setAirlinePendUserSize: (state, action) => {
      state.pendUserFilter.size = action.payload;
    },
  },
});

const { actions } = airlineUserSlice;

export const {
  setAirlineUsersList,
  setAirlineUsersGroup,
  setAirlineUsersTotalItems,
  setAirlineFilterPage,
  setAirlineFilterGroup,
  setAirlineFilterSize,
  setAirlineFilterStatus,
  setAirlineUserFilterSearch,
  setAirlinePendUserList,
  setAirlinePendUserPage,
  setAirlinePendUserSize,
  setAirlinePendUserTotalItems,
} = actions;

//selectors
export const selAirlineUserList = (state) => state.airlineUsers.list;
export const selAirlineUsersGroup = (state) => state.airlineUsers.groups;
export const selAirlineUsersTotalItems = (state) =>
  state.airlineUsers.totalItems;
export const selAirlineUserFilterPage = (state) =>
  state.airlineUsers.filter.page;
export const selAirlineUserFilterSize = (state) =>
  state.airlineUsers.filter.size;
export const selAirlineUserFilterStatus = (state) =>
  state.airlineUsers.filter.status;
export const selAirlineUserFilterGroup = (state) =>
  state.airlineUsers.filter.group;
export const selAirlineUserFilterSearch = (state) =>
  state.airlineUsers.filter.search;
export const selAirlinePendUserList = (state) => state.airlineUsers.pendingList;
export const selAirlinePendUserPage = (state) =>
  state.airlineUsers.pendUserFilter.page;
export const selAirlinePendUserSize = (state) =>
  state.airlineUsers.pendUserFilter.size;
export const selAirlinePendUserTotalItems = (state) =>
  state.airlineUsers.pendUserTotalItems;

const reducer = {
  airlineUsers: airlineUserSlice.reducer,
};

export default reducer;
