import { createSlice } from "@reduxjs/toolkit";
import * as R from "ramda";

import { remUserData, userFormModel } from "./models/userModel";

import { ALLFITLERS, EDITUSER, NEWUSER } from "./adminConstants";
const initialState = {
  status: NEWUSER,
  id: "",
  list: [],
  airports: [],
  allList: [],
  asc: true,
  sort: "date",
  filter: {
    status: "",
  },
  page: 1,
  listSize: 5,
  totalItems: 0,
  tags: [],
  contries: [],
  form: userFormModel,
  currentAdmin: {},
  group: {
    id: "",
    title: "admin",
    description: "",
  },
  activeTab: 0,
};

const sortByPropTitle = (prop, asc) =>
  asc
    ? R.sortWith([R.ascend(R.compose(R.identity, R.pathOr("N/A", prop)))])
    : R.sortWith([R.descend(R.compose(R.identity, R.pathOr("N/A", prop)))]);

const filterByPropValue = (prop, val) =>
  R.filter((item) => R.prop(prop)(item) === val);

function filterListByPath(state, action) {
  // const {path,crew} = action.payload;

  const newStatus =
    action.payload.status !== "" ? action.payload.status : ALLFITLERS;

  const isAllStatus = newStatus === ALLFITLERS;

  let list = state.allList;
  let newList = R.filter((item) => {
    const statusCondition = isAllStatus
      ? true
      : R.pathOr(null, ["is_deleted"])(item) === newStatus;
    return statusCondition;
  })(list);

  state.list = newList;
  state.filter.status = newStatus;
}

function sortingList(state, action) {
  const list = state.list;
  const sort = state.sort;
  const asc = state.asc;
  const bysort = action.payload;
  const bySortTitle = action.payload[0];

  if (sort !== bySortTitle) {
    const newSorted = sortByPropTitle(bysort, true)(list);
    state.list = newSorted;
    state.asc = true;
    state.sort = bySortTitle;
  } else {
    const newSorted = sortByPropTitle(bysort, !asc)(list);

    state.list = newSorted;
    state.asc = !asc;
  }
  state.page = 1;
}

const serachProp = (prop, val) =>
  R.compose(R.includes(val), R.toLower, R.propOr("N/A", prop));

const adminSlice = createSlice({
  name: "alirlineUsers",
  initialState,
  reducers: {
    setAdminsList: (state, action) => {
      state.list = action.payload.users;
      state.listSize = action.payload.listSize;
      state.page = action.payload.page;
      state.totalItems = action.payload.totalItems;
    },

    setAdmin: (state, action) => {
      const fixedData = remUserData(action.payload);
      const newData = { ...userFormModel, ...fixedData };
      state.form = newData;
      state.status = EDITUSER;
      state.id = action.payload.id;
    },

    setAdminPage: (state, action) => {
      state.page = action.payload;
    },
    setAdminListSize: (state, action) => {
      state.listSize = action.payload;
    },

    setTotalItems: (state, action) => {
      state.totalItems = action.payload;
    },

    filterAdminList: (state, action) => {
      const all = state.allList;
      const { name, value } = action.payload;
      if (value === "all") {
        state.list = all;
      } else {
        const filtered = filterByPropValue(name, value)(all);
        state.list = filtered;
      }
      state.page = 1;
      state.sort = "date";
      state.asc = true;
    },
    resetAdminList: (state, action) => {
      const allList = state.allList;
      state.list = allList;
      state.filter.group = "";
      state.filter.status = "";
    },

    setForminit: (state, action) => {
      state.form = userFormModel;
      state.status = NEWUSER;
      state.id = "";
    },

    handleAdminFormChange: (state, action) => {
      const { key, value } = action.payload;
      state.form[key] = value;
    },

    setActiveTabIndex: (state, action) => {
      console.log(88861, action.payload);
      state.activeTab = action.payload;
    },

    filterAdminByText: (state, action) => {
      const text = action.payload;
      let list = state.allList;
      let newList = R.filter((item) => {
        const codeCondition = serachProp("username", text)(item);
        const firstCondition = serachProp("first_name", text)(item);
        const lastCondition = serachProp("last_name", text)(item);

        return codeCondition || firstCondition || lastCondition;
      })(list);
      state.list = newList;
      state.filter.group = "";
      state.filter.status = "";
    },
    setCurrentAdmin: (state, action) => {
      state.currentAdmin = action.payload;
    },
    setAdminGroup: (state, action) => {
      state.group = action.payload;
    },
    sortUserListBy: sortingList,
    filterAdmin: filterListByPath,
  },
});

export const { actions } = adminSlice;

export const {
  setAdminsList,
  setAdmin,
  handleAdminFormChange,
  filterAdmin,
  filterAdminByText,
  setAdminListSize,
  filterAdminList,
  setAdminPage,
  sortAdminListBy,
  resetAdminList,
  setTotalItems,
  setCurrentAdmin,
  setForminit,
  setAdminGroup,
  setActiveTabIndex,
} = actions;

export const onAdminFormChange = (key, value) => (dispatch) => {
  return dispatch(handleAdminFormChange({ key, value }));
};

export const adminForminit = () => (dispatch) => {
  return dispatch(setForminit());
};
//selector
export const selUsersList = (state) => {
  return state.admins.list;
};
export const selAdminPage = (state) => state.admins.page;
export const selAdminsSize = (state) => state.admins.listSize;
export const selAdminSort = (state) => state.admins.sort;
export const selAdminAsc = (state) => state.admins.asc;
export const selAdminFormStatus = (state) => state.admins.status;
export const selAdminTableFilter = (state) => state.admins.filter;
export const selCurrentAdmin = (state) => state.admins.currentAdmin;
export const selAdminForm = (state) => state.admins.form;
export const selAdminId = (state) => state.admins.id;
export const selAdminTotalItems = (state) => state.admins.totalItems;
export const selAdminGroup = (state) => state.admins.group;
export const selActiveTabIndex = (state) => state.admins.activeTab;
const reducer = {
  admins: adminSlice.reducer,
};

export default reducer;
