import * as R from "ramda";
export const airlineUserFormModel = {
  first_name: "",
  middle_name: "",
  last_name: "",
  known_as: "",
  username: "",
  email: "",
  birth_date: "2017-05-24",
  birth_place: "",
  gender: "Male",
  address: "",
  telephone: {
    number: "",
    code: "",
  },
  cell_phone: {
    number: "",
    code: "",
  },
  nationality: "",
};

export const remUserData = R.pick([
  "first_name",
  "middle_name",
  "last_name",
  "known_as",
  "username",
  "email",
  "password",
  "birth_date",
  "birth_place",
  "gender",
  "address",
  "telephone",
  "cell_phone",
  "nationality",
]);
export const remUserDataCreate = R.pick([
  "first_name",
  "middle_name",
  "last_name",
  "known_as",
  "username",
  "email",
  "birth_date",
  "birth_place",
  "gender",
  "address",
  "telephone",
  "cell_phone",
  "nationality",
]);

export const userGeneralForm = {
  first_name: "",
  middle_name: "",
  last_name: "",
  known_as: "",
  username: "",
  email: "",
  birth_date: new Date(),
  birth_place: "",
  gender: "",
  address: "",
};
