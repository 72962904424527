import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import ContainerHeader from "../../components/UIs/ContainerHeader";
import { selAirlinesCurrent } from "../../slice/airlinesSlice";
import { getAirlinePendUsers } from "../slice/airlineUserThunk";
import AirlinePendUserTable from "./pendUserList/pendUserTable";
const AirlinePendUsers = () => {
  const airline = useSelector(selAirlinesCurrent);
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(getAirlinePendUsers(id));
  }, [id]);
  return (
    <>
      <ContainerHeader
        showFilters={false}
        btnTitle=""
        title={`${airline.name} Pend Users`}
      />
      <AirlinePendUserTable />
    </>
  );
};

export default AirlinePendUsers;
