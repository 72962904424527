import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { makeStyles } from "@material-ui/core";
import logo from "../assets/images/enroutelogo.png";
import { useDispatch } from "react-redux";
import { forgetPassword } from "actions";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles({
  logo: {
    width: "100%",
  },
  logoBg: {
    backgroundColor: "#4468c1",
  },
  mainContent: {
    width: "100%",
    height: "257px",
  },
});

const SignUp = (props) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const history = useHistory();
  const onFinish = () => {
    toast.info("Please check your email");
    history.push("signin");
  };
  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className={`app-login-main-content ${classes.mainContent}`}>
        <div
          className={`app-logo-content d-flex align-items-center justify-content-center ${classes.logoBg}`}
        >
          <Link className="logo-lg" to="/" title="Jambo">
            <img className={classes.logo} src={logo} alt="Logo" title="Logo" />
          </Link>
        </div>

        <div className="app-login-content">
          <div className="mb-4">
            <h2>
              <IntlMessages id="appModule.forgotpassword" />
            </h2>
          </div>

          <div className="app-login-form">
            <form method="post" action="/">
              <TextField
                type="email"
                onChange={(event) => setEmail(event.target.value)}
                label={<IntlMessages id="appModule.emailpass" />}
                fullWidth
                defaultValue={email}
                margin="normal"
                className="mt-0 mb-2"
              />

              <div className="mb-3 d-flex align-items-center justify-content-between">
                <Button
                  variant="contained"
                  onClick={() => {
                    forgetPassword(email, onFinish);
                  }}
                  color="primary"
                >
                  <IntlMessages id="appModule.recoveryPassword" />
                </Button>
                <Link to="/signin">
                  <IntlMessages id="appModule.signIn" />
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
