import FullWidthTabs from "../components/UIs/Tabs";
import CardBox from "components/CardBox";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { setAdminForEdit } from "../slices/adminThunks";
import { adminForminit } from "../slices/adminSlice";

const AdminEdit = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    userId ? dispatch(setAdminForEdit(userId)) : dispatch(adminForminit());
  }, [userId]);

  return (
    <>
      <CardBox>
        <FullWidthTabs />
      </CardBox>
    </>
  );
};

export default AdminEdit;
