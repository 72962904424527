/* eslint-disable react/jsx-no-bind */
import {
  Avatar,
  Button,
  CircularProgress,
  ClickAwayListener,
  FormControl,
  FormGroup,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Input, Label, Row } from "reactstrap";
import { selCountries } from "store/slices";
import { getCountries } from "store/slices/thunks";
import { useDebounce } from "util/debounceRamda";
import DateFnsUtils from "@date-io/date-fns";
import { onUserFormChange, selUserForm } from "../../slice/userSlice";
import { Link } from "react-router-dom";
import { editUser } from "../../slice/userThunk";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  titleStatus: {
    fontWeight: "bold",
  },
  selectBox: {
    position: "absolute !important",
    backgroundColor: "white",
    zIndex: 100,
    width: "100%",
    border: "1px solid lightgrey",
    borderRadius: 8,
    padding: 8,
  },
  listItemPrimary: {
    fontSize: "0.86em !important",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    backgroundColor: "white",
    zIndex: 100,
    width: "100%",
    border: "1px solid lightgrey",
    borderRadius: 8,
    height: 60,
    alignItems: "center",
  },
  error: {
    color: "red",
    fontSize: 10,
  },
  formGroup: {
    marginBottom: theme.spacing(2),
  },
}));

const GeneralForm = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const { validators, validation } = props;
  const classes = useStyles();
  const { errors, checkFiled, checkValidation } = validation;
  const userForm = useSelector(selUserForm);

  const dispatch = useDispatch();
  const today = new Date();
  const thirteenYearsAgo = new Date(
    today.getFullYear() - 13,
    today.getMonth(),
    today.getDate()
  );

  const onChange = (name) => {
    return function (value) {
      dispatch(onUserFormChange(name, value));
    };
  };

  const onChangeCountry = (countryName, item) => {
    dispatch(onUserFormChange(item, countryName));
  };

  const onEditUser = async () => {
    // validators.general(userForm);
    const valid = await checkValidation(userForm);
    if (!valid) {
      // debugger;
      return "";
    }
    dispatch(
      editUser(
        {
          first_name,
          middle_name,
          last_name,
          email,
          username,
          birth_date,
          birth_place,
          gender,
          address,
          telephone,
          cell_phone,
          nationality,
        },
        id,
        onFinishEdit
      )
    );
  };

  const onFinishEdit = () => {
    toast.success("User Edited Successfully");
    history.push("/dashboard/users");
  };

  const handleDateField = (e, value) => {
    dispatch(onUserFormChange("birth_date", value));
  };

  const {
    first_name,
    middle_name,
    last_name,
    username,
    email,
    birth_date,
    birth_place,
    gender,
    address,
    telephone,
    cell_phone,
    nationality,
  } = userForm;
  return (
    <Grid container>
      <Grid item sm={6}>
        <FormField
          name={"First Name*"}
          value={first_name}
          onChange={onChange("first_name")}
          error={errors.first_name}
          type={"string"}
        />
        <FormField
          name={"Middle Name"}
          value={middle_name}
          onChange={onChange("middle_name")}
          error={errors.middle_name}
          type={"string"}
        />
        <FormField
          name={"Last Name*"}
          value={last_name}
          onChange={onChange("last_name")}
          error={errors.last_name}
          type={"string"}
        />

        <FormField
          name={"Username*"}
          value={username}
          onChange={onChange("username")}
          error={errors.username}
          type={"string"}
        />
        <FormField
          name={"Place of Birth"}
          value={birth_place}
          onChange={onChange("birth_place")}
          error={errors.birth_place}
          type={"string"}
        />

        <FormGroup row className={classes.formGroup}>
          <Label sm={5}>Nationality*</Label>
          <Col sm={6}>
            <LoaderSelect
              name={"nationality"}
              onSelect={onChangeCountry}
              error={errors.nationality}
              value={nationality}
            />
          </Col>
        </FormGroup>

        {/* <FormField
          name={"Password"}
          value={password}
          onChange={onChange("password")}
          error={errors.password}
          type={"string"}
        /> */}
      </Grid>
      <Grid item sm={6}>
        <FormSelect
          name={"Gender*"}
          onChange={onChange("gender")}
          value={gender}
          items={["male", "female"]}
          error={errors.gender}
        />

        <FormGroup row className={classes.formGroup}>
          <Label sm={5}>DoB*</Label>
          <Col sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                autoOk
                size="small"
                variant="inline"
                format="MM/dd/yyyy"
                inputVariant="outlined"
                InputAdornmentProps={{ position: "end" }}
                value={birth_date || null}
                placeholder="mm/dd/yyyy"
                error={false}
                helperText={null}
                maxDate={thirteenYearsAgo}
                disableFuture={true}
                onChange={handleDateField}
              />
              {errors.birth_date && (
                <span className={classes.error}>{errors.birth_date}</span>
              )}
            </MuiPickersUtilsProvider>
          </Col>
        </FormGroup>
        <PhoneSelect
          name={"Cell Phone*"}
          value={cell_phone}
          onChange={onChange("cell_phone")}
          error={errors.cell_phone}
          type={"string"}
        />
        <PhoneSelect
          name={"Phone*"}
          value={telephone}
          error={errors.telephone}
          onChange={onChange("telephone")}
          type={"string"}
        />
        <FormField
          name={"Email*"}
          value={email}
          onChange={onChange("email")}
          error={errors.email}
          type={"string"}
        />
        <FormField
          name={"Address"}
          value={address}
          onChange={onChange("address")}
          error={errors.address}
          type={"string"}
        />
      </Grid>
      <Row>
        <Button
          className="mr-2 "
          variant="contained"
          color="primary"
          onClick={onEditUser}
        >
          save
        </Button>

        <Link to={"/dashboard/users"}>
          <Button className="mr-2" variant="contained">
            cancel
          </Button>
        </Link>
        {/* <Button className="mr-2" variant="contained" onClick={resetForm}>
          Reset
        </Button> */}
      </Row>
    </Grid>
  );
};

export default GeneralForm;

const FormField = React.memo((props) => {
  const classes = useStyles();
  const {
    error = false,
    type,
    name,
    value,
    onChange,
    readOnly = false,
    maxLength,
  } = props;

  function handleChange(e) {
    const val = e.target.value;
    onChange(val);
  }

  return (
    <FormGroup className={classes.formGroup} row>
      <Label sm={5}>{name}</Label>
      <Col sm={6}>
        <Input
          type={type}
          name={name}
          value={value}
          onChange={handleChange}
          placeholder=""
          readOnly={readOnly}
          maxLength={maxLength || ""}
        />
        {error && <span className={classes.error}>{error}</span>}
      </Col>
    </FormGroup>
  );
});

const FormSelect = React.memo((props) => {
  const classes = useStyles();
  const { items, name, value, readOnly = false, onChange, error } = props;
  function handleChange(e) {
    const val = e.target.value;
    // console.log(val);
    onChange(val);
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <FormGroup className={classes.formGroup} row>
      <Label sm={5}>{name}</Label>
      <Col sm={6}>
        <Input
          type={"select"}
          name={name}
          value={value}
          onChange={handleChange}
          placeholder=""
          readOnly={readOnly}
        >
          <option disabled> Select gender </option>
          {items.map((item) => (
            <option value={item}> {capitalizeFirstLetter(item)} </option>
          ))}
        </Input>
        {error && <span className={classes.error}>{error}</span>}
      </Col>
    </FormGroup>
  );
});

const LoaderSelect = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const countries = useSelector(selCountries);
  const { onSelect, name, error, value } = props;

  const [show, setShow] = useState(false);
  const [inputVal, setInputVal] = useState(value);

  const [loading, setLoading] = useState(false);
  function setterLoading(cond) {
    setLoading(cond);
  }

  useEffect(() => {
    setInputVal(value);
  }, [value]);

  async function onSearch(payload) {
    dispatch(getCountries(setterLoading, payload));
    // setCountries(items);
    setShow(true);
  }
  const debouncedSearch = useDebounce(onSearch, 500);

  function handleSearch(e) {
    const { value } = e.target;
    setInputVal(value);
    if (value === "") {
      onSelect("", name);
    }
    return debouncedSearch(value);
  }

  return (
    <React.Fragment>
      <Input
        type={"string"}
        name={name}
        value={inputVal}
        onChange={handleSearch}
        placeholder="Search country"
        autoComplete="off"
      />
      {error && <span className={classes.error}>{error}</span>}
      {loading ? (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      ) : (
        show &&
        (countries.length > 0 ? (
          <List className={classes.selectBox}>
            {countries.map((item) => {
              return (
                <ListItem
                  button
                  key={item.id}
                  onClick={() => {
                    setShow(false);
                    onSelect(item.name, name);
                    setInputVal(item.name);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar alt={item.name} src={item.flag} />
                  </ListItemAvatar>
                  <ListItemText
                    classes={{ primary: classes.listItemPrimary }}
                    primary={item.name}
                    secondary={`Region: ${item.region}`}
                  />
                </ListItem>
              );
            })}
          </List>
        ) : (
          <List className={classes.selectBox}>
            <ListItem>
              <ListItemText
                classes={{ primary: classes.listItemPrimary }}
                primary="No results found"
              />
            </ListItem>
          </List>
        ))
      )}
    </React.Fragment>
  );
};

const PhoneSelect = React.memo((props) => {
  const {
    error = false,
    type,
    name,
    value,
    onChange,
    readOnly = false,
    maxLength,
    onSelect,
  } = props;
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [code, setCode] = useState("");
  const [number, setNumber] = useState("");
  const countries = useSelector(selCountries);
  const dispatch = useDispatch();

  const handleNumber = (e) => {
    setNumber(e.target.value);
    handlePhoneChange(e.target.value, code);
  };

  const handlePhoneChange = (number, code) => {
    onChange({ number, code });
  };

  function setterLoading(cond) {
    setLoading(cond);
  }

  const debouncedSearch = useDebounce(onSearch, 500);

  function handleSearch(e) {
    const { value } = e.target;
    setCode(value);
    if (value === "") {
      // onSelect("", name);
      setCode("");
    }
    return debouncedSearch(value);
  }

  const handleCloseShow = () => {
    setShow(false);
  };

  async function onSearch(payload) {
    dispatch(getCountries(setterLoading, payload));
    setShow(true);
  }

  useEffect(() => {
    setCode(value?.code);
    setNumber(value?.number);
  }, [value]);

  const classes = useStyles();
  return (
    <FormGroup className={classes.formGroup} row>
      <Label sm={5}>{name}</Label>
      <Col sm={6}>
        <Row>
          <Col sm={4}>
            <Input
              type={type}
              name={name}
              value={code}
              onChange={handleSearch}
              placeholder=""
              readOnly={readOnly}
            />
          </Col>
          <Col sm={8}>
            <Input
              type={type}
              name={name}
              onChange={handleNumber}
              value={number}
              placeholder=""
              readOnly={readOnly}
            />
          </Col>
        </Row>
        {error && <span className={classes.error}>{error}</span>}
        {loading ? (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        ) : (
          show &&
          (countries.length > 0 ? (
            <ClickAwayListener onClickAway={handleCloseShow}>
              <List className={classes.selectBox}>
                {countries.map((item) => {
                  return (
                    <ListItem
                      button
                      key={item.id}
                      onClick={() => {
                        setShow(false);
                        // onSelect(item.name, name);
                        setCode(`+${item.callingCodes[0]}`);
                        handlePhoneChange(number, `+${item.callingCodes[0]}`);
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar alt={item.name} src={item.flag} />
                      </ListItemAvatar>
                      <ListItemText
                        classes={{ primary: classes.listItemPrimary }}
                        primary={`${item.name}(+${item.callingCodes[0]})`}
                        secondary={`Region: ${item.region}`}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </ClickAwayListener>
          ) : (
            <ClickAwayListener onClickAway={handleCloseShow}>
              <List className={classes.selectBox}>
                <ListItem>
                  <ListItemText
                    classes={{ primary: classes.listItemPrimary }}
                    primary="No results found"
                  />
                </ListItem>
              </List>
            </ClickAwayListener>
          ))
        )}
      </Col>
    </FormGroup>
  );
});
