import { createSlice } from "@reduxjs/toolkit";
import {
  MODULEAIRLINE,
  MODULEBRIFING,
  MODULEFLEET,
  MODULELIBRARY,
  MODULEOFP,
  MODULEPERFORMANCE,
  MODULEREPORT,
  MODULEUSER,
  MODULEWB,
  MODULEFLIGHT,
} from "./module.mode";

const initialState = {
  modules: {
    [MODULEFLIGHT]: {
      exist: false,
      enabled: false,
      expiration_date: new Date(),
      module: "enr:flight:module",
    },
    [MODULELIBRARY]: {
      exist: false,
      enabled: false,
      expiration_date: new Date(),
      module: "enr:library:module",
    },

    [MODULEUSER]: {
      exist: false,
      enabled: false,
      expiration_date: new Date(),
      module: "enr:user:module",
    },

    [MODULEFLEET]: {
      exist: false,
      settings: {},
      enabled: false,
      expiration_date: new Date(),
      module: "enr:fleet:module",
    },

    [MODULEOFP]: {
      exist: false,
      enabled: false,
      expiration_date: new Date(),
      module: "enr:ofp:module",
    },

    [MODULEBRIFING]: {
      exist: false,
      enabled: false,
      expiration_date: new Date(),
      module: "enr:briefing:module",
    },

    [MODULEWB]: {
      exist: false,
      enabled: false,
      expiration_date: new Date(),
      module: "enr:w&b:module",
    },

    [MODULEPERFORMANCE]: {
      exist: "",
      enabled: false,
      expiration_date: new Date(),
      module: "enr:performance:module",
    },

    [MODULEREPORT]: {
      exist: "",
      enabled: false,
      expiration_date: new Date(),
      module: "enr:report:module",
    },

    [MODULEAIRLINE]: {
      exist: "",
      enabled: false,
      expiration_date: new Date(),
      module: "enr:airline:module",
    },
  },
  airline: "",
  refresh: null,
};

const modulesSlice = createSlice({
  name: "modules",
  initialState,
  reducers: {
    setModules: (state, action) => {
      const curModules = state.modules;
      const newModules = { ...curModules, ...action.payload };
      state.modules = newModules;
    },
    setModuleSetting: (state, action) => {
      const { moduleKey, name, value } = action.payload;
      console.log("🚀 ~ file: moduleSlice.js ~ line 95 ~ value", value);
      console.log("🚀 ~ file: moduleSlice.js ~ line 95 ~ moduleKey", moduleKey);
      console.log("object");
      state.modules[moduleKey].settings = value;
    },
    setAirlineInModules: (state, action) => {
      state.airline = action.payload;
    },
  },
});

export const { actions } = modulesSlice;
export const { setModules, setModuleSetting, setAirlineInModules } = actions;

// selectors

export const selModsOBJ = (state) => state.modules.modules;

export const selModByKey = (key) => (state) => state.modules.modules[key];
export const selAirlineInModule = (state) => state.modules.airline;

const reducer = {
  modules: modulesSlice.reducer,
};

export default reducer;
