import {
  actions,
  addUserBase,
  init,
  setCountries,
  setTokens,
  setUserAssignments,
} from "./";
// import * as middleActions from "src/dRedux/actions";
// import client from "src/api/masterApi";
// import { curCatcherLoader as clienter } from "src/api/masterApi";

import loaderClient, { loaderWithSetter } from "api/reduxLoaderApi";
import { toast } from "react-toastify";

const { pendingOTP, setLoading } = actions;
// const { apiCall } = middleActions;

// export const sendFirstLoginValues = (data) => async (dispatch) => {
//   return dispatch(
//     apiCall({
//       method: "POST",
//       url: "/login",
//       data,
//       params: {},
//       onSuccess: pendingOTP.type,
//     })
//   );
// };

// export const ThunkSimple = (data) => async (dispatch) => {
//   const client = clienter(dispatch)(setLoading);
//   const res = await client("POST", "/check", data, {});
//   const { status } = res;
//   switch (status) {
//     case "verified":
//       localStorage.setItem("token", JSON.stringify(res.data.token));
//       return "";
//     // return dispatch(init(res.data.token))
//     default:
//       break;
//   }
//   if (!res.data) return "";
// };

// export const thunkWithApiMiddle = (phoneNumber) => (dispatch) => {
//   return dispatch(
//     apiCall({
//       method: "POST",
//       url: "/login",
//       data: { phone: phoneNumber },
//       params: {},
//       onSuccess: pendingOTP.type,
//     })
//   );
// };

export const initProfile =
  ({ token, refresh }) =>
  async (dispatch) => {
    dispatch(setTokens({ token, refresh }));
    const client = loaderClient("get")("/users/profile");
    const res = await client({})({});

    if (!res.message) return "";

    const { user, userAssignments } = res.message;
    dispatch(addUserBase(user));
    dispatch(setUserAssignments(userAssignments || []));
  };

export const getCountries = (setter, search) => async (dispatch) => {
  let searchValue = search?.replace("+", "");
  searchValue = searchValue?.replace("(", "");
  searchValue = searchValue?.replace(")", "");
  if (!search || searchValue?.length < 2) return;
  const res = await loaderWithSetter(setter)("get")(`/countries`)({
    params: {
      search: searchValue,
    },
  })({});
  if (res.error) return toast.error(res.message);
  return dispatch(setCountries(res.message));
};
