import { Chip, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import * as R from "ramda";
import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useHistory } from "react-router";
import { AirlineLogo } from "../../airlineList/AilrlineLogo";

const useStyles = makeStyles((theme) => ({
  iconEmpty: {
    height: 42,
    width: 80,
  },
  deletedChip: {
    width: 60,
  },
  activeChip: {
    backgroundColor: "#3fa83f !important",
    width: 60,
  },
}));

const AirlineUserTableCell = ({ data }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleDetailPage = (id) => {
    history.push(`/dashboard/user/${id}`);
  };

  const handleEditUser = (id) => {
    history.push(`/dashboard/user-edit/${id}`);
  };

  return (
    <tr tabIndex={-1}>
      <td>
        <div>
          <AirlineLogo imageId={data?.user?.image} />
        </div>
      </td>
      <td>
        <div className="user-detail">
          <h5 className="user-name">
            {data?.user?.first_name}
            {data?.user?.last_name}
          </h5>
        </div>
      </td>
      <td>
        <div className="user-detail">
          <h5 className="user-name"> {data?.user?.username} </h5>
        </div>
      </td>
      <td>
        <div className="user-detail">
          <h5 className="user-name"> {data?.known_as} </h5>
        </div>
      </td>
      <td>
        <div className="user-detail">
          <h5 className="user-name"> {data?.code} </h5>
        </div>
      </td>
      <td>
        <div className="user-detail">
          <h5 className="user-name"> {data?.email} </h5>
        </div>
      </td>
      <td>
        <div className="user-detail">
          <h5 className="user-name">
            {data.groups.map((item) => `${item.title} `)}
          </h5>
        </div>
      </td>
      <td>
        <div className="user-detail">
          <Chip
            size="small"
            className={
              data.is_active ? classes.activeChip : classes.deletedChip
            }
            label={R.prop("is_active")(data) ? "Active" : "Inactive"}
            color={R.prop("is_active")(data) ? "primary" : "default"}
          />
        </div>
      </td>
      <td>
        <div className="user-detail">
          <h5>{data?.user?.last_login}</h5>
        </div>
      </td>
      <td>
        <Tooltip title="user detail">
          <IconButton
            onClick={() => handleDetailPage(data.user.id)}
            color="primary"
          >
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      </td>
      <td>
        <Tooltip title="edit user">
          <IconButton
            onClick={() => handleEditUser(data.user.id)}
            color="primary"
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </td>
    </tr>
  );
};

export default AirlineUserTableCell;
