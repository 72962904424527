import CardBox from "components/CardBox";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import FullWidthTabs from "../components/UI/Tabs";
import { getUserById } from "../slice/userThunk";
const UserEdit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserById(id));
  }, [id]);
  return (
    <React.Fragment>
      <CardBox>
        <FullWidthTabs />
      </CardBox>
    </React.Fragment>
  );
};

export default UserEdit;
