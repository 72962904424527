import { useState } from "react";
// import { useAlert } from "react-alert";
import * as R from "ramda";
import { yupFactory } from "./CreateDYupJSon";

export default function useValidation(init) {
  // const alert = useAlert();
  const [errors, setErrors] = useState({});
  const { data, key } = init;
  const [checker, checkVal] = yupFactory(data, key);
  async function checkFiled(name, value) {
    const message = await checker(name, value);
    setErrors((prev) => ({
      ...prev,
      [name]: message,
    }));
  }

  async function checkValidation(values) {
    let [mess, allmess] = await checkVal(values);

    if (key === "airlineForm") {
      mess = airlineValidation(values, mess, allmess);
    } else if (key === "userForm") {
      mess = adminValidation(values, mess, allmess);
    }

    setErrors({ ...allmess });
    if (mess) {
      return false;
    }
    return true;
  }

  const adminValidation = (values, mess, allmess) => {
    const validPhoneRegex = RegExp(/^[0-9]+$/);
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
    const validDateRegex = RegExp(/^\d{1,2}\/\d{1,2}\/\d{4}$/);

    if (!values.telephone?.code || !values.telephone?.number) {
      allmess.telephone = "is required";
      mess += "/ is required";
    } else if (values.telephone?.number?.length < 10) {
      allmess.telephone = "phone number must be minimum 10 character";
      mess += "/ phone number must be minimum 10 character";
    } else if (!validPhoneRegex.test(values.telephone?.number)) {
      allmess.telephone = "invalid phone number";
      mess += "/ invalid phone number";
    }

    if (!values.cell_phone?.code || !values.cell_phone?.number) {
      allmess.cell_phone = "is required";
      mess += "/ is required";
    } else if (values.cell_phone.number?.length < 10) {
      allmess.cell_phone = "phone number must be minimum 10 character";
      mess += "/ phone number must be minimum 10 character";
    } else if (!validPhoneRegex.test(values.cell_phone?.number)) {
      allmess.cell_phone = "invalid phone number";
      mess += "/ invalid phone number";
    }

    if (!!values.email && !validEmailRegex.test(values.email)) {
      allmess.email = "invalid email";
      mess += "/ invalid email";
    }

    if (!!values.birth_date) {
      if (!validDateRegex.test(values.birth_date)) {
        allmess.birth_date = "valid date format is mm/dd/yyyy";
        mess += "/ invalid date format";
      } else {
        const date = new Date(values.birth_date);
        const today = new Date();
        const thirteenYearsAgo = new Date(
          today.getFullYear() - 13,
          today.getMonth(),
          today.getDate()
        ).getTime();
        const dateMillisecond = today.setHours(0, 0, 0, 0);

        // eslint-disable-next-line eqeqeq
        if (date == "Invalid Date") {
          allmess.birth_date = "invalid date";
          mess += "/ invalid date format";
        } else if (thirteenYearsAgo < date.getTime()) {
          allmess.birth_date = "invalid date";
          mess += "/ invalid date format";
        } else if (-2209001144000 >= date.getTime()) {
          allmess.birth_date = "invalid date";
          mess += "/ invalid date format";
        }
      }
    }

    setErrors({ ...allmess });
    return mess;
  };

  const airlineValidation = (values, mess, allmess) => {
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

    const validDateRegex = RegExp(/^\d{1,2}\/\d{1,2}\/\d{4}$/);

    const validPhoneRegex = RegExp(/^[\+]?[0-9]+$/);

    if (!!values.icao_code) {
      if (values.icao_code.length > 3) {
        allmess.icao_code = "icao code must have 3 characters";
        mess += "/ icao code must have 3 characters";
      }
    }

    if (!!values.iata_code) {
      if (values.iata_code.length > 2) {
        allmess.iata_code = "iata code must have 2 characters";
        mess += "/ iata code must have 2 characters";
      }
    }

    if (!!values.unique_code) {
      if (values.unique_code.length < 6) {
        allmess.unique_code = "unique code must have 6 characters";
        mess += "/ unique code must have 6 characters";
      } else if (values.unique_code.length > 12) {
        allmess.unique_code = "unique code must have maximum 12 characters";
        mess += "/ unique code must have maximum 12 characters";
      }
    }

    if (!!values.email) {
      if (!validEmailRegex.test(values.email)) {
        allmess.email = "please input valid email";
        mess += "/ please input valid email";
      }
    }

    if (!!values.homebases) {
      if (values.homebases.length === 0) {
        allmess.homebases = "homebase is require";
        mess += "/ homebase is require";
      }
    }

    if (!values.telephone?.code || !values.telephone?.number) {
      allmess.telephone = "is required";
      mess += "/ is required";
    } else if (!validPhoneRegex.test(values.telephone?.number)) {
      allmess.telephone = "invalid phone number";
      mess += "/ invalid phone number";
    } else if (!validPhoneRegex.test(values.telephone.code)) {
      allmess.telephone = "invalid area code";
      mess += "/ invalid area code";
    } else if (values.telephone.number?.length < 6) {
      allmess.telephone = "phone number must be minimum 6 character";
      mess += "/ phone number must be minimum 6 character";
    } else if (values.telephone.number?.length > 10) {
      allmess.telephone = "phone number must be maximum 10 character";
      mess += "/ phone number must be maximum 10 character";
    } else if (values.telephone.code?.length > 6) {
      allmess.telephone = "area code must be maximum 6 character";
      mess += "/ area code must be maximum 6 character";
    }

    if (!!values.expiration_date) {
      if (!validDateRegex.test(values.expiration_date)) {
        allmess.expiration_date = "valid date format is mm/dd/yyyy";
        mess += "/ invalid date format";
      } else {
        const date = new Date(values.expiration_date);

        // eslint-disable-next-line eqeqeq
        if (date == "Invalid Date") {
          allmess.expiration_date = "invalid date";
          mess += "/ invalid date format";
        } else {
          const expireMillisecond = date.getTime();
          const today = new Date();
          const dateMillisecond = today.setHours(0, 0, 0, 0);
          if (expireMillisecond <= dateMillisecond) {
            allmess.expiration_date = "invalid date";
            mess += "invalid date";
          }
        }
      }
    }

    setErrors({ ...allmess });
    return mess;
  };

  return {
    errors,
    checkFiled,
    checkValidation,
  };
}

const mapper = (field) => {
  const [name, validationType] = field;

  if (validationType === "time")
    return {
      name: `${name}`,
      validationType: "string",
      validations: [
        {
          type: "required",
          params: ["is required"],
        },
        {
          type: "matches",
          // params: [/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, "must be hh:mm"],
          params: [
            /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
            {
              message: " must be HH:MM",
              excludeEmptyString: false,
            },
          ],
        },
      ],
    };

  return {
    name: `${name}`,
    validationType,
    validations: [
      {
        type: "required",
        params: [`is required`],
      },
    ],
  };
};

export const mapFieldArrToObjSchema = R.map(mapper);
