import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import ContainerHeader from "../../UIs/ContainerHeader";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import EmailIcon from "@material-ui/icons/EmailRounded";
import PhoneIcon from "@material-ui/icons/PhoneRounded";
import HomeIcon from "@material-ui/icons/Home";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "api/axiosConfig";
import { selAirlinesCurrent } from "../../../slice/airlinesSlice";
import { retrieveAirline } from "../../../slice/airlineThunks";
const useStyles = makeStyles({
  root: {
    flexDirection: "column",
    justifyContent: "center",
    display: "flex",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  media: {
    height: 200,
    width: 200,
    display: "flex",
    justifyContent: "center",
    display: "flex",
    marginTop: 30,
    alignSelf: "center",
    borderRadius: 100,
  },
  contentDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    fontSize: 18,
  },
  homebasePapaer: {
    padding: "12px",
    marginBottom: "14px",
  },
});
const AirlineInformation = ({}) => {
  const classes = useStyles();
  let { id } = useParams();
  const dispatch = useDispatch();
  const airline = useSelector(selAirlinesCurrent);

  useEffect(() => {
    dispatch(retrieveAirline(id));
  }, [id]);

  return (
    <React.Fragment>
      {/* <ContainerHeader
        title="Airline Details"
        icon={false}
        btnTitle="Back"
        url="/dashboard/airline"
        showFilters={false}
      /> */}
      <Row>
        <Col xs="4">
          <Card className={classes.root}>
            <CardMedia
              className={classes.media}
              image={
                airline.logo
                  ? `${BASE_URL}filemanager/download/public/${airline.logo}`
                  : "https://via.placeholder.com/200x200"
              }
              title={airline.name}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {airline.name}
              </Typography>
              <Divider />
              <Typography variant="body2" color="textSecondary" component="p">
                <div className="mt-2">
                  <EmailIcon color="primary" /> Email: {airline.email}
                </div>
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <div className="mt-2">
                  <PhoneIcon color="primary" /> Phone:{" "}
                  {airline?.telephone?.number
                    ? airline?.telephone?.code + airline?.telephone?.number
                    : "N/A"}
                </div>
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <div className="mt-2">
                  <HomeIcon color="primary" /> Address: {airline.address}
                </div>
              </Typography>
            </CardContent>
            {/* <CardActions>
            <Button size="small" color="primary">
              Share
            </Button>
            <Button size="small" color="primary">
              Learn More
            </Button>
          </CardActions> */}
          </Card>
        </Col>

        <Col xs="8">
          <Card>
            <CardContent className={classes.contentDetails}>
              <Grid container>
                <Grid item sm={4}>
                  <Typography className="mt-2" component="h6">
                    Name: {airline.name}
                  </Typography>
                  <Typography className="mt-2" component="h3">
                    Known As: {airline.known_as}
                  </Typography>
                  <Typography className="mt-2" component="h3">
                    Unique Code: {airline.unique_code}
                  </Typography>
                  <Typography className="mt-2" component="h3">
                    Call Sign: {airline.callsign}
                  </Typography>
                  <Typography className="mt-2" component="h3">
                    ICAO Code: {airline.icao_code}
                  </Typography>
                  <Typography className="mt-2" component="h3">
                    IATA Code: {airline.iata_code}
                  </Typography>
                  <Typography className="mt-2" component="h3">
                    Address: {airline.address}
                  </Typography>
                  <Typography className="mt-2" component="h3">
                    Email: {airline.email}
                  </Typography>
                  <Typography className="mt-2" component="h3">
                    Phone:{" "}
                    {airline?.telephone?.number
                      ? airline?.telephone?.code + airline?.telephone?.number
                      : "N/A"}
                  </Typography>
                  <Typography className="mt-2" component="h3">
                    Status
                    <Chip
                      style={{ marginLeft: "12px" }}
                      color={airline.is_enabled ? "primary" : "default"}
                      label={airline.is_enabled ? "Active" : "Inactive"}
                    />
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  {airline.homebases.map((homebase) => (
                    <Paper className={classes.homebasePapaer}>
                      {homebase.name}({homebase.icao_code}/{homebase.iata_code})
                    </Paper>
                  ))}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default AirlineInformation;
