export const categories = [
  {
    key: "opsTable",
    title: "OPS table",
  },
  {
    key: "opsTimeline",
    title: "OPS timeline",
  },
  {
    key: "opsTimeline_newTripModal",
    title: "OPS timeline - New trip modal",
  },
  {
    key: "opsCalendar",
    title: "OPS calendar",
  },
  {
    key: "opsTable_newTripModal",
    title: "OPS - New trip",
  },
  {
    key: "opsTable_flightTab",
    title: "OPS - flight tab",
  },
  {
    key: "opsTable_TripTab",
    title: "OPS - Trip tab",
  },
  {
    key: "opsTable_ChecklistTab",
    title: "OPS - Checklist tab",
  },
  {
    key: "opsTable_CrewTab",
    title: "OPS - Crew tab",
  },
  {
    key: "opsTable_CrewTab_Assignment_CockpitTab",
    title: "OPS - Crew tab - Add assignment - Cockpit tab",
  },
  {
    key: "opsTable_CrewTab_Assignment_CabinTab",
    title: "OPS - Crew tab - Add assignment - Cabin tab",
  },
  {
    key: "opsTable_PAXTab",
    title: "OPS - PAX tab",
  },
  {
    key: "opsTable_FuelTab",
    title: "OPS - Fuel tab",
  },
  {
    key: "opsTable_FW&JLTab",
    title: "OPS - FW&JL tab",
  },
  {
    key: "opsTable_FW&JLTab_FlightWatchTab",
    title: "OPS - FW&JL tab - Flight watch tab",
  },
  {
    key: "opsTable_FW&JLTab_JourneyLogsTab",
    title: "OPS - FW&JL tab - Journey logs tab",
  },
  {
    key: "opsTable_ACFTTab",
    title: "OPS - ACFT tab",
  },
  {
    key: "opsTable_ResponseTab",
    title: "OPS - Response tab",
  },
  {
    key: "opsTable_HistoryTab",
    title: "OPS - History tab",
  },
  {
    key: "generalSettings_generalTab",
    title: "General Settings - General tab",
  },
  {
    key: "generalSettings_departmentTab",
    title: "General Settings - Department tab",
  },
  {
    key: "generalSettings_departmentTab_CreateDepartmentModal",
    title: "General Settings - Department tab -Create department modal",
  },
  {
    key: "usersManagement_usersTab",
    title: "Users Management - Users tab",
  },
  {
    key: "usersManagement_pendUsersTab",
    title: "Users Management - Pend users tab",
  },
  {
    key: "usersManagement_pendUsersTab_userDetails",
    title: "Users Management - Pend users tab - User details",
  },
  {
    key: "usersManagement_addUserForm",
    title: "Users Management - Add user form",
  },
  {
    key: "usersManagement_editUserForm_generalTab",
    title: "Users Management - Edit user Form - General tab",
  },
  {
    key: "usersManagement_editUserForm_endorsementTab",
    title: "Users Management - Edit user Form - Endorsement tab",
  },
  {
    key: "usersManagement_editUserForm_endorsementTab_assignCrew",
    title: "Users Management - Edit user Form - Endorsement tab - Assign crew",
  },
  {
    key: "usersManagement_editUserForm_ratingTab",
    title: "Users Management - Edit user Form - Rating tab",
  },
  {
    key: "usersManagement_editUserForm_ftlTab",
    title: "Users Management - Edit user Form - Ftl tab",
  },
  {
    key: "usersManagement_permissions",
    title: "Users Management - Permissions",
  },

  {
    key: "usersManagement_details",
    title: "Users Management - Details",
  },
  {
    key: "adminPrivileges_addNewGroupTab",
    title: "Admin privileges - Add new group tab",
  },
  {
    key: "adminPrivileges",
    title: "Admin privileges",
  },
  {
    key: "tags",
    title: "Tags",
  },
  {
    key: "emailTemplates",
    title: "Email Template",
  },
  {
    key: "emailTemplates_createGroupModal",
    title: "Email Template - Create group modal",
  },
  {
    key: "emailTemplates_editGroupModal",
    title: "Email Template - Edit group modal",
  },
  {
    key: "emailTemplates_selectAircraftTypeModal",
    title: "Email Template - Select aircraft type modal",
  },
  {
    key: "emailTemplates_addSubTemplateModal",
    title: "Email Templates - Add sub template",
  },
  {
    key: "messaging",
    title: "Messaging",
  },
  {
    key: "messaging_newMessagingModal",
    title: "Messaging - New messaging modal",
  },
  {
    key: "messaging_EditModal",
    title: "Messaging - Edit modal",
  },
  {
    key: "ticketing",
    title: "Ticketing",
  },
  {
    key: "ticketing_newTicketModal",
    title: "Ticketing - New ticket modal",
  },
  {
    key: "viewModules",
    title: "View Modules",
  },
  {
    key: "airports",
    title: "Airports",
  },
  {
    key: "fleets",
    title: "Fleets",
  },
  {
    key: "fleets",
    title: "Fleets",
  },
  {
    key: "fleets_addNewAirCraft_basicTab",
    title: "Fleets - Add new aircraft - Basic tab",
  },
  {
    key: "fleets_addNewAirCraft_opsTab",
    title: "Fleets - Add new aircraft - Ops tab",
  },
  {
    key: "fleets_addNewAirCraft_performanceTab",
    title: "Fleets - Add new aircraft - Performance tab",
  },
  {
    key: "fleets_editFleet_performanceTab_addDistanceModal",
    title: "Fleets - Edit fleet - Performance tab - Add distance modal",
  },
  {
    key: "fleets_editFleet_seatTab",
    title: "Fleets - Edit fleet - Seat tab",
  },
  {
    key: "fleets_editFleet_seatTab_addSeatClassModal",
    title: "Fleets - Edit fleet - Seat tab - Add new seat class modal",
  },
  {
    key: "fleets_editFleet_documentsTab",
    title: "Fleets - Edit fleet - Documents tab",
  },
  {
    key: "fleetDocumentsSetup",
    title: "Fleet document setup",
  },
  {
    key: "fleetDocumentsSetup_createDocumentModal",
    title: "Fleet document setup - Create new document modal",
  },
  {
    key: "fleetDocumentsSetup_editDocument_setupDocumentModal",
    title: "Fleet document setup - Edit document - Setup document modal",
  },
  {
    key: "seats",
    title: "Seats",
  },
  {
    key: "crewPosition",
    title: "Crew position",
  },
  {
    key: "crewPosition_cockpitTab",
    title: "Crew position - Cockpit tab ",
  },
  {
    key: "crewPosition_cabinTab",
    title: "Crew position - Cabin tab ",
  },
  {
    key: "crewPosition_maintenanceTab",
    title: "Crew position - Maintenance tab ",
  },
  {
    key: "crewPosition_groundTab",
    title: "Crew position - Ground tab ",
  },
  {
    key: "crewPosition_positioningTab",
    title: "Crew position - Positioning tab ",
  },
  {
    key: "crewCurrency",
    title: "Crew currency",
  },
  {
    key: "crewEndorsement_endorsementList",
    title: "Crew endorsement - Endorsement list",
  },
  {
    key: "crewEndorsement_endorsementList_newEndorsementModal",
    title: "Crew endorsement - Endorsement list - New endorsement ops modal",
  },
  {
    key: "crewEndorsement_endorsementList_editEndorsementModal",
    title: "Crew endorsement - Endorsement list - Edit endorsement ops modal",
  },
  {
    key: "crewEndorsement_mailingRules_newMailingRulesModal",
    title: "Crew endorsement - Mailing rules - New mailing rules modal",
  },
  {
    key: "crewEndorsement_mailingRules_editMailingRulesModal",
    title: "Crew endorsement - Mailing rules - Edit mailing rules modal",
  },
  {
    key: "crewEndorsement_mailingRules",
    title: "Crew endorsement - Mailing rules",
  },
  {
    key: "crewRatings",
    title: "Crew ratings",
  },
  {
    key: "ftlSettings",
    title: "Ftl settings",
  },
  {
    key: "dutiesSetup",
    title: "DutiesSetup",
  },
  {
    key: "dutiesSetup_newDefinitionModal",
    title: "DutiesSetup - New definition modal",
  },
  {
    key: "dutiesSetup_updateDefinitionModal",
    title: "DutiesSetup - Update definition modal",
  },
  {
    key: "flightEditing_flightTab",
    title: "Flight editing - Flight tab",
  },
  {
    key: "flightEditing_flightStatusTab",
    title: "Flight editing - Flight status tab",
  },
  {
    key: "flightEditing_delayCodesTab",
    title: "Flight editing - Delay codes tab",
  },
  {
    key: "schedule",
    title: "Schedule",
  },
  {
    key: "schedule_selectFlights",
    title: "Schedule - Select flight",
  },
  {
    key: "schedule_newScheduleModal",
    title: "Schedule - New schedule modal",
  },
  {
    key: "schedule_modifyFlightModal",
    title: "Schedule - Modify flight modal",
  },
  {
    key: "library",
    title: "Library",
  },
  {
    key: "reports",
    title: "Reports",
  },
  {
    key: "crew",
    title: "Crew",
  },
  {
    key: "crew_editDutyModal",
    title: "Crew - Edit duty modal",
  },
  {
    key: "bugReportModal",
    title: "Bug report modal",
  },
  {
    key: "flightPlanning",
    title: "Flight planning",
  },
  {
    key: "flightPlanning_newFlightModal",
    title: "Flight planning - New flight modal",
  },
  {
    key: "flightPlanning_editFlightModal",
    title: "Flight planning - Edit flight modal",
  },
];
