import React, { useEffect } from "react";
import { Input, FormGroup, Label, Col, Row } from "reactstrap";
import { Button, Divider, TextField } from "@material-ui/core";
import ContainerHeader from "./UIs/ContainerHeader";
import SingleTag from "./components/SingleTag";
import { getAllTags } from "./slice/tagThunks";
import { useDispatch, useSelector } from "react-redux";
import useValidation from "hooks/useDValidation";
import {
  resetTagForm,
  selCurentTag,
  selTagsList,
  selTagsStutus,
  sendTagForEdit,
} from "./slice/tagSlice";

import { tagsReqFields } from "./slice/models/tags.model";

import { createTag, editTag } from "./slice/tagThunks";
import { EDITTAG, NEWTAG } from "./slice/tagConstants";
import { makeStyles } from "@material-ui/styles";

import * as R from "ramda";
import { toast } from "react-toastify";

function checkTagValidation(title, tags, setter) {
  const toLowerProp = (prop) => R.compose(R.toLower, R.prop(prop));
  const status = R.find((file) => {
    const fileTitle = toLowerProp("tag")(file);
    return R.equals(fileTitle, R.toLower(R.trim(title)));
  })(tags)
    ? false
    : true;

  return setter(status);
}

const tagValidationsFields = [
  {
    name: "tag",
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["is required"],
      },
    ],
  },
  {
    name: "description",
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["is required"],
      },
    ],
  },
];
const useStyles = makeStyles({
  error: {
    color: "red",
    fontSize: 12,
  },
});

const removeItemsByIds = (byIds, items) =>
  R.filter((item) => !R.includes(item.id, byIds))(items);

const Tags = () => {
  const { errors, checkFiled, checkValidation } = useValidation({
    data: tagValidationsFields,
    key: "tag_create",
  });

  const classes = useStyles();

  const dispatch = useDispatch();

  const [tagInput, setTagInput] = React.useState("");
  const [tagDesc, setDescription] = React.useState("");
  const [checkNotSameTag, setSameTag] = React.useState(true);

  const allTags = useSelector(selTagsList);
  const tagsStatus = useSelector(selTagsStutus);
  const currentTag = useSelector(selCurentTag);

  useEffect(() => {
    dispatch(getAllTags());
  }, []);

  useEffect(() => {
    if (tagsStatus === NEWTAG) {
      setTagInput("");
      setDescription("");
    } else {
      setTagInput(currentTag.tag);
      setDescription(currentTag.description);
    }
  }, [tagsStatus, currentTag]);

  function handleChange(e) {
    checkFiled("tag", e.target.value);
    checkTagValidation(e.target.value, allTags, setSameTag);
    setTagInput(e.target.value);
    checkFiled("tag", e.target.value);
  }
  function handleDescChange(e) {
    checkFiled("description", e.target.value);
    setDescription(e.target.value);
    checkFiled("description", e.target.value);
  }

  function handelResetForm() {
    dispatch(resetTagForm());
  }

  const onFinishAddTag = () => {
    toast.info("Create tag success");
  };

  const onFinishEditTag = () => {
    toast.info("Edit tag success");
  };

  async function handleCreate() {
    const data = {
      tag: R.trim(R.toLower(tagInput)),
      description: tagDesc,
    };
    const check = await checkValidation(data);

    if (!check || !checkNotSameTag) return "";
    return tagsStatus === NEWTAG
      ? dispatch(createTag(data, onFinishAddTag))
      : dispatch(editTag(data, onFinishEditTag));
  }

  const showableTags = removeItemsByIds(["5fd1086ee136a264e2bb8735"], allTags);

  return (
    <React.Fragment>
      <ContainerHeader title="Tags" />
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <div className="row mb-md-3">
          <div className="col-12">
            <div className="jr-card">
              <FormGroup row>
                <Label className="mt-1">File Tag:</Label>
                <Col sm={2}>
                  <Input
                    type="string"
                    name="tag"
                    value={tagInput}
                    onChange={handleChange}
                    error={errors.tag || !checkNotSameTag}
                    placeholder=""
                  />
                  {errors.tag && (
                    <span className={classes.error}>
                      This field is required
                    </span>
                  )}

                  {!checkNotSameTag && (
                    <span className={classes.error}>Same tag exists</span>
                  )}
                </Col>
                <Label className="mt-1">Description:</Label>
                <Col sm={2}>
                  <Input
                    type="string"
                    name="description"
                    value={tagDesc}
                    onChange={handleDescChange}
                    error={errors.description}
                    placeholder=""
                  />
                  {errors.description && (
                    <span className={classes.error}>
                      This field is required.
                    </span>
                  )}
                </Col>
                <Col>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleCreate}
                  >
                    {tagsStatus === NEWTAG ? "Create" : "OK"}
                  </Button>
                  {tagsStatus === EDITTAG && (
                    <Button
                      className="ml-2"
                      color="default"
                      variant="outlined"
                      onClick={handelResetForm}
                    >
                      cancel
                    </Button>
                  )}
                </Col>
              </FormGroup>
              <Divider />
              <Row className="d-flex mt-3">
                <Col>
                  {showableTags.map((item) => (
                    <SingleTag
                      item={item}
                      key={item.id}
                      disable={currentTag.id === item.id}
                    />
                  ))}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Tags;
