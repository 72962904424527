import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { withStyles } from "@material-ui/core/styles";
import useValidation from "hooks/useDValidation";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import Admin from "../../admins";
import { AllAdminFormFields } from "../../admins/slices/models/generalForm.model";
import { airLineReqFields } from "../../slice/airline.model";
import { EDITAIRLINE } from "../../slice/airlineConstants";
import { getAirlineUser } from "../../slice/airlineThunks";
import {
  selAirlinesCurrent,
  selAirlinesFormStatus,
  setAirlineUser,
} from "../../slice/airlinesSlice";
import AirlineUsers from "../../users";
import GeneralForm from "../Forms/GeneralForm";

function TabContainer({ children, dir }) {
  return (
    <div dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

function FullWidthTabs(props) {
  const formStatus = useSelector(selAirlinesFormStatus);
  const airline = useSelector(selAirlinesCurrent);
  const [value, setValue] = useState(0);
  const [validations, setValidations] = useState({
    admin: true,
    airline: true,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (airline.superadmin) {
      dispatch(getAirlineUser(airline?.superadmin));
    } else {
      dispatch(setAirlineUser(undefined));
    }
  }, [airline, dispatch]);

  const {
    errors: airlineErrors,
    checkValidation: airlineCheckValidation,
  } = useValidation({
    data: airLineReqFields,
    key: "airlineForm",
  });

  const {
    errors: adminErrors,
    checkValidation: adminCheckValidation,
  } = useValidation({
    data: AllAdminFormFields,
    key: "userFullForm",
  });

  const validators = {
    airline: async function (fields) {
      const valid = await airlineCheckValidation(fields);
      return setValidations((prev) => ({ ...prev, airline: valid }));
    },
  };

  function handleChange(event, value) {
    setValue(value);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }

  const { theme } = props;

  return (
    <div className="w-100">
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="on"
        >
          <Tab
            className="tab"
            label="General"
            style={{ color: !validations.airline ? "red" : "" }}
          />
          {formStatus === EDITAIRLINE ? (
            <Tab className="tab" label="Users" />
          ) : null}
          {formStatus === EDITAIRLINE ? (
            <Tab className="tab" label="Super Admins" />
          ) : null}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabContainer dir={theme.direction}>
          <GeneralForm
            validation={{
              errors: airlineErrors,
              airlineCheckValidation,
              adminCheckValidation,
            }}
            validators={validators}
            valid={validations}
          />
        </TabContainer>
        {formStatus === EDITAIRLINE ? (
          <TabContainer dir={theme.direction}>
            <AirlineUsers />
          </TabContainer>
        ) : null}
        {formStatus === EDITAIRLINE ? (
          <TabContainer dir={theme.direction}>
            <Admin />
          </TabContainer>
        ) : null}
      </SwipeableViews>
    </div>
  );
}

FullWidthTabs.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withStyles(null, { withTheme: true })(FullWidthTabs);
