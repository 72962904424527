import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const AlertModal = ({
  open,
  handleClose,
  onAccept,
  title,
  subTitle,
  fullWidth = true,
  maxWidth = "xs",
  okBtnTitle = "ok",
  cancelBtnTitle = "cancel",
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {subTitle}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {cancelBtnTitle}
        </Button>
        <Button onClick={onAccept} color="secondary" autoFocus>
          {okBtnTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AlertModal;
