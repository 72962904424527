import React, { useEffect, useState } from "react";

import AirlineModuleTableCell from "./AirlineModuleTableCell";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Drawer,
  IconButton,
  Divider,
} from "@material-ui/core";

import { Pagination } from "@material-ui/lab";

import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";

import CloseIcon from "@material-ui/icons/Close";
import * as R from "ramda";
import { getAirlines } from "../slice/airlineThunks";
import {
  selAirlinesList,
  setAirlineListPage,
  setAirlineListSize,
} from "../slice/airlinesSlice";
import {
  getItemsBySizeAndPage,
  setPaginationByAmountAndSize,
} from "util/list-fns";
import ContainerHeader from "../components/UIs/ContainerHeader";

import { selModsOBJ } from "store/slices/moduleSlice/moduleSlice";
import { allModules } from "store/slices/moduleSlice/module.mode";
import { useParams } from "react-router";

import { getListModulesByAirlineId } from "store/slices/moduleSlice/moduleThunk";
import { setAirlineInModules } from "store/slices/moduleSlice/moduleSlice";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  headTitle: {
    fontWeight: "bold",
  },
  formControl: {
    minWidth: 140,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  iconEmpty: {
    height: 42,
    width: 80,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    marginTop: 70,
  },
  content: {
    flexGrow: 1,
    padding: `0 ${theme.spacing(3)}`,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 416,
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  headerTitle: {
    marginTop: 12,
  },
}));

const items = [
  {
    name: "library",
    expiration_date: "2020-09-05T18:54:50.099Z",
    status: false, //false = disabled,
    created_at: "2020-09-05T18:54:50.099Z",
    modified_at: "2020-09-05T18:54:50.099Z",
  },
  {
    name: "admin privilages",
    expiration_date: "2020-09-05T18:54:50.099Z",
    status: true, //false = disabled,
    created_at: "2020-09-05T18:54:50.099Z",
    modified_at: "2020-09-05T18:54:50.099Z",
  },
];

const AirlineModuleTable = (props) => {
  let { id } = useParams();
  // hooks and initalize
  const dispatch = useDispatch();
  const classes = useStyles();
  const allModsOBJ = useSelector(selModsOBJ);
  const modsKeys = allModules;

  useEffect(() => {
    dispatch(getListModulesByAirlineId({ id }));
    dispatch(setAirlineInModules(id));
  }, [id]);

  function handleSortBy(items) {}

  return (
    <React.Fragment>
      <ContainerHeader showFilters={false} title="Assign Module" btnTitle="" />
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <div className="row mb-md-3">
          <div className="col-12">
            <div className="jr-card">
              <div className="table-responsive-material">
                <table className="default-table table-unbordered table table-sm table-hover">
                  <thead className="th-border-b">
                    <tr>
                      <HeaderTH
                        name={"name"}
                        title={"Name"}
                        onClick={handleSortBy}
                      />
                      <HeaderTH
                        onClick={handleSortBy}
                        name={"expiration_date"}
                        title={"Expiration Date"}
                      />
                      <HeaderTH
                        onClick={handleSortBy}
                        name={"created_at"}
                        title={"Created at"}
                      />
                      <HeaderTH
                        onClick={handleSortBy}
                        name={"modified_at"}
                        title={"Modified at"}
                      />
                      <HeaderTH
                        onClick={handleSortBy}
                        name={"status"}
                        title={"Status"}
                      />
                      <th> Enabled </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {modsKeys.map((k) => {
                      const mod = allModsOBJ[k];
                      const data = {
                        title: k,
                        key: k,
                        ...mod,
                      };
                      return (
                        mod && <AirlineModuleTableCell key={k} data={data} />
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            {/* <div className=" d-flex flex-row justify-content-between">
              <div className=" d-flex mt-3">
                <Pagination
                  {...pagination}
                  count={pagination.all}
                  page={pagination.cur}
                  shape="rounded"
                  color="primary"
                  onChange={hangelChangePage}
                />
              </div>

              <FormControl className={`${classes.formControl}`}>
                <InputLabel id="demo-simple-select-label">
                  Shows per page
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={size}
                  onChange={handleChangeListSize}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                </Select>
              </FormControl>
            </div> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const HeaderTH = (props) => {
  const classes = useStyles();
  const { title, name, sorter, asc, onClick } = props;
  const showSort = name === sorter;

  function onHeaderClick() {
    return onClick([name]);
  }
  return (
    <th onClick={onHeaderClick}>
      <div className={showSort ? classes.headTitle : ""}>
        {title}
        {!showSort ? (
          ""
        ) : asc ? (
          <i className={`ml-2 zmdi zmdi-long-arrow-up`} />
        ) : (
          <i className={`ml-2 zmdi zmdi-long-arrow-down`} />
        )}
      </div>
    </th>
  );
};

export default AirlineModuleTable;
