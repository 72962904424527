import { IconButton, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { useDispatch } from "react-redux";
import { getDirectory } from "../slice/helperThunks";
import React from "react";
export const TableRow = ({ key, directory, handelOpenModal }) => {
  const dispatch = useDispatch();

  const decodeEntities = (function () {
    const element = document.createElement("div");

    function RichTextEditor(str) {
      if (str && typeof str === "string") {
        str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
        str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
        element.innerHTML = str;
        str = element.textContent;
        element.textContent = "";
      }

      return str;
    }

    return RichTextEditor;
  })();
  const textContent = decodeEntities(directory?.helper?.description);

  return (
    <tr tabIndex={-1}>
      <td>
        <h4>{directory?.title}</h4>
      </td>
      <td>
        <h4>{directory?.helper?.type}</h4>
      </td>
      <td style={{ maxWidth: "800px" }}>
        <h4>
          <div
            dangerouslySetInnerHTML={{
              __html: textContent,
            }}
          ></div>
        </h4>
      </td>
      <td style={{ textAlign: "end" }}>
        <Tooltip title="edit user">
          <IconButton
            onClick={() => {
              console.log(directory?.helper?.title);
              if (directory?.helper) {
                dispatch(getDirectory(directory?.id)).then((res) => {
                  handelOpenModal();
                });
              } else {
                dispatch(getDirectory(directory?.id)).then((res) => {
                  handelOpenModal();
                });
              }
            }}
            color="primary"
          >
            {directory?.helper ? (
              <EditIcon style={{ color: "orange" }} />
            ) : (
              <AddIcon fontSize="large" />
            )}
          </IconButton>
        </Tooltip>
      </td>
    </tr>
  );
};
