import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// import asyncComponent from "../../../util/asyncComponent";
// import { selLoading } from "store/slices";
// import { useSelector } from "react-redux";
// import { SimpleLoader } from "app/components/loaders";
// import Library from "./routes/library";
import Settings from "./routes/settings";
import Tags from "./routes/tags";

import Airline from "./routes/airlines";

import { useDispatch } from "react-redux";
import { updateWindowWidth } from "actions";
import AirlineDetail from "./routes/airlines/AirlineDetail/AirlineDetails";
import AirlineEdit from "./routes/airlines/AirlinesEdit/AirlineEdit";
import AirlineModuleList from "./routes/airlines/airlineModule/AirlineModuleTable";
// import FleetCreate from "./routes/fleet/fleetCreate";

import Unfound from "app/routes/extraPages/routes/404";
import UserDetail from "./routes/users/userDetail";
import UserEdit from "./routes/users/userEdit";
import Users from "./routes/users";
import AdminEdit from "./routes/airlines/admins/adminEdit/AdminEdit";
import AdminDetail from "./routes/airlines/admins/adminDetail/AdminDetail";
import ReportedBug from "./routes/ReportedBugs";
import Helper from "./routes/helper";

const Dashboard = ({ match }) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(dispatch(updateWindowWidth(window.innerWidth)));
  }, []);
  // const loading = useSelector(selLoading);
  return (
    <>
      {/* {loading && <SimpleLoader />} */}
      <div className="app-wrapper">
        <Switch>
          <Redirect exact from={`${match.url}/`} to={`${match.url}settings`} />
          <Route path={`${match.url}/reportedbug`} component={ReportedBug} />
          <Route path={`${match.url}/settings`} component={Settings} />
          <Route path={`${match.url}/tags`} component={Tags} />
          <Route path={`${match.url}/airline`} component={Airline} />
          <Route path={`${match.url}/users`} component={Users} />
          <Route path={`${match.url}/user/:id`} component={UserDetail} />
          <Route path={`${match.url}/user-edit/:id`} component={UserEdit} />
          <Route path={`${match.url}/help`} component={Helper} />
          <Route
            exact
            path={`${match.url}/airline-create/`}
            component={AirlineEdit}
          />
          <Route
            exact
            path={`${match.url}/airline-edit/:id`}
            component={AirlineEdit}
          />
          <Route
            path={`${match.url}/airline-detail/:id`}
            component={AirlineDetail}
          />
          <Route
            path={`${match.url}/airline-module/:id`}
            component={AirlineModuleList}
          />

          <Route
            path={`${match.url}/airline-edit/:id/user-detail/:userId`}
            component={AdminDetail}
          />

          <Route
            path={`${match.url}/airline-edit/:id/user-add`}
            component={AdminEdit}
          />

          <Route
            path={`${match.url}/airline-edit/:id/user-edit/:userId`}
            component={AdminEdit}
          />

          <Route component={Unfound} />
        </Switch>
      </div>
    </>
  );
};

export default Dashboard;
