import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  global: false,
};

const baseSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setLoaderApp: (state, action) => {
      state.loading = action.payload;
    },
    setLoaderGlobalApp: (state, action) => {
      state.global = action.payload;
    },
  },
});

export const { actions } = baseSlice;
export const { setLoaderApp, setUser, setLoaderGlobalApp } = actions;
// selectors

export const selLoaderLoading = (state) => state.loading.loading;
export const selLoaderGlobalLoading = (state) => state.loading.global;

const reducer = {
  loading: baseSlice.reducer,
};

export default reducer;
