import { setTagList, selCurentTag } from "./tagSlice";
// import * as R from "ramda";
import loaderClient from "api/reduxLoaderApi";

export const getAllTags = () => async (dispatch) => {
  const client = loaderClient("get")("/tags?limit=1000");
  const res = await client({})({});
  if (!res.message) return "";
  return dispatch(setTagList(res.message));
};

export const createTag =
  (data, callback = false) =>
  async (dispatch) => {
    const client = loaderClient("post")("/tags");
    const res = await client(data)({});
    if (!res.message) return "";
    dispatch(getAllTags());
    return callback && callback();
  };

export const editTag =
  (data, callback = false) =>
  async (dispatch, getState) => {
    const tag = selCurentTag(getState());
    const client = loaderClient("patch")(`/tags/${tag.id}`);
    const res = await client(data)({});
    if (!res.message) return "";
    dispatch(getAllTags());
    return callback && callback();
  };

export const deleteTags =
  (id, callback = false) =>
  async (dispatch) => {
    const client = loaderClient("delete")(`/tags/${id}`);
    const res = await client({})({});
    if (res.error) return "";
    dispatch(getAllTags(id));
    return callback && callback();
  };
