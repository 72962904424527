import { createSlice } from "@reduxjs/toolkit";
import * as R from "ramda";
import { format } from "date-fns";
import { model } from "./airline.model";

import { EDITAIRLINE, NEWAIRLINE } from "./airlineConstants";
import { toast } from "react-toastify";
const initialState = {
  status: NEWAIRLINE,
  list: [],
  totalItems: 0,
  airports: [],
  form: model,
  current: model,
  filter: {
    search: "",
    page: 1,
    size: 5,
    sort: "created_time:asc",
    status: "all",
  },
  settings: {},
  airlineUser: undefined,
};
//

const airlinesSlice = createSlice({
  name: "airlines",
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setAirlineList: (state, action) => {
      state.list = action.payload;
    },
    setListItems: (state, action) => {
      state.status = NEWAIRLINE;
      console.log("setlist", action.payload);
      state.totalItems = action.payload;
    },
    setListPage: (state, action) => {
      state.filter.page = action.payload;
    },
    setListSize: (state, action) => {
      state.filter.page = 1;
      state.filter.size = action.payload;
    },

    setAirlineFilterStatus: (state, action) => {
      state.filter.status = action.payload;
    },

    setAirportList: (state, action) => {
      state.airports = action.payload;
    },

    // current item for edit Reducer
    setAirlineFilterSearch: (state, action) => {
      state.filter.search = action.payload;
    },

    setAirlineSort: (state, action) => {
      state.filter.sort = action.payload;
    },

    setAirlineHomebase: (state, action) => {
      state.form.homebases = [...action.payload];
    },

    setAirlineSettings: (state, action) => {
      state.settings = action.payload;
    },

    setCurrent: (state, action) => {
      state.status = EDITAIRLINE;
      const homebases = action.payload.homebases.map(
        ({ homebase_airport, ...rest }) => ({ ...homebase_airport, ...rest })
      );
      const airline = {
        ...action.payload,
        homebases,
      };
      state.form = { ...airline };
      state.current = { ...airline };
    },

    // form reducers for add or edit items

    setFormInit: (state, action) => {
      state.status = NEWAIRLINE;
      state.form = model;
      state.current = model;
      // state.tag = { id: "", description: "" };
    },

    setHomebaseNumber: (state, action) => {
      const { index, value } = action.payload;
      state.form.homebases[index].homebase_number = value;
    },

    setFormField: (state, action) => {
      const { name, value } = action.payload;
      state.form[name] = value;
    },

    addAirlineHomebase: (state, action) => {
      const index = state.form.homebases.findIndex(
        (el) => el.id === action.payload.id
      );
      if (index === -1) {
        state.form.homebases = [
          ...state.form.homebases,
          {
            ...action.payload,
            homebase_number: state.form.homebases.length + 1,
          },
        ];
      } else {
        toast.error(`${action.payload.name} is selected`);
      }
    },
    removeAirlineHomebase: (state, action) => {
      const newState = [...state.form.homebases];
      newState.splice(action.payload, 1);
      state.form.homebases = [...newState];
    },
    setAirlineUser: (state, action) => {
      state.airlineUser = action.payload;
    },

    filterList: (state, action) => {
      // const all = state.allList;
      // const { name, value } = action.payload;
      // if (value === "all") {
      //   state.list = all;
      // } else {
      //   const filtered = filterByPropValue(name, value)(all);
      //   state.list = filtered;
      // }
      // state.page = 1;
      // state.sort = "date";
      // state.asc = true;
    },
  },
});

export const { actions } = airlinesSlice;
export const {
  setStatus,
  setListItems,
  setListPage: setAirlineListPage,
  setListSize: setAirlineListSize,
  setCurrent,
  setFormInit: setAirlineFormInit,
  setFormField: setAirlineFormFiled,
  setAirportList,
  setAirlineHomebase,
  setAirlineSort,
  addAirlineHomebase,
  removeAirlineHomebase,
  setHomebaseNumber,
  setAirlineFilterSearch,
  setAirlineSettings,
  setAirlineList,
  setAirlineFilterStatus,
  setAirlineUser,
} = actions;

// selectors

export const selAirlinesList = (state) => state.airlines.list;
export const selAirlineTotalItems = (state) => state.airlines.totalItems;
export const selAirlineFilter = (state) => state.airlines.filter;
export const selAirlinesStatus = (state) => state.airlines.status;
export const selAirlinesCurrent = (state) => state.airlines.current;
export const selAirlinesForm = (state) => state.airlines.form;
export const selAirlinesFormStatus = (state) => state.airlines.status;
export const selAirlinePage = (state) => state.airlines.list.page;
export const selAirlineSize = (state) => state.airlines.list.size;
export const selAirports = (state) => state.airlines.airports;
export const selAirlineSort = (state) => state.airlines.sort;
export const selAirlineFilterSearch = (state) => state.airlines.filter.search;
export const selAirlineSettings = (state) => state.airlines.settings;
export const selAirlineUser = (state) => state.airlines.airlineUser;

const reducer = {
  airlines: airlinesSlice.reducer,
};

export default reducer;
