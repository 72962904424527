import loaderClient from "api/reduxLoaderApi";
import { toast } from "react-toastify";
import {
  setAirlineUser,
  setAirlinesName,
  setBugReportedList,
  setUsers,
  setUsersList,
} from "./bugReportedSlice";

export const getBugReported = (id) => async (dispatch) => {
  const client = loaderClient("get")(`/bug-reports?limit=all`);
  const res = await client({})({});
  // console.log("br", res)

  if (res.error) return toast.error(res.message);
  return dispatch(setBugReportedList(res.message));
};

export const updatedBugReported = (
  bugId,
  resolved_note,
  callback = false
) => async (dispatch) => {
  const client = loaderClient("patch")(`/bug-reports/${bugId}/resolve`);
  const res = await client({ resolved_note })({});
  // console.log("bu", res);

  if (res.error) return toast.error(res.message);
  toast.success(res.message);
  dispatch(getBugReported());
  return callback && callback();
};

export const getFilteredBugs = (params) => async (dispatch) => {
  // console.log("params", params);
  const client = loaderClient("get")(`/bug-reports?${params}`);
  const res = await client({})({});
  if (res.error) return toast.error(res.message);
  return dispatch(setBugReportedList(res.message));
};

export const getUsers = () => async (dispatch) => {
  const client = loaderClient("get")(`/users?limit=all`);
  const res = await client({})({});
  console.log("resssssssssssssssssssssssss", res);
  if (res.error) return toast.error(res.message);
  return dispatch(setUsersList(res.message));
};

export const getAirlines = () => async (dispatch) => {
  const client = loaderClient("get")(`/airlines`);
  const res = await client({})({});

  if (res.error) return toast.error(res.message);
  return dispatch(setAirlinesName(res.message));
};
