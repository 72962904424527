import React from "react";
import { configureStore } from "@reduxjs/toolkit";
import reducer, { setLoaderGlobalApp } from "./loaderSlice";

import {
  Provider,
  createStoreHook,
  createDispatchHook,
  createSelectorHook,
} from "react-redux";

// import allMiddles from './middlewares'

const loaderStore = configureStore({
  reducer: {
    ...reducer,
  },
});
const MyContext = React.createContext(null);

export default function DProvider({ children }) {
  return (
    <Provider context={MyContext} store={loaderStore}>
      {children}
    </Provider>
  );
}

const DuseStore = createStoreHook(MyContext);
const DuseDispatch = createDispatchHook(MyContext);
const DuseSelector = createSelectorHook(MyContext);
const dispatch = loaderStore.dispatch;

const globalLoading = (cond) => {
  return dispatch(setLoaderGlobalApp(cond));
};

export {
  DProvider,
  DuseStore,
  DuseDispatch,
  DuseSelector,
  loaderStore,
  globalLoading,
};
