import {
  Button,
  FormControl,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  selUserFilterSearch,
  selUserFilterStatus,
  setUserPage,
  setUserSearch,
  setUserStatusFilter,
} from "../../slice/userSlice";
import { useDebounce } from "util/debounceRamda";
import { getAllUsers } from "../../slice/userThunk";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  item: {
    fontSize: 18,
    cursor: "pointer",
  },
}));

const ContainerHeader = ({ title, url, showFilters, btnTitle }) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const search = useSelector(selUserFilterSearch);
  const status = useSelector(selUserFilterStatus);
  const onSearch = (payload) => {
    dispatch(setUserPage(1));
    dispatch(getAllUsers());
    // dispatch(filterUserByText(payload));
    // dispatch(getAirlines({ page: 1, limit: 5, search: payload }));
  };

  const debouncedSearch = useDebounce(onSearch, 400);

  function handleSearch(e) {
    const { value } = e.target;
    // dispatch(setAirlineFilterSearch(v.alue));
    dispatch(setUserSearch(value));
    debouncedSearch(value);
  }

  const handleStatus = (e) => {
    dispatch(setUserStatusFilter(e.target.value));
    dispatch(getAllUsers());
  };

  return (
    <div className="page-heading d-sm-flex flex-row justify-content-sm-between z-index-0">
      <div className=" d-sm-flex flex-column justify-content-sm-between align-self-end">
        <h2 className="title">{title}</h2>
      </div>
      <div className="d-flex flex-column align-items-start">
        <div className="d-sm-flex flex-row justify-content-sm-between">
          {showFilters ? (
            <React.Fragment>
              <FormControl className={`${classes.formControl} mr-3`}>
                <InputLabel id="demo-simple-select-label">Filter By</InputLabel>
                <Input
                  placeholder="Search"
                  value={search}
                  onChange={handleSearch}
                />
              </FormControl>

              {/* <FormControl className={`${classes.formControl} mr-3`}>
                <InputLabel id="demo-simple-select-label">
                  User Status
                </InputLabel>
                <Select value={status} onChange={handleStatus}>
                  <MenuItem value={"all"}>All</MenuItem>
                  <MenuItem value={false}>Active</MenuItem>
                  <MenuItem value={true}>Deleted</MenuItem>
                </Select>
              </FormControl> */}
            </React.Fragment>
          ) : (
            false
          )}
        </div>
      </div>
    </div>
  );
};

export default ContainerHeader;
