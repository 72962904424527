import {
  setListItems,
  setCurrent,
  selAirlinesForm,
  selAirlinesCurrent,
  setAirportList,
  setAirlineList,
  setAirlineSettings,
  setAirlineUser,
} from "./airlinesSlice";
import * as R from "ramda";
import loaderClient from "api/reduxLoaderApi";
import { pickerPropAirlines } from "./airline.model";
import { toast } from "react-toastify";
import moment from "moment";

export const getAirlines = () => async (dispatch, getState) => {
  const { airlines } = getState();
  let params = `/airlines?page=${airlines.filter.page}&limit=${airlines.filter.size}`;
  if (!!airlines.filter.search) {
    params += `&search=${airlines.filter.search}`;
  }

  if (!!airlines.filter.sort) {
    params += `&sortBy=${airlines.filter.sort}`;
  }

  if (!!airlines.filter.status && !!airlines.filter.status !== "all") {
    if (airlines.filter.status === "active") {
      params += `&is_enabled=true`;
    } else if (airlines.filter.status === "inactive") {
      params += `&is_enabled=false`;
    } else if (airlines.filter.status === "deleted") {
      params += `&is_deleted=true`;
    }
  }

  const client = loaderClient("get")(params);
  const res = await client({})({});
  if (res.error) return toast.error(res.message);
  dispatch(setListItems(res.result_number));
  return dispatch(setAirlineList(res.message));
};

export const retrieveAirline = (id) => async (dispatch) => {
  const client = loaderClient("get")(`/airlines/${id}`);
  const res = await client({})({});
  if (res.error) return toast.error(res.message);
  return dispatch(
    setCurrent({
      ...res.message.airline,
      expiration_date: moment(res?.message?.airline?.expiration_date).format(
        "MM/DD/YYYY"
      ),
    })
  );
};

export const postAirline = (callback = true) => async (dispatch, getState) => {
  const form = selAirlinesForm(getState());
  // const validData = pickerPropAirlines(form);
  const validData = R.reject(R.equals(""), form);
  const homebases = validData.homebases.map(
    (homebase_airport) => homebase_airport.id
  );
  console.log(" validData-", validData, homebases);
  const client = loaderClient("post")(`/airlines`);
  const res = await client({ ...validData, homebases })({});
  // console.log("res.message.airline.id", res.message.airline.id)
  console.log("ressss5555", res);
  if (res.error) return toast.error(res.message);
  return callback && callback();
};

export const patchAirline = (callback = false) => async (
  dispatch,
  getState
) => {
  const form = selAirlinesForm(getState());
  const currentAirlineData = selAirlinesCurrent(getState());
  const data = pickerPropAirlines(form);
  const validData = R.reject(R.equals(""), data);
  const finalData = R.omit(["homebases"], validData);
  const client = loaderClient("patch")(`/airlines/${currentAirlineData.id}`);
  const res = await client(finalData)({});
  if (res.error) return toast.error(res.message);
  return callback && callback();
  // return dispatch(getAllAirLines());
};

export const updateAirlineStatus = (id, is_enabled, callback = false) => async (
  dispatch
) => {
  const client = loaderClient("patch")(`/airlines/${id}`);
  const res = await client({ is_enabled })({});
  if (res.error) return toast.error(res.message);
  return callback && callback();
};

export const deleteAirlines = (id, callback = false) => async (dispatch) => {
  const client = loaderClient("delete")(`/airlines/${id}`);
  const res = await client({})({});
  if (res.error) return toast.error(res.message);

  return callback && callback();
};

export const getAirports = (search) => async (dispatch) => {
  let params = "?limit=10";
  if (!!search) {
    params += `&search=${search}`;
  }
  const client = loaderClient("get")(`/airports${params}`);
  const res = await client({})({});
  if (res.error) return toast.error(res.message);
  return dispatch(setAirportList(res.message));
};

export const createSuperAdminForAirline = (
  id,
  data,
  callback = false
) => async () => {
  const client = loaderClient("post", `/admin/${id}`);
  const { code, crew_id } = data;

  const validData = R.reject(R.equals(""), data);

  delete validData.code;
  delete validData.crew_id;
  delete validData.role;
  delete validData.gender;

  const userData = {
    userBody: validData,
    assignmentBody: {
      code,
      crew_id,
    },
  };

  const res = await client(userData)({});

  if (res.error) return toast.error(res.message);
  return callback && callback();
};

export const patchHomebase = (
  airlineId,
  data,
  callback = false,
  homebaseId
) => async (dispatch) => {
  const homebases = data.map((el) => el.id);
  if (!!homebaseId) {
    homebases.push(homebaseId);
  }
  const client = loaderClient("patch")(`/airlines/${airlineId}/homebases`);
  const res = await client({ homebaseList: homebases })({});
  if (res.error) {
    dispatch(retrieveAirline(airlineId));
    return toast.error(res.message);
  }
  return callback && callback();
};

export const deleteHomebase = (
  airlineId,
  homebaseId,
  callback = false
) => async () => {
  const client = loaderClient("delete")(
    `/airlines/${airlineId}/homebases/${homebaseId}`
  );
  const res = await client({})({});
  if (res.error) return toast.error(res.message);
  return callback && callback();
};

export const getAirlineSettings = (id) => async (dispatch) => {
  const client = loaderClient("get")(`/airlines/${id}/settings`);
  const res = await client({})({});
  if (res.error) return toast.error(res.message);
  return dispatch(setAirlineSettings(res.message));
};

export const getAirlineUser = (id) => async (dispatch) => {
  const client = loaderClient("get")(`/user-assignments/${id}`);
  const res = await client({})({});
  if (res.error) return toast.error(res.message);
  return dispatch(setAirlineUser(res.message));
};
