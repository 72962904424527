import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Avatar,
  Badge,
  Button,
  Chip,
  Drawer,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/VisibilityOutlined";
import SettingsIcon from "@material-ui/icons/SettingsApplicationsRounded";
import DateIcon from "@material-ui/icons/DateRangeOutlined";
import RemoveIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/EditOutlined";
import { Link, useHistory } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import * as R from "ramda";
import { useDispatch } from "react-redux";
import ConfirmToast from "components/ConfirmToast";
import { toast } from "react-toastify";
import { deleteAirlines } from "../slice/airlineThunks";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Checkbox from "@material-ui/core/Checkbox";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { format, compareAsc } from "date-fns";
import { addModules } from "store/slices/moduleSlice/moduleThunk";
import { Label, FormGroup, Input, Col, Form, Row } from "reactstrap";
import { MODULEFLEET } from "store/slices/moduleSlice/module.mode";
import { setModuleSetting } from "store/slices/moduleSlice/moduleSlice";
import Switch from "@material-ui/core/Switch";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";

const useStyles = makeStyles((theme) => ({
  iconEmpty: {
    height: 42,
    width: 80,
  },
}));

const modules = [
  {
    id: 0,
    name: "library",
  },
  {
    id: 1,
    name: "flight",
  },
  {
    id: 2,
    name: "fleet",
  },
  {
    id: 3,
    name: "users",
  },
];

const AirlineModuleTableCell = (props) => {
  const { data } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const propOrNA = R.propOr("N/A");
  const [checked, setChecked] = React.useState([]);

  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  const handleEditClick = (id) => () => {
    history.push(`/dashboard/airline-edit/${id}`);
  };

  const handleDetails = (id) => () => {
    history.push(`/dashboard/airline-detail/${id}`);
  };

  const handleDelete = (id) => () => {
    toast.info(
      <ConfirmToast
        message="Do you want to delete this tag?"
        confirm={confirmDelete(id)}
      />
    );
  };

  const handleToggle = (value) => {
    dispatch(
      addModules(
        {
          key: data.title,
          date: data.expiration_date,
          name: data.module,
          enabled: !data.enabled,
        },
        () =>
          toast.info(
            `${data.title} ${
              data.enabled ? "disabled" : "enabled"
            } successfully`
          )
      )
    );
  };

  const confirmDelete = (id) => () => {
    return dispatch(deleteAirlines(id));
  };

  return (
    <React.Fragment>
      <ModalAssign toggle={toggle} open={open} mod={data || []} />
      {/* <Modal size="sm" isOpen={open} toggle={toggle}>
        <ModalHeader toggle={toggle}>Change Expiration Date Module</ModalHeader>
        <ModalBody>
          <div>
            <MaterialUIPickers mod={data} />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button variant="contained" color="primary" onClick={toggle}>
            Submit
          </Button>{" "}
          <Button variant="outlined" color="primary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal> */}
      <tr tabIndex={-1}>
        <td>
          <div className="user-detail">
            <h5 className="user-name">{propOrNA("title")(data)} </h5>
          </div>
        </td>
        <td>
          <div className="user-detail">
            <h5 className="user-name">
              {data.exist
                ? moment(data.expiration_date).format("MM/DD/YYYY")
                : "N/A"}
            </h5>
          </div>
        </td>
        <td>
          <div className="user-detail">
            <h5 className="user-name">
              {moment(data.created_time).format("MM/DD/YYYY")}
            </h5>
          </div>
        </td>
        <td>
          <div className="user-detail">
            <h5 className="user-name">
              {moment(data.updated_time).format("MM/DD/YYYY")}{" "}
            </h5>
          </div>
        </td>
        <td>
          <div className="user-detail">
            <Chip
              size="small"
              color={
                data.exist
                  ? !data.enabled
                    ? "default"
                    : new Date(data.expiration_date).getTime() <
                      new Date().getTime()
                    ? "secondary"
                    : "primary"
                  : "secondary"
              }
              label={
                data.exist
                  ? !propOrNA("enabled")(data)
                    ? "Inactive"
                    : new Date(data.expiration_date).getTime() <
                      new Date().getTime()
                    ? "Expire"
                    : "Active"
                  : "Not Assigned"
              }
            />
          </div>
        </td>
        <td>
          <Tooltip title={`${data.enabled ? "Disable" : "Enable"}  Module`}>
            <Switch
              disabled={
                !data.exist ||
                new Date(data.expiration_date).getTime() < new Date().getTime()
              }
              checked={data.exist ? (!data.enabled ? false : true) : false}
              onChange={handleToggle}
              color="secondary"
            />
          </Tooltip>
        </td>
        <td>
          <Tooltip title="Change Expiration Date">
            <IconButton onClick={toggle}>
              <DateIcon color="secondary" />
            </IconButton>
          </Tooltip>
        </td>
      </tr>
    </React.Fragment>
  );
};

const ModalAssign = (props) => {
  const { mod, open, toggle } = props;
  // The first commit of Material-UI
  const [selectedDate, setSelectedDate] = React.useState(mod.expiration_date);

  useEffect(() => {
    setSelectedDate(mod.expiration_date);
  }, [open]);

  const dispatch = useDispatch();

  const handleDateChange = (date) => {
    setSelectedDate(moment(new Date(date).toUTCString()).format("MM/DD/YYYY"));
  };

  return (
    <Modal size="sm" isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>Change Expiration Date Module</ModalHeader>
      <ModalBody>
        <div>
          <MuiPickersUtilsProvider locale={enLocale} utils={DateFnsUtils}>
            <Grid container justifyContent="space-between">
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                showTodayButton={true}
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Set expired date"
                cancelLabel={true}
                value={selectedDate}
                autoOk={true}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>

          {mod.title === MODULEFLEET && (
            <>
              <Label sm={5}>add max fleet</Label>
              <Col sm={6}>
                <Input
                  type={"string"}
                  name={mod.module}
                  value={mod?.settings?.max_active_fleets}
                  onChange={(e) => {
                    dispatch(
                      setModuleSetting({
                        moduleKey: mod.title,
                        value: { max_active_fleets: e.target.value },
                        name: "max_active_fleets",
                      })
                    );
                  }}
                  // placeholder=""
                  // readOnly={readOnly}
                />
              </Col>
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            dispatch(
              addModules(
                {
                  key: mod.title,
                  date: selectedDate,
                  name: mod.module,
                },
                () => toggle()
              )
            );
          }}
        >
          Submit
        </Button>{" "}
        <Button variant="outlined" color="primary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const ModuleAssign = (props) => {
  const { data, handleToggle, checked } = props;

  return (
    <div>
      <Checkbox
        color="primary"
        checked={checked.indexOf(data.id) !== -1}
        onChange={() => {
          handleToggle(data.id);
        }}
        value={data.id}
        className="size-30 mr-2"
        name={data.name}
      />
      <span>{data.name}</span>
    </div>
  );
};

const MaterialUIPickers = (props) => {
  const { mod } = props;
  // The first commit of Material-UI
  const [selectedDate, setSelectedDate] = React.useState(
    new Date("2014-08-18T21:11:54")
  );
  const dispatch = useDispatch();

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-between">
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id="date-picker-inline"
          label="Set expired date"
          showTodayButton={true}
          disableCloseOnSelect={false}
          value={selectedDate}
          onChange={handleDateChange}
          autoOk={true}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
        <button
          onClick={() => {
            dispatch(addModules({ key: mod.title, selectedDate }));
          }}
        >
          done
        </button>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};
export default AirlineModuleTableCell;
