import {
  Avatar,
  Badge,
  Chip,
  IconButton,
  Switch,
  Tooltip,
} from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/EditOutlined";
import ModuleIcon from "@material-ui/icons/ViewModuleOutlined";
import VisibilityIcon from "@material-ui/icons/VisibilityOutlined";
import { makeStyles } from "@material-ui/styles";
import { format } from "date-fns";
import * as R from "ramda";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import AlertModal from "../../../../../../components/AlertModal";
import {
  deleteAirlines,
  getAirlines,
  updateAirlineStatus,
} from "../slice/airlineThunks";
import {
  selAirlineFilterSearch,
  selAirlinePage,
  selAirlineSize,
} from "../slice/airlinesSlice";
import { AirlineLogo } from "./AilrlineLogo";
const useStyles = makeStyles((theme) => ({
  iconEmpty: {
    height: 42,
    width: 80,
  },
}));

const modules = [
  {
    id: 0,
    name: "library",
  },
  {
    id: 1,
    name: "flight",
  },
  {
    id: 2,
    name: "fleet",
  },
  {
    id: 3,
    name: "users",
  },
];

const AirlinesTableCell = (props) => {
  const { data, airlineModules } = props;
  const classes = useStyles();
  const { id } = data;
  const dispatch = useDispatch();
  const history = useHistory();
  const propOrNA = R.propOr("N/A");
  const page = useSelector(selAirlinePage);
  const size = useSelector(selAirlineSize);
  const [showEdit, setShowEdit] = useState(false);
  const [checked, setChecked] = React.useState([]);
  const [isDeleted, setIsDeleted] = useState();
  const groups = ["Group1", "Grroup2", "Group3"];
  const [open, setOpen] = useState(false);
  const [openEnableDialog, setOpenEnableDialog] = useState(false);
  const [toggleEnable, setToggleEnable] = useState(data.is_enabled);
  const created_time = format(new Date(data.created_time), "dd/MM/yyyy");
  const [formState, setFormState] = React.useState({
    selectedGroups: [],
  });
  const search = useSelector(selAirlineFilterSearch);
  const moduleCount = airlineModules.message
    ? airlineModules.message
        .find((o) => o.airline.id == id)
        .modules.filter(
          (o) => o.enabled === true && new Date(o.expiration_date) >= new Date()
        ).length
    : 0;

  const handleEditClick = (id) => () => {
    history.push(`/dashboard/airline-edit/${id}`);
  };

  const handleDetails = (id) => () => {
    history.push(`/dashboard/airline-detail/${id}`);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenEnableDialog = () => {
    setOpenEnableDialog(true);
  };

  const handleCloseEnableDialog = () => {
    setOpenEnableDialog(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const finishEditAirlineStatus = () => {
    dispatch(getAirlines({ page, limit: size, search }));
    toast.info("Operation Success");
    setOpenEnableDialog(false);
  };

  const handleToggle = () => {
    dispatch(
      updateAirlineStatus(data.id, !data.is_enabled, finishEditAirlineStatus)
    );
  };

  const finishDelete = () => {
    toast.info("Delete Successful");
    dispatch(getAirlines({ page, limit: size, search }));
    setOpen(false);
  };

  const deleteAirline = () => {
    dispatch(deleteAirlines(id, finishDelete));
  };

  const confirmDelete = (id) => () => {
    return dispatch(deleteAirlines(id));
  };

  return (
    <React.Fragment>
      <tr tabIndex={-1}>
        <td>
          <div>
            {!data.logo ? (
              <Avatar alt={data.name} />
            ) : (
              <AirlineLogo imageId={data.logo} />
            )}
          </div>
        </td>
        <td>
          <div className="user-detail">
            <h5 className="user-name">{propOrNA("name")(data)} </h5>
          </div>
        </td>
        <td>
          <div className="user-detail">
            <h5 className="user-name">{propOrNA("callsign")(data)} </h5>
          </div>
        </td>
        <td>
          <div className="user-detail">
            <Chip
              size="small"
              color={
                data.is_deleted
                  ? "secondary"
                  : data.is_enabled
                  ? "primary"
                  : "default"
              }
              label={
                data.is_deleted
                  ? "Deleted"
                  : data.is_enabled
                  ? "Active"
                  : "Inactive"
              }
            />
          </div>
        </td>
        <td>
          <div className="user-detail">
            <h5 className="user-name">{propOrNA("icao_code")(data)} </h5>
          </div>
        </td>
        <td>
          <div className="user-detail">
            <h5 className="user-name">{propOrNA("iata_code")(data)} </h5>
          </div>
        </td>

        <td>
          <div className="user-detail">
            <h5 className="user-name">{propOrNA("email")(data)} </h5>
          </div>
        </td>

        <td>
          <div className="user-detail">
            <h5 className="user-name">
              {data.telephone.code} {data.telephone.number}{" "}
            </h5>
          </div>
        </td>

        <td>
          <div className="user-detail">
            <h5 className="user-name">{created_time} </h5>
          </div>
        </td>
        <td>
          <Tooltip title={`${data.is_enabled ? "Disable" : "Enable"} Airline`}>
            <Switch
              onChange={handleOpenEnableDialog}
              checked={data.is_enabled}
              color="secondary"
            />
          </Tooltip>
        </td>
        <td>
          <Tooltip title="Details" aria-label="add">
            <IconButton onClick={handleDetails(data.id)}>
              <VisibilityIcon color="primary" />
            </IconButton>
          </Tooltip>
        </td>
        <td>
          <Tooltip title="delete" aria-label="add">
            <IconButton onClick={handleOpen}>
              <RemoveIcon color="secondary" />
            </IconButton>
          </Tooltip>
        </td>
        <td>
          <Link>
            <Tooltip title="Edit Airline" aria-label="add">
              <IconButton onClick={handleEditClick(data.id)}>
                <EditIcon style={{ color: "orange" }} />
              </IconButton>
            </Tooltip>
          </Link>
        </td>
        <td>
          <Link to={`/dashboard/airline-module/${data.id}`}>
            <Tooltip title="Assign module" aria-label="add">
              <IconButton>
                <Badge badgeContent={moduleCount} color="secondary">
                  <ModuleIcon color="primary" />
                </Badge>
              </IconButton>
            </Tooltip>
          </Link>
        </td>
      </tr>
      <AlertModal
        open={open}
        handleClose={handleClose}
        title="Delete Airline"
        subTitle={`Are you sure want delete ${data.name}?`}
        onAccept={deleteAirline}
      />
      <AlertModal
        open={openEnableDialog}
        title={`${toggleEnable ? "Disable" : "Enable"} ${data.name}`}
        subTitle="Are you sure?"
        handleClose={handleCloseEnableDialog}
        onAccept={handleToggle}
      />
    </React.Fragment>
  );
};

export default AirlinesTableCell;
