import { createSlice } from "@reduxjs/toolkit";
import * as R from "ramda";

import {
  DONEUPLOAD,
  FAILEDCUT,
  INITCUT,
  INITFILE,
  INITUPLOAD,
  ONCHECK,
  ONCUT,
  ONPROGRESSFILE,
  READYFORUPLOAD,
  WAITING,
} from "./constants";
// impoer {CancelToken}
import axios from "axios";
import { toast } from "react-toastify";

const setCurrentFolder = (state, action) => {
  const content = action.payload;
  state.content = content;
};

const initialState = {
  status: WAITING,
  roads: {},
  navs: [],
  unPublished: [],
  drawerContent: {},
  showType: "grid",
  content: {
    current: {},
    files: [],
    folders: [],
    contents: [],
    deleted: [],
  },
  tags: {},
  cutStatus: INITCUT,
  cutFrom: {},
  cutContent: {},
  upload: [],
  uploadStutus: ONCHECK,
  asc: true,
  listSort: {
    asc: true,
    sort: "uuu",
    path: [],
  },
};

const sortByPropTitle = (prop, asc) =>
  asc
    ? R.sortWith([R.ascend(R.compose(R.identity, R.pathOr("N/A", prop)))])
    : R.sortWith([R.descend(R.compose(R.identity, R.pathOr("N/A", prop)))]);

const filterByPropValue = (prop, val) =>
  R.filter((item) => R.prop(prop)(item) === val);

function sortingList(state, action) {
  const sort = state.listSort.sort;
  const asc = state.listSort.asc;

  const bySortTitle = action.payload[0];
  const bySortPath = action.payload[1];

  if (sort !== bySortTitle) {
    // const newSorted = sortByPropTitle(bysort, true)(list);
    // state.list = newSorted;
    state.listSort.asc = true;
    state.listSort.sort = bySortTitle;
    state.listSort.path = bySortPath;
  } else {
    // const newSorted = sortByPropTitle(bysort, !asc)(list);
    // state.list = newSorted;
    state.listSort.asc = !asc;
  }
  // state.page = 1;
}

// upload: {file:{},name:'',version:'',isUpdate:null , progress}

const serachProp = (prop, val) =>
  R.compose(R.includes(val), R.toLower, R.prop(prop));

// const sortByPropDate = (prop, asc) =>
//   asc
//     ? R.sortWith([R.ascend(R.compose((item) => new Date(item), R.prop(prop)))])
//     : R.sortWith([
//         R.descend(R.compose((item) => new Date(item), R.prop(prop))),
//       ]);

const byIsFolder = R.groupBy((content) => {
  const { is_folder, is_deleted } = content;
  return is_deleted ? "deleted" : is_folder ? "folders" : "files";
});

function setContentBySearch(state, action) {
  const { type, val, asc = true, search } = action.payload;
  const { contents } = state.content;
  let newContents = [];
  switch (type) {
    case "all":
      newContents = contents;
      // newContents = R.filter((item) => !item.is_deleted)(contents);
      break;
    case "sort":
      newContents = sortByPropTitle([val], asc)(contents);
      break;
    default:
      newContents = R.filter(serachProp("title", search))(contents);
      newContents = sortByPropTitle([val], asc)(newContents);
      break;
  }

  const dividedContents = byIsFolder(newContents);

  state.content.files = dividedContents.files;
  state.content.folders = dividedContents.folders;
  state.asc = asc;
}

const checkPropInArrOfObjects = (prop, arr, value) => {
  return R.find(R.propEq(prop, value))(arr) ? true : false;
};

const librarySlice = createSlice({
  name: "library",
  initialState,
  reducers: {
    setContents: setCurrentFolder,

    setRoute: (state, action) => {
      const { content, idx } = action.payload;
      if (idx === "push") {
        state.navs.push(content);
      } else if (idx === 0) {
        state.navs = [content];
      } else {
        state.navs = R.slice(0, idx + 1, state.navs);
      }
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setShowType: (state, action) => {
      state.showType = action.payload;
    },
    setUnpublishedContenst: (state, action) => {
      state.unPublished = action.payload;
    },
    setDrawerContent: (state, action) => {
      state.drawerContent = action.payload;
    },
    searchContent: setContentBySearch,

    setTagsInLibrary: (state, action) => {
      state.tags = action.payload;
    },
    changeDrawerContent: (state, action) => {
      state.drawerContent = action.payload;
    },
    setCutStatus: (state, action) => {
      state.cutStatus = action.payload;
    },
    setAsc: (state, action) => {
      state.asc = action.payload;
    },
    setCutContent: (state, action) => {
      const { current, content } = action.payload;
      state.cutStatus = FAILEDCUT;
      state.cutContent = content;
      state.cutFrom = current;
    },
    setCutClose: (state) => {
      state.cutStatus = INITCUT;
      state.cutContent = {};
      state.cutFrom = {};
    },

    // upload
    addFileForUpload: (state, action) => {
      const { files } = action.payload;
      const upload = state.upload;

      const maker = R.map((file) => {
        const { name } = file;
        const removedTypeName = R.toLower(R.join("")(R.remove(-4, 4, name)));
        const valid = !checkPropInArrOfObjects("name", upload, removedTypeName);

        if (!valid) toast.warn("Same name exist");

        return valid
          ? {
              file,
              isRevised: false,
              revisedFile: null,
              progress: 0,
              name: removedTypeName,
              version: "",
              verValidation: true,
              titleValidation: valid,
              cancelToken: axios.CancelToken.source(),
              fileStatus: INITFILE,
            }
          : null;
      })(files);

      const filterNull = R.filter((item) => item !== null)(maker);
      // const
      state.uploadStutus = ONCHECK;
      state.upload = [...upload, ...filterNull];
    },

    changeFileUploadName: (state, action) => {
      const { idx, value } = action.payload;
      const uploadList = state.upload;
      const newList = R.remove(idx, 1)(uploadList);
      const valid = !checkPropInArrOfObjects("name", newList, value);

      // isRevised: false, revisedFile: upItem ? upItem : null
      state.uploadStutus = ONCHECK;

      state.upload[idx].isRevised = false;
      state.upload[idx].revisedFile = null;
      state.upload[idx].titleValidation = value ? valid : false;
      state.upload[idx].name = R.toLower(value);
    },

    changeFileUploadVersion: (state, action) => {
      const { idx, value } = action.payload;
      const { revisedFile } = state.upload[idx];
      if (revisedFile) {
        const revisedVersions = [
          revisedFile?.version,
          ...R.map((arch) => arch.version)(revisedFile.archives),
        ];

        const valid = R.find(R.equals(value))(revisedVersions);
        state.upload[idx].checkVersion = valid === undefined ? true : false;
      } else {
        state.upload[idx].checkVersion = true;
      }
      // const revisedVersion = revisedFile?.version || null;

      state.upload[idx].version = value;
    },

    checkVersion: (state, action) => {
      const { idx, value } = action.payload;
      if (!value) state.upload[idx].checkVersion = false;
    },

    setFileStatus: (state, action) => {
      const { idx, status } = action.payload;

      state.upload[idx].fileStatus = status;
    },

    setFileRevised: (state, action) => {
      const { idx, isRevised } = action.payload;
      state.upload[idx].isRevised = isRevised;
    },

    setFileUploadProgerss: (state, action) => {
      const { idx, value } = action.payload;
      if (value === 1) {
        state.upload[idx].fileStatus = ONPROGRESSFILE;
      }
      state.upload[idx].progress = value;
    },

    setFilesToUpload: (state, action) => {
      state.upload = action.payload;
    },
    removeFileFromUploadList: (state, action) => {
      const idx = action.payload;
      const upload = state.upload;
      const newList = R.remove(idx, 1)(upload);

      const uploadStutus = state.uploadStutus;

      state.uploadStutus = newList.length === 0 ? ONCHECK : uploadStutus;
      state.upload = newList;
    },

    resetUpload: (state) => {
      state.upload = [];
    },
    setUploadStatus: (state, action) => {
      state.uploadStutus = action.payload;
    },
    sortListViewBy: sortingList,
  },
});

export const { actions } = librarySlice;
export const {
  setContents,
  setRoute,
  setStatus,
  setShowType,
  setDrawerContent,
  searchContent,
  setTagsInLibrary,
  // cut
  setCutStatus,
  setCutContent,
  setCutClose,
  // upload
  addFileForUpload,
  changeFileUploadVersion,
  changeFileUploadName,
  removeFileFromUploadList,
  setFileUploadProgerss,
  resetUpload,
  setFilesToUpload,
  setUploadStatus,
  setFileStatus,
  setFileRevised,
  checkVersion,
  // checker,
  sortListViewBy,
} = actions;

// cut dispatcher

export const checkCutStatus = (newPath) => (dispatch, getState) => {
  const cutStatus = selCutStatus(getState());
  // check if init
  if (cutStatus === INITCUT) {
    return "";
  }
  // check if we are in same folder
  const fromFolder = selCutFrom(getState());
  if (fromFolder.id === newPath.id) {
    return dispatch(setCutStatus(FAILEDCUT));
  }

  // check if type folder is inside path
  const forCutContent = selCutContent(getState());
  const navIds = R.map(R.prop("id"))(selNavs(getState()));

  if (forCutContent.id === newPath.id) {
    return dispatch(setCutStatus(FAILEDCUT));
  }
  const checkContentInNavs = R.includes(forCutContent.id)(navIds);
  if (checkContentInNavs) {
    return dispatch(setCutStatus(FAILEDCUT));
  }

  dispatch(setCutStatus(ONCUT));
};

// upload dispathcers

export const progressBarHandler = (idx) => (event) => (dispatch, getState) => {
  // console.log();
  const value = Math.round((100 * event.loaded) / event.total);

  return dispatch(setFileUploadProgerss({ idx, value }));
};

export const checkVersions = () => (dispatch, getState) => {
  const uploadList = selUploadList(getState());
  const newUp = R.map((item) => {
    const { version } = item;
    const { revisedFile } = item;
    const revisedVersion = revisedFile?.version || null;

    const valid = !R.equals(revisedVersion, version);

    return {
      ...item,
      verValidation: revisedVersion ? valid : version ? true : false,
    };
  })(uploadList);
  return dispatch(setFilesToUpload(newUp));
};

const finalChecks = () => (dispatch, getState) => {
  const uploadList = selUploadList(getState());
  let condition = true;
  R.forEach((file) => {
    const { verValidation, titleValidation, revisedFile, isRevised } = file;
    if (!verValidation) {
      return (condition = false);
    }
    if (!titleValidation) {
      return (condition = false);
    }
    if (revisedFile && !isRevised) {
      return (condition = false);
    }
  })(uploadList);

  return condition
    ? dispatch(setUploadStatus(READYFORUPLOAD))
    : dispatch(setUploadStatus(ONCHECK));
};

export const addFilesWithCheck = (forUploadFiles) => async (
  dispatch,
  getState
) => {
  const upload = selUploadList(getState());
  const maker = R.map((file) => {
    const { name } = file;
    const removedTypeName = R.toLower(R.join("")(R.remove(-4, 4, name)));
    const valid = !checkPropInArrOfObjects("name", upload, removedTypeName);

    if (!valid) toast.warn("Same name exist");

    return valid
      ? {
          file,
          isRevised: false,
          revisedFile: null,
          progress: 0,
          name: removedTypeName,
          version: "",
          verValidation: true,
          checkVersion: true,
          titleValidation: valid,
          cancelToken: axios.CancelToken.source(),
          fileStatus: INITFILE,
        }
      : null;
  })(forUploadFiles);
  const filterNull = R.filter((item) => item !== null)(maker);

  const { files } = selContent(getState());

  const uploadList = [...upload, ...filterNull];

  const newUp = R.map((item) => {
    const { name, revisedFile } = item;
    const toLowerProp = (prop) => R.compose(R.toLower, R.prop(prop));

    const upItem = revisedFile
      ? revisedFile
      : R.find((file) => {
          const fileTitle = toLowerProp("title")(file);
          return R.equals(fileTitle, R.toLower(name));
        })(files);

    return {
      ...item,
      isRevised: upItem ? true : false,
      revisedFile: upItem ? upItem : null,
    };
  })(uploadList);

  return dispatch(setFilesToUpload(newUp));
};

export const checkAllVersionsNull = () => (dispatch, getState) => {
  const uploadList = selUploadList(getState());
  let val = true;
  const mapForEach = R.addIndex(R.forEach);

  mapForEach((item, idx) => {
    if (!item.version) return (val = false);
    // dispatch(checkVersion({ value: item.version, idx }));
  })(uploadList);
  return val;
};

export const checkAllVersions = () => (dispatch, getState) => {
  const uploadList = selUploadList(getState());

  const mapForEach = R.addIndex(R.forEach);
  mapForEach((item, idx) => {
    dispatch(checkVersion({ value: item.version, idx }));
  })(uploadList);

  const allVersionStatus = R.map((item) => item.checkVersion)(uploadList);
  const falsiVersion = R.find(R.equals(false))(allVersionStatus);
  return falsiVersion === undefined ? true : false;
};

export const checkCompleteUpload = () => (dispatch, getState) => {
  const uploadList = selUploadList(getState());
  const allFileStatus = R.map((item) => item.fileStatus)(uploadList);
  const onProgressContain = R.find(R.equals(ONPROGRESSFILE))(allFileStatus);

  if (onProgressContain === undefined) {
    return dispatch(setUploadStatus(DONEUPLOAD));
  }

  return "";
};
export const stopUpload = () => async (dispatch, getState) => {
  const uploadList = selUploadList(getState());

  R.forEach(async (item) => {
    await item.cancelToken.cancel();
  })(uploadList);
  dispatch(setUploadStatus(ONCHECK));
  return dispatch(setFilesToUpload([]));
};

export const checkFilesForUpload = () => async (dispatch, getState) => {
  const { files } = selContent(getState());
  const uploadList = selUploadList(getState());

  const newUp = R.map((item) => {
    const { name, revisedFile, isRevised } = item;
    const toLowerProp = (prop) => R.compose(R.toLower, R.prop(prop));

    const upItem = revisedFile
      ? revisedFile
      : R.find((file) => {
          const fileTitle = toLowerProp("title")(file);
          return R.equals(fileTitle, R.toLower(name));
        })(files);

    return {
      ...item,
      isRevised: revisedFile ? isRevised : false,
      revisedFile: upItem ? upItem : null,
    };
  })(uploadList);

  dispatch(setFilesToUpload(newUp));
  dispatch(checkVersions());
  return dispatch(finalChecks());
};

// selectors

export const selLibraryStatus = (state) => state.library.status;
export const selContent = (state) => state.library.content;
export const selDeleted = (state) => state.library.content.deleted;
export const selNavs = (state) => state.library.navs;
export const selUnpublished = (state) => state.library.unPublished;
export const selShowType = (state) => state.library.showType;
export const selDrawerContent = (state) => state.library.drawerContent;
export const selTagsInLibrary = (state) => state.library.tags;
export const selCutStatus = (state) => state.library.cutStatus;
export const selCutFrom = (state) => state.library.cutFrom;
export const selCutContent = (state) => state.library.cutContent;

export const selLibraryAsc = (state) => state.library.asc;
export const selLibraryListViewSorter = (state) => state.library.listSort;

// upload selectors

export const selUploadList = (state) => state.library.upload;
export const selUploadStatus = (state) => state.library.uploadStutus;
const reducer = {
  library: librarySlice.reducer,
};

export default reducer;
