import { setLoaderApp } from "store/loader/loaderSlice";
import axiosInstance from "./axiosConfig";
import { loaderStore } from "store/loader/loaderStore";
/* -------------------------------------------------------------------------- */
const setLoading = setLoaderApp;
const dispatch = loaderStore.dispatch;

export const uploadAPI = (file, uploadProgress, is_public) => {
  let formData = new FormData();
  formData.append("file", file);
  let percentCompleted = 0;
  let configObj = {
    timeout: 300000,
    headers: {
      "Content-Type": "multipart/form-data",
      design: "Enroute Backoffice",
    },
    onUploadProgress: (progressEvent) => {
      if (uploadProgress) {
        if (progressEvent.total) {
          percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        }
        console.warn({ percentCompleted });
        uploadProgress(percentCompleted);
      }
    },
  };
  dispatch(setLoading(true));

  return axiosInstance
    .post("filemanager/upload", formData, configObj)
    .then((res) => {
      dispatch(setLoading(false));

      return res;
    })
    .catch((err) => {
      let errorCode = 0;
      let result_number = null;
      if (err?.response?.status) {
        errorCode = err.response.status;
      }
      if (err?.response?.data) {
        result_number = err.response.data.result_number;
      }
      return Promise.reject({
        ecode: "Services:APIs:File:Upload:2",
        err,
        errorCode,
        result_number,
      });
    });
};
/* -------------------------------------------------------------------------- */
export const downloadFileAPI = (fileId) => {
  dispatch(setLoading(true));
  return axiosInstance
    .get(`filemanager/download/public/${fileId}`, {
      responseType: "blob",
      headers: {
        design: "Enroute Backoffice",
      },
    })
    .then((res) => {
      dispatch(setLoading(false));

      return res.data;
    })
    .catch((err) => {
      let errorCode = 0;
      let result_number = null;
      if (err?.response?.status) {
        errorCode = err.response.status;
      }
      if (err?.response?.data) {
        result_number = err.response.data.result_number;
      }
      let errorResponse = {
        ecode: "Services:APIs:File:Upload:2",
        err,
        errorCode,
        result_number,
      };
      throw errorResponse;
    });
};
/* -------------------------------------------------------------------------- */
