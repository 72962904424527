import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { HelperModal } from "./components/HelperModal";
import {
  createDirectories,
  getAllDirectory,
  getDirectory,
  getDirectoryWithFilter,
} from "./slice/helperThunks";
import {
  directoryList,
  directorySelected,
  setDirectory,
  totalItem,
} from "./slice/helpersSlice";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Autocomplete, Pagination } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import { TableRow } from "./components/TableRow";
import { categories } from "./directories";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 140,
  },
}));

const Helper = () => {
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const directories = useSelector(directoryList);
  const directory = useSelector(directorySelected);
  const resultNumber = useSelector(totalItem);
  const classes = useStyles();
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      dispatch(createDirectories(categories));
    }, []);

    useEffect(() => {
      const entries = Object.entries(params).filter(([, value]) => value);
      const queryParam = entries
        .map(([key, value]) => `${key}=${value}`)
        .join("&");

      dispatch(getAllDirectory(queryParam));
    }, [params]);

    useEffect(() => {
      const timer = setTimeout(() => setDebouncedValue(value), delay || 500);
      return () => {
        clearTimeout(timer);
      };
    }, [value, delay]);
    return debouncedValue;
  }

  const debouncedSearch = useDebounce(search, 400);
  function handleSearch(e) {
    const { value } = e.target;
    setSearch(value);
  }

  useEffect(() => {
    if (search.length) {
      dispatch(getDirectoryWithFilter(debouncedSearch));
    }
  }, [debouncedSearch]);

  const handelOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSearch("");
    dispatch(setDirectory({}));
  };

  const handleSelectDirectory = (e, value) => {
    setSearch(value?.title);
    if (value === null) {
      setSearch("");
      setParams({
        page: 1,
        limit: 10,
      });
    } else {
      dispatch(getDirectory(value?.id));
    }
  };

  // ================= PAGINATION ==================

  const handlePagination = useCallback((value) => {
    setParams((params) => ({ ...params, ...value }));
  }, []);

  const setPaginationByAmountAndSize = (size, amount) => {
    if (amount % size === 0) return amount / size;
    return Math.floor(amount / size) + 1;
  };

  function onChangeSize(e) {
    const { value } = e.target;
    handlePagination({ limit: value });
  }
  function onClickPage(e, page) {
    handlePagination({ page });
  }

  return (
    <>
      <div className="page-heading d-sm-flex flex-row justify-content-sm-between z-index-0">
        <div className=" d-sm-flex flex-column justify-content-sm-between align-self-end">
          <h2 className="title">Helpers</h2>
        </div>
        <div className="d-flex flex-column align-items-start">
          <div className="d-sm-flex flex-row justify-content-sm-between">
            <FormControl style={{ width: "500px", marginBottom: "18px" }}>
              <Autocomplete
                options={directories}
                inputValue={search}
                onChange={(e, value) => handleSelectDirectory(e, value)}
                getOptionLabel={function (option) {
                  console.log(7777, option);
                  return option?.title;
                }}
                renderOption={function (option) {
                  console.log(77771, option);
                  return option?.title;
                }}
                renderInput={(params) => (
                  <TextField
                    style={{ height: "35px" }}
                    {...params}
                    variant="outlined"
                    onChange={handleSearch}
                  />
                )}
              />
            </FormControl>
          </div>
        </div>
      </div>
      <div className="jr-card">
        <div className="table-responsive-material">
          <table className="default-table table-unbordered table table-sm table-hover">
            <thead className="th-border-b ">
              <tr>
                <th className="font-weight-bold">Title Directory</th>
                <th className="font-weight-bold">Type</th>
                <th className="font-weight-bold">Description</th>
                <th className="font-weight-bold" style={{ textAlign: "end" }}>
                  Actions
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {directories?.map((directory) => {
                return (
                  <TableRow
                    key={directory?.id}
                    directory={directory}
                    handelOpenModal={handelOpenModal}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className=" d-flex flex-row justify-content-between">
        <div className="w-100 d-flex justify-content-between mt-3">
          <Pagination
            page={params?.page}
            count={setPaginationByAmountAndSize(params?.limit, resultNumber)}
            shape="rounded"
            color="primary"
            onChange={onClickPage}
          />
          <FormControl
            className={`${classes.formControl}`}
            style={{ minWidth: 140 }}
          >
            <InputLabel id="demo-simple-select-label">
              Shows per page
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={params?.limit}
              onChange={onChangeSize}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </div>
        {/* )} */}
      </div>
      <HelperModal
        directory={directory}
        handleOpenModal={openModal}
        handleClose={handleCloseModal}
      />
    </>
  );
};

export default Helper;
