import * as R from "ramda";
export const userFormModel = {
  first_name: "",
  middle_name: "",
  last_name: "",
  username: "",
  email: "",
  password: "",
  birth_date: "",
  birth_place: "",
  homebase_one: "",
  homebase_two: "",
  gender: "male",
  address: "",
  phone: "",
  cell_phone: "",
  telephone: "",
  nationality: "",
  job_title: "admin",
  code: "",
  crew_id: "",
};

export const remUserData = R.pick([
  "first_name",
  "middle_name",
  "last_name",
  "username",
  "gender",
  "homebase_one",
  "cell_phone",
  "telephone",
  "email",
  "password",
  "role",
]);
export const remUserDataCreate = R.pick([
  "first_name",
  "middle_name",
  "last_name",
  "username",
  "email",
  "password",
  "homebase_one",
  "cell_phone",
  "telephone",
  "role",
]);

export const userGeneralForm = {
  first_name: "",
  middle_name: "",
  last_name: "",
  username: "",
  email: "",
  password: "",
  birth_date: new Date(),
  birth_place: "",
  homebase_one: "",
  gender: "",
  address: "",
  homebase_two: "",
  code: "",
  crew_id: "",
};
