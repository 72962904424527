import loaderClient from "api/reduxLoaderApi";
import { dictionary } from "./module.mode";

import * as R from "ramda";
import { setModules, selModByKey, selAirlineInModule } from "./moduleSlice";
import { format, compareAsc } from "date-fns";
import { toast } from "react-toastify";
// const { apiCall } = middleActions;

export const getListModulesByAirlineId = ({ id }) => async (dispatch) => {
  const client = loaderClient("get")(`airlines/${id}/modules?limit=all`);
  const res = await client({})({});

  if (res.error) return toast.error(res.message);
  const modules = R.reduce((acc, m) => {
    const mKey = dictionary[m.module];
    if (!mKey) return acc;
    return { ...acc, [mKey]: { ...m, exist: true } };
  })({})(res.message);

  return dispatch(setModules(modules));
};
// ------------------------------------------------------------------------------------
export const getAllModules = async () => {
  const client = loaderClient("get")(`enroute-modules`);
  const res = await client({})({});

  if (res.error) return toast.error(res.message);
  return res;
};
// ----------------------------------------------------------------------------------
export const addModules = ({ key, date, enabled }, callback = false) => async (
  dispatch,
  getState
) => {
  const mod = selModByKey(key)(getState());
  const settings = mod.settings || {};
  const exist = mod.exist;
  const name = mod.module;
  const airline = selAirlineInModule(getState());

  if (!exist) {
    const client = loaderClient("post")(`airlines/${airline}/modules`);

    const data = {
      module: name,
      expiration_date: format(new Date(date), "yyyy-MM-dd"),
      settings,
      enabled,
    };
    const res = await client(data)({});
    if (res.error) return toast.error(res.message);
    dispatch(getListModulesByAirlineId({ id: airline }));
  } else {
    const { id } = mod;

    const client = loaderClient("patch")(`airlines/${airline}/modules/${id}`);

    const data = {
      expiration_date: format(new Date(date), "yyyy-MM-dd"),
      settings,
      enabled,
    };

    const res = await client(data)({});

    if (res.error) return toast.error(res.message);

    dispatch(getListModulesByAirlineId({ id: airline }));
  }

  return callback && callback();
};
