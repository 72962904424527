import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Pagination } from "@material-ui/lab";

import { setPaginationByAmountAndSize } from "util/list-fns";
import {
  selAirlinePendUserList,
  selAirlinePendUserPage,
  selAirlinePendUserSize,
  selAirlinePendUserTotalItems,
  setAirlinePendUserPage,
  setAirlinePendUserSize,
} from "../../slice/airlineUserSlice";
import { getAirlinePendUsers } from "../../slice/airlineUserThunk";
import PendUserTableCell from "./penUserTableCell";

const useStyles = makeStyles(
  (theme) => ({
    headTitle: {
      fontWeight: "bold",
    },
    formControl: {
      minWidth: 140,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    iconEmpty: {
      height: 42,
      width: 80,
    },
    headerTitle: {
      marginTop: 12,
    },
  }),
  { index: 1 }
);

const AirlinePendUserTable = () => {
  const pendUsers = useSelector(selAirlinePendUserList);
  const classes = useStyles();
  const dispatch = useDispatch();
  const page = useSelector(selAirlinePendUserPage);
  const size = useSelector(selAirlinePendUserSize);
  const totalItems = useSelector(selAirlinePendUserTotalItems);
  const { id } = useParams();

  const pagination = {
    cur: page,
    all: setPaginationByAmountAndSize(size, totalItems),
  };
  const handleChangePage = (e, page) => {
    dispatch(setAirlinePendUserPage(page));
    dispatch(getAirlinePendUsers(id));
  };

  const handleChangeListSize = (e) => {
    const { value } = e.target;
    dispatch(setAirlinePendUserSize(value));
    dispatch(setAirlinePendUserPage(1));
    dispatch(getAirlinePendUsers(id));
  };

  return (
    <React.Fragment>
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <div className="row mb-md-3">
          <div className="col-12">
            <div className="jr-card">
              <div className="table-responsive-material">
                <table className="default-table table-unbordered table table-sm table-hover">
                  <thead className="th-border-b">
                    <tr>
                      <th></th>
                      <th> Fullname </th>
                      <th> Username </th>
                      <th> Known As </th>
                      <th> Email </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {pendUsers.map((item) => (
                      <PendUserTableCell user={item.user} key={item.id} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className=" d-flex flex-row justify-content-between">
              <div className=" d-flex mt-3">
                <Pagination
                  {...pagination}
                  count={pagination.all}
                  page={pagination.cur}
                  shape="rounded"
                  color="primary"
                  onChange={handleChangePage}
                />
              </div>

              <FormControl className={`${classes.formControl}`}>
                <InputLabel id="demo-simple-select-label">
                  Shows per page
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={size}
                  onChange={handleChangeListSize}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AirlinePendUserTable;
