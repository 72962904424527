import { DropzoneArea } from "material-ui-dropzone";
import React, { useState } from "react";
import ImageCropper from "./imageCropper/ImageCropper";
import { Button } from "@material-ui/core";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import { makeStyles } from "@material-ui/styles";
import { uploadAPI } from "api/fileManagerApi";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const useStyles = makeStyles({
  icon: {
    color: "red",
    width: 24,
    height: 24,
    cursor: "pointer",
    marginLeft: -25,
  },
});

const ImageUpload = (props) => {
  let { title = "Upload", link = "/files", onSubmit } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState({});

  const classes = useStyles();

  const [blob, setBlob] = useState(null);

  const [inputImg, setInputImg] = useState("");

  function getBlob(blob) {
    setBlob(blob);
  }

  function handleSubmit(data) {
    onSubmit(data);
    setIsOpen(false);
  }

  function handleDelete() {
    setInputImg("");
  }

  function onInputChange(files) {
    const file = files[0];
    setFile(file);
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        setInputImg(reader.result);
      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
    }
  }

  async function handleSubmitImage() {
    try {
      const imageUpload = await uploadAPI(blob);
      if (!imageUpload.data) return "";
      handleSubmit(imageUpload.data);
    } catch (error) {
      console.log(error);
    }
  }

  function toggle() {
    setIsOpen(false);
  }

  return (
    <>
      <Button
        startIcon={<CloudUploadOutlinedIcon />}
        variant="contained"
        color="primary"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {title}
      </Button>
      <Modal className={classes.modal} size="lg" isOpen={isOpen}>
        <ModalHeader>Image Uploader</ModalHeader>
        <ModalBody>
          <DropzoneArea
            acceptedFiles={["image/*"]}
            filesLimit={1}
            onChange={onInputChange}
            onDelete={handleDelete}
          />
          <div>
            <div className="relative">
              {inputImg && (
                <ImageCropper
                  file={file}
                  getBlob={getBlob}
                  inputImg={inputImg}
                />
              )}
              {!inputImg && (
                <div className=" w-full flex justify-center mt-8 mx-auto">
                  <div className="w-full flex flex-col justify-center items-center h-300 bg-yellow-opacity-10"></div>
                </div>
              )}

              <ModalFooter>
                {inputImg && (
                  <Button
                    onClick={handleSubmitImage}
                    variant="contained"
                    color={"primary"}
                  >
                    Upload
                  </Button>
                )}

                <Button
                  variant="outlined"
                  color="primary"
                  className="absolute h-6 w-6 rounded-md bg-yellow-opacity-10 btn-7 border-none  mb-20 mr-1 "
                  onClick={toggle}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ImageUpload;
