import ContainerHeader from "./components/UIs/ContainerHeader";
import React, { useEffect } from "react";
import AdminTable from "./adminList/AdminTable";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getAirlineSettings } from "../slice/airlineThunks";
import { selAirlineUser } from "../slice/airlinesSlice";

const Admin = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const admin = useSelector(selAirlineUser);
  useEffect(() => {
    dispatch(getAirlineSettings(id));
  }, []);
  return (
    <React.Fragment>
      <ContainerHeader
        url={`${id}/user-add`}
        showFilters={false}
        title="Admins"
        btnTitle="Add Admin"
        icon={true}
        user={admin}
      />
      <AdminTable user={admin} />
    </React.Fragment>
  );
};
export default Admin;
