import ContainerHeader from "./components/UI/ContainerHeader";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  getAirlineUserGroups,
  getAirlineUsers,
} from "./slice/airlineUserThunk";
import AirlineUserTable from "./userList/AirlineUserTable";
import { selAirlineUser, selAirlinesCurrent } from "../slice/airlinesSlice";

const AirlineUsers = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const airline = useSelector(selAirlinesCurrent);
  const admin = useSelector(selAirlineUser);

  useEffect(() => {
    dispatch(getAirlineUsers(id));
    dispatch(getAirlineUserGroups(id));
  }, [id]);

  return (
    <>
      <ContainerHeader title={`${airline.name} Users`} showFilters={true} />
      <AirlineUserTable admin={admin} />
    </>
  );
};

export default AirlineUsers;
