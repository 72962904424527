// import { setLoading } from "store/slices";
import { catcher } from "./masterApi";
import { loaderStore } from "store/loader/loaderStore";
import { setLoaderApp } from "store/loader/loaderSlice";

import * as R from "ramda";

const setLoading = setLoaderApp;

const dispatch = loaderStore.dispatch;

const clientLoader = async (method, link, data, params) => {
  dispatch(setLoading(true));
  const res = await catcher(method, link, data, params);
  dispatch(setLoading(false));
  return { ...res };
};
const clientNoLoaderCathcer = async (method, link, data, params) => {
  const res = await catcher(method, link, data, params);
  return { ...res };
};

export const clientNoLoader = R.curryN(4, clientNoLoaderCathcer);

export const loaderWithSetter = (setter) =>
  R.curryN(4, async (method, link, data, params) => {
    setter(true);
    const res = await catcher(method, link, data, params);
    setter(false);
    return { ...res };
  });

export default R.curryN(4, clientLoader);
