import React, { useState } from "react";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import {
  filterAdmin,
  filterAdminByText,
  resetAdminList,
  selAdminTableFilter,
} from "../../slices/adminSlice";
import { useDispatch, useSelector } from "react-redux";
import { ALLFITLERS } from "../../slices/adminConstants";
import { useDebounce } from "util/debounceRamda";
import { getAirlines } from "../../../slice/airlineThunks";

const useStyles = makeStyles(
  {
    formControl: {
      minWidth: 120,
    },
    item: {
      fontSize: 18,
      cursor: "pointer",
    },
  },
  { index: 1 }
);

const ContainerHeader = (props) => {
  const userFilter = useSelector(selAdminTableFilter);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  function onSearch(payload) {
    dispatch(filterAdminByText(payload));
    // dispatch(getAirlines(1, 5, payload));
  }

  const debouncedSearch = useDebounce(onSearch, 400);

  function handleSearch(e) {
    const { value } = e.target;
    setSearch(value);
    if (value.length < 3) return "";
    return debouncedSearch(value);
  }

  const { status } = userFilter;
  const classes = useStyles();

  const onAssignFilterStatus = (e) => {
    const { value } = e.target;
    const payload = {
      status: value,
    };
    dispatch(filterAdmin(payload));
    setSearch("");
  };

  const { title, url, showFilters, btnTitle, icon, user } = props;
  console.log(77755, user);

  return (
    <div className="page-heading d-sm-flex flex-row justify-content-sm-between">
      <div className=" d-sm-flex flex-column justify-content-sm-between align-self-end">
        <h2 className="title">{title}</h2>
      </div>
      <div className="d-flex flex-column align-items-start">
        <div className="d-sm-flex flex-row justify-content-sm-between">
          {showFilters ? (
            <React.Fragment>
              <FormControl className={`${classes.formControl} mr-3`}>
                <InputLabel id="demo-simple-select-label">Filter By</InputLabel>
                <Input
                  placeholder="Search"
                  value={search}
                  onChange={handleSearch}
                />
              </FormControl>

              <FormControl className={`${classes.formControl} mr-3`}>
                <InputLabel id="demo-simple-select-label">
                  User Status
                </InputLabel>
                <Select value={status} onChange={onAssignFilterStatus}>
                  <MenuItem value={ALLFITLERS}>All</MenuItem>
                  <MenuItem value={false}>Active</MenuItem>
                  <MenuItem value={true}>Deleted</MenuItem>
                </Select>
              </FormControl>
            </React.Fragment>
          ) : (
            false
          )}
          <Link className="mt-2" to={url}>
            <Button
              disabled={user ? true : false}
              className="mr-1"
              variant="contained"
              color="primary"
              startIcon={icon ? <AddIcon /> : false}
            >
              {btnTitle}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ContainerHeader;
