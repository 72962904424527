import axios from "axios";
import { getNewAccessToken } from "./masterApi";

export const BASE_URL =
  process.env.REACT_APP_BACKEND_URL || "https://apidev.bep.ir/api";

let axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 20000,
  headers: {},
});

export const axiosTokenInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 20000,
  headers: {},
});

const sendReq = async (data, token) => {
  try {
    data.headers["Authorization"] = `Bearer ${token}`;

    const response = await axiosInstance.request(data);
    return response;
  } catch (error) {}
};

export const invalidateUser = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refresh-token");
  window.location.reload();
};

let refreshTokenPromise;
const onResponseError = (error) => {
  if (error.request?.status === 401) {
    if (!refreshTokenPromise) {
      refreshTokenPromise = getNewAccessToken(error).finally(() => {
        refreshTokenPromise = undefined;
      });
    }
    return refreshTokenPromise.then((res) => {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;

      return axiosInstance.request(error.config);
    });
  }
  return Promise.reject(error);
};

axiosInstance.interceptors.response.use(function (response) {
  return response;
}, onResponseError);

axiosInstance.interceptors.request.use(
  (reqConfig) => {
    if (!reqConfig.url.includes("/token-auth")) {
      reqConfig.headers["Authorization"] = `${localStorage.getItem("token")}`;
      return reqConfig;
    }
  },
  (err) => Promise.reject(err)
);
export default axiosInstance;
