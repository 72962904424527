import React, { useEffect } from "react";
import UserTable from "./userList/UserTable";
import ContainerHeader from "./components/UI/ContainerHeader";
import { useDispatch } from "react-redux";
import { getAllUsers } from "./slice/userThunk";

const Users = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  return (
    <>
      <ContainerHeader title={"Users"} showFilters={true} btnTitle={""} />
      <UserTable />
    </>
  );
};

export default Users;
