import { updateWindowWidth } from "actions";
import React from "react";
import { useDispatch } from "react-redux";
import SettingCategory from "./components/content/SettingCategory";
import Header from "./components/header";
// import loaderClient from "api/reduxLoaderApi";

const settings = [
  {
    id: 0,
    title: "General Settings",
    childs: [
      // {
      //   id: 1,
      //   title: "Library",
      //   icon: "zmdi zmdi-folder",
      //   url: "/app/dashboard/library",
      // },
      {
        id: 2,
        title: "Tags",
        icon: "zmdi zmdi-label-alt-outline",
        url: "/dashboard/tags",
      },
      {
        id: 3,
        title: "Airline",
        icon: "zmdi zmdi-flight-takeoff zmdi-24",
        url: "/dashboard/airline",
      },
      {
        id: 4,
        title: "Users",
        icon: "zmdi zmdi-account-o",
        url: "/dashboard/users",
      },
      {
        id: 5,
        title: "Reported Bugs",
        icon: "zmdi zmdi-assignment",
        url: "/dashboard/reportedbug",
      },
      {
        id: 5,
        title: "Helpers",
        icon: "zmdi zmdi-pin-help",
        url: "/dashboard/help",
      },
      // {
      //   id: 4,
      //   title: "Admin Privileges",
      //   icon: "zmdi zmdi-folder-person",
      //   url: "/app/dashboard/admin-privileges",
      // },
    ],
  },
  // {
  //   id: 1,
  //   title: "Fleet",
  //   childs: [
  //     {
  //       id: 0,
  //       title: "Fleet",
  //       icon: "zmdi zmdi-flight-takeoff zmdi-24",
  //       url: "/app/dashboard/fleet",
  //     },
  //   ],
  // },
];

const RenderCategories = (props) => {
  const { settings } = props;
  return (
    <>
      {settings.map((item, idx) => {
        return <SettingCategory item={item} key={`${item.url}_idx`} />;
      })}
    </>
  );
};

function Settings({ match }) {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(dispatch(updateWindowWidth(window.innerWidth)));
  }, []);
  return (
    <React.Fragment>
      {/* <button onClick={testRefresh}>show test refresh</button> */}
      {/* <Header /> */}
      <RenderCategories settings={settings} />
      {/* {RenderCategories()} */}
    </React.Fragment>
  );
}
export default Settings;
