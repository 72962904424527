import {
  setAdminsList,
  setCurrentAdmin,
  setAdmin,
  selAdminId,
  setAdminPage,
  setAdminListSize,
  setTotalItems,
  setAdminGroup,
} from "./adminSlice";

import loaderClient from "api/reduxLoaderApi";
import * as R from "ramda";
import { remUserData } from "./models/userModel";
import { toast } from "react-toastify";
import moment from "moment";

export const getAirlineAdmins = (airline, groupId, page, size) => async (
  dispatch
) => {
  const client = loaderClient("get")(
    `/user-assignments?limit=${size}&airline=${airline}&groups=${groupId}&page=${page}`
  );
  const res = await client({})({});
  if (res.error) return toast.error(res.message);
  return dispatch(
    setAdminsList({
      users: res.message,
      totalItems: res.result_number,
      listSize: size,
      page,
    })
  );
};
export const getAdminById = (id) => async (dispatch) => {
  const client = loaderClient("get")(`/users/${id}`);
  const res = await client({})({});
  if (res.error) return toast.error(res.message);

  return dispatch(
    setCurrentAdmin({
      ...res.message.user,
    })
  );
};

export const setAdminForEdit = (id) => async (dispatch) => {
  const client = loaderClient("get")(`/users/${id}`);
  const res = await client({})({});
  // console.log(res.message);
  if (res.error) return toast.error(res.message);
  return dispatch(
    setAdmin({ ...res.message.user, password: "*****", homebase_one: "****" })
  );
};

export const editAdmin = (data, callback = false) => async (
  dispatch,
  getState
) => {
  const id = selAdminId(getState());
  const noPass = R.omit(["password", "homebase_one"])(data);
  const validData = R.reject(R.equals(""), noPass);
  const client = loaderClient("patch")(`/users/${id}`);
  const res = await client(remUserData(validData))({});
  if (res.error) return toast.error(res.message);
  setAdminForEdit(res.message.id);
  return callback && callback();
  // return dispatch(getAllUsers());
};

export const deleteAdmin = (id, callback = false) => async (dispatch) => {
  const client = loaderClient("delete")(`/users/${id}`);
  const res = await client({})({});
  if (res.error) return toast.error(res.message);
  return callback && callback();
};

export const createAdmin = (
  airlineId,
  assignmentBody,
  userBody,
  callback = false
) => async (dispatch) => {
  const client = loaderClient("post")(`/admin/${airlineId}`);
  const validUserData = R.reject(R.equals(""), userBody);

  const res = await client({
    assignmentBody,
    userBody: validUserData,
  })({});
  if (res.error) return toast.error(res.message);
  return callback && callback(res);
};

export const getAdminPermissionGroup = (id, callback = false) => async (
  dispatch
) => {
  const client = loaderClient("get")(
    `/groups?airline=${id}&fields=title,description&title=admin`
  );
  const res = await client({})({});
  if (res.error) return toast.error(res.message);
  console.log(9992, res.message);
  dispatch(setAdminGroup(res.message[0]));
  // dispatch(getAirlineAdmins(id, res.message[0].id, 1, 5));
};

export const assignAdminToAirline = (id, data, callback = false) => async (
  dispatch
) => {
  const client = loaderClient("post")(`/users/${id}/assignments`);
  const res = await client(data)({});
  if (res.error) return toast.error(res.message);
  return callback && callback(res);
};
