import { Avatar } from "@material-ui/core";
import { downloadFileAPI } from "api/fileManagerApi";
import React, { useEffect, useState } from "react";
export function AirlineLogo({ imageId, style }) {
  const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
    if (imageId)
      downloadFileAPI(imageId)
        .then((res) => {
          let url = URL.createObjectURL(res);
          setImageUrl(url);
        })
        .catch((err) => {});
  }, [imageId]);

  return <Avatar src={imageUrl} />;
}
