import React, { useEffect, useState } from "react";

import AirlinesTableCell from "./AirlinesTableCell";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Drawer,
  IconButton,
  Divider,
} from "@material-ui/core";

import { Pagination } from "@material-ui/lab";

import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";

import CloseIcon from "@material-ui/icons/Close";
import * as R from "ramda";
import { getAirlines, getAirports } from "../slice/airlineThunks";
import {
  selAirlineFilter,
  selAirlineSize,
  selAirlinesList,
  selAirlineSort,
  selAirlineTotalItems,
  setAirlineListPage,
  setAirlineListSize,
  setAirlineSort,
} from "../slice/airlinesSlice";
import {
  getItemsBySizeAndPage,
  setPaginationByAmountAndSize,
} from "util/list-fns";
import { getAllModules } from "store/slices/moduleSlice/moduleThunk";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  headTitle: {
    fontWeight: "bold",
  },
  formControl: {
    minWidth: 140,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  iconEmpty: {
    height: 42,
    width: 80,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    marginTop: 70,
  },
  content: {
    flexGrow: 1,
    padding: `0 ${theme.spacing(3)}`,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 416,
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  headerTitle: {
    marginTop: 12,
  },
}));

const AirlinesTable = (props) => {
  // hooks and initalize
  const classes = useStyles();
  const dispatch = useDispatch();
  const sorter = useSelector(selAirlineSort);
  const [allAirLineModules, setAllAirLineModules] = useState([]);
  // init get all airlines

  useEffect(() => {
    dispatch(getAirlines());
  }, []);

  useEffect(() => {
    const getData = async () => {
      const airlineModules = await getAllModules();
      setAllAirLineModules(airlineModules);
    };
    getData();
  }, []);

  // list items page and size
  const items = useSelector(selAirlinesList);
  const filter = useSelector(selAirlineFilter);
  const { page, size } = filter;
  const totalItems = useSelector(selAirlineTotalItems);

  const pagination = {
    cur: page,
    all: setPaginationByAmountAndSize(size, totalItems),
  };

  const pageSize = useSelector(selAirlineSize);

  // methods
  const handleSortBy = (name, asc) => {
    const sortString = `${name}:${asc ? "asc" : "desc"}`;
    dispatch(setAirlineSort(sortString));
    dispatch(getAirlines());
    dispatch(setAirlineSort(sortString));
  };

  const handleChangeListSize = (e) => {
    const { value } = e.target;
    dispatch(setAirlineListSize(value));
    dispatch(getAirlines());
  };

  const handleChangePage = (e, page) => {
    dispatch(setAirlineListPage(page));
    dispatch(getAirlines());
  };

  return (
    <React.Fragment>
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <div className="row mb-md-3">
          <div className="col-12">
            <div className="jr-card">
              <div className="table-responsive-material">
                <table className="default-table table-unbordered table table-sm table-hover">
                  <thead className="th-border-b">
                    <tr>
                      {/* <th></th> */}
                      <HeaderTH
                        onClick={handleSortBy}
                        name={"logo"}
                        // title={"Logo"}
                      />
                      <HeaderTH
                        name={"name"}
                        title={"Name"}
                        onClick={handleSortBy}
                        sorter={sorter}
                      />

                      <HeaderTH
                        onClick={handleSortBy}
                        name={"callsign"}
                        title={"Callsign"}
                        sorter={sorter}
                      />
                      <HeaderTH
                        onClick={handleSortBy}
                        name={"status"}
                        title={"Status"}
                        sorter={sorter}
                      />
                      <HeaderTH
                        onClick={handleSortBy}
                        name={"icao_code"}
                        title={"ICAO code"}
                        sorter={sorter}
                      />
                      <HeaderTH
                        onClick={handleSortBy}
                        name={"iata_code"}
                        title={"IATA code"}
                        sorter={sorter}
                      />

                      <HeaderTH
                        onClick={handleSortBy}
                        name={"email"}
                        title={"Email"}
                        sorter={sorter}
                      />

                      <HeaderTH
                        onClick={handleSortBy}
                        name={"phone"}
                        title={"Phone"}
                        sorter={sorter}
                      />

                      <HeaderTH
                        onClick={handleSortBy}
                        name={"created_time"}
                        sorter={sorter}
                        title={"Created Time"}
                      />

                      {/* <HeaderTH
                          showSort={true}
                          asc={false}
                          name={"notes"}
                          title={"Notes"}
                        /> */}
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {items?.map((item) => {
                      return (
                        <AirlinesTableCell
                          key={item.id}
                          data={item}
                          airlineModules={allAirLineModules}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className=" d-flex flex-row justify-content-between">
              <div className=" d-flex mt-3">
                <Pagination
                  {...pagination}
                  count={pagination.all}
                  page={pagination.cur}
                  shape="rounded"
                  color="primary"
                  onChange={handleChangePage}
                />
              </div>

              <FormControl className={`${classes.formControl}`}>
                <InputLabel id="demo-simple-select-label">
                  Shows per page
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={size}
                  onChange={handleChangeListSize}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const HeaderTH = (props) => {
  const classes = useStyles();
  const { title, name, sorter, onClick } = props;
  const [showSort, setShowSort] = useState(false);
  const [asc, setAsc] = useState(false);

  useEffect(() => {
    setShowSort(name === sorter);
  }, [sorter]);

  function onHeaderClick() {
    // if (name === sorter) {
    setAsc(!asc);
    // }
    onClick(name, asc);
  }
  return (
    <th
      onMouseEnter={() => {
        setShowSort(true);
      }}
      onMouseLeave={() => {
        if (sorter !== name) {
          setShowSort(false);
        }
      }}
      onClick={onHeaderClick}
    >
      <div className={showSort ? classes.headTitle : ""}>
        {title}
        {!showSort ? (
          ""
        ) : !asc ? (
          <i className={`ml-2 zmdi zmdi-long-arrow-up`} />
        ) : (
          <i className={`ml-2 zmdi zmdi-long-arrow-down`} />
        )}
      </div>
    </th>
  );
};

export default AirlinesTable;
