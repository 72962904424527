import React, { useCallback, useState } from "react";
import Cropper from "react-easy-crop";
import "react-image-crop/dist/ReactCrop.css";
import { getCroppedImg } from "./cropImage";

const ImageCropper = (props) => {
  const { getBlob, inputImg } = props;

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const onCropComplete = useCallback(async (croppedArea, croppedAreaPixels) => {
    const blob = await getCroppedImg(inputImg, croppedAreaPixels);
    getBlob(blob);
  });

  return (
    <div
      style={{
        position: "relative",
        height: 300,
        width: 300,
      }}
    >
      <Cropper
        image={inputImg}
        cropShape="round"
        crop={crop}
        zoom={zoom}
        aspect={1}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
      />
    </div>
  );
};

export default ImageCropper;
