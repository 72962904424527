const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  airlineList: [],
  bugReportedList: [],
  usersList: [],

  filter: {
    page: 1,
    size: 5,
    status: "all",
  },
  totalItems: 0,
  // pendUserFilter: {
  //     page: 1,
  //     size: 5,
  // },
};
const bugReportedslice = createSlice({
  name: "bugReport",
  initialState,
  reducers: {
    setBugReportedList: (state, action) => {
      state.bugReportedList = action.payload;
    },
    setUpdatedBugReported: (state, action) => {
      state.UpdatedListOfBugReported = action.payload;
    },
    setAirlinesName: (state, action) => {
      state.airlineList = action.payload;
      // console.log("airlinename", action.payload);
    },
    setUsersList: (state, action) => {
      state.usersList = action.payload;
      console.log("userspa", action.payload);
    },
    setAirlineBugTotalItems: (state, action) => {
      state.totalItems = action.payload;
    },
    setAirlineBugFilterPage: (state, action) => {
      state.filter.page = action.payload;
    },
    setAirlineBugFilterSize: (state, action) => {
      state.filter.page = action.payload;
    },
  },
});
const { actions } = bugReportedslice;
export const {
  setBugReportedList,
  setUpdatedBugReported,
  setAirlineBugTotalItems,
  setAirlineBugFilterPage,
  setAirlineBugFilterSize,
  setAirlinesName,
  setUsersList,
} = actions;
export const selAirlineBugTotalItems = (state) => state.bugReport.totalItems;
export const selAirlinebugFilterPage = (state) => state.bugReport.filter.page;
export const selAirlineBugFilterSize = (state) => state.bugReport.filter.size;
export const selAirlinesName = (state) => state.bugReport.airlineName;
export const selUsersList = (state) => state.bugReport.usersList;
// export const selBugReportedList = (state) => state.bugReportedslice.bugReportedList;
const reducer = {
  bugReport: bugReportedslice.reducer,
};

export default reducer;
