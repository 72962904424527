import React, { useState } from "react";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getAirlines } from "../../slice/airlineThunks";
import { useDebounce } from "util/debounceRamda";
import {
  selAirlineFilter,
  selAirlineFilterSearch,
  setAirlineFilterSearch,
  setAirlineFilterStatus,
} from "../../slice/airlinesSlice";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  item: {
    fontSize: 18,
    cursor: "pointer",
  },
}));

const ContainerHeader = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const search = useSelector(selAirlineFilterSearch);
  const filter = useSelector(selAirlineFilter);
  const { status } = filter;
  const { title, url, showFilters, btnTitle, icon } = props;

  function onSearch(payload) {
    // dispatch(filterUserByText(payload));
    dispatch(getAirlines());
  }

  const debouncedSearch = useDebounce(onSearch, 400);

  function handleSearch(e) {
    const { value } = e.target;
    dispatch(setAirlineFilterSearch(value));
    debouncedSearch(value);
  }

  const handleStatus = (e) => {
    dispatch(setAirlineFilterStatus(e.target.value));
    dispatch(getAirlines());
  };

  return (
    <div className="page-heading d-sm-flex flex-row justify-content-sm-between">
      <div className=" d-sm-flex flex-column justify-content-sm-between align-self-end">
        <h2 className="title">{title}</h2>
      </div>
      <div className="d-flex flex-column align-items-start">
        <div className="d-sm-flex flex-row justify-content-sm-between">
          {showFilters ? (
            <React.Fragment>
              <FormControl className={`${classes.formControl} mr-3`}>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select value={status} onChange={handleStatus}>
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                  <MenuItem value="deleted">Deleted</MenuItem>
                </Select>
              </FormControl>

              <FormControl className={`${classes.formControl} mr-3`}>
                <InputLabel id="demo-simple-select-label">Filter By</InputLabel>
                <Input
                  placeholder="Search"
                  value={search}
                  onChange={handleSearch}
                />
              </FormControl>
            </React.Fragment>
          ) : (
            false
          )}
          {btnTitle !== "" ? (
            <Button
              className="mr-1"
              variant="contained"
              color="primary"
              startIcon={icon ? <AddIcon /> : null}
              onClick={() => {
                history.push(url);
              }}
            >
              {btnTitle}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ContainerHeader;
