import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { AppBar } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import PropTypes from "prop-types";
import AirlineInformation from "../details/information";
import { withStyles } from "@material-ui/styles";
import AirlineUsers from "../../users";
import AirlinePendUsers from "../../users/pendUsers";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

function TabContainer({ children, dir }) {
  return (
    <div dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const DetailTabs = (props) => {
  const { theme } = props;

  const classes = useStyles();
  //TODO
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="w-100">
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Information" />
          <Tab label="Users" />
          <Tab label="Pend Users" />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChange}
      >
        <TabContainer dir={theme.direction}>
          <AirlineInformation />
        </TabContainer>

        <TabContainer dir={theme.direction}>
          <AirlineUsers />
        </TabContainer>

        <TabContainer dir={theme.direction}>
          <AirlinePendUsers />
        </TabContainer>
      </SwipeableViews>
    </div>
  );
};

DetailTabs.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withStyles(null, { withTheme: true })(DetailTabs);
