import loaderClient from "api/reduxLoaderApi";
import { toast } from "react-toastify";
import {
  setAirlinePendUserList,
  setAirlinePendUserTotalItems,
  setAirlineUsersGroup,
  setAirlineUsersList,
  setAirlineUsersTotalItems,
} from "./airlineUserSlice";

export const getAirlineUsers = (airlineId) => async (dispatch, getState) => {
  const { airlineUsers } = getState();
  console.log(8884, airlineUsers);
  let params = `?airline=${airlineId}&page=${airlineUsers.filter.page}&limit=${airlineUsers.filter.size}`;

  if (!!airlineUsers.filter.group && airlineUsers.filter.group !== "all") {
    params += `&groups=${airlineUsers.filter.group}`;
  }

  if (!!airlineUsers.filter.status && airlineUsers.filter.status !== "all") {
    params += `&${airlineUsers.filter.status}=true`;
  }

  if (!!airlineUsers.filter.search) {
    params += `&search=${airlineUsers.filter.search}`;
  }

  const client = loaderClient("get")(`/user-assignments${params}`);
  const res = await client({})({});
  if (res.error) return toast.error(res.message);

  dispatch(setAirlineUsersTotalItems(res.result_number));
  return dispatch(setAirlineUsersList(res.message));
};

export const getAirlineUserGroups = (airlineId) => async (dispatch) => {
  const client = loaderClient("get")(
    `/groups?airline=${airlineId}&fields=title,description`
  );
  const res = await client({})({});
  if (res.error) return toast.error(res.message);
  return dispatch(setAirlineUsersGroup(res.message));
};

export const getAirlinePendUsers = (airlineId) => async (dispatch) => {
  const client = loaderClient("get")(
    `/join-requests?airline=${airlineId}&status=pending`
  );
  const res = await client({})({});
  if (res.error) return toast.error(res.message);
  dispatch(setAirlinePendUserTotalItems(res.result_number));
  return dispatch(setAirlinePendUserList(res.message));
};
