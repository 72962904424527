import React from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
// import URLSearchParams from "url-search-params";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { Redirect, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import indigoTheme from "./themes/indigoTheme";
import cyanTheme from "./themes/cyanTheme";
import orangeTheme from "./themes/orangeTheme";
import amberTheme from "./themes/amberTheme";
import pinkTheme from "./themes/pinkTheme";
import blueTheme from "./themes/blueTheme";
import purpleTheme from "./themes/purpleTheme";
import greenTheme from "./themes/greenTheme";
// import darkTheme from "./themes/darkTheme";
// import asyncComponent from "util/asyncComponent";

import Notfound from "app/routes/extraPages/routes/404";

import AppLocale from "../lngProvider";
import {
  AMBER,
  BLUE,
  CYAN,
  DARK_AMBER,
  DARK_BLUE,
  DARK_CYAN,
  DARK_DEEP_ORANGE,
  DARK_DEEP_PURPLE,
  DARK_GREEN,
  DARK_INDIGO,
  DARK_PINK,
  DEEP_ORANGE,
  DEEP_PURPLE,
  GREEN,
  INDIGO,
  PINK,
} from "constants/ThemeColors";

// import MainApp from "app/index.js";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import ForgetPassword from "./ForgetPassword";

// import { setInitUrl } from "../actions/Auth";
import RTL from "util/RTL";

// import { setDarkTheme, setThemeColor } from "../actions/Setting";
import AppLayout from "./AppLayout";
import { selToken } from "store/slices";
import { WINDOW_WIDTH } from "constants/ActionTypes";
import { updateWindowWidth } from "actions";
import { ToastContainer } from "react-toastify";

// const RestrictedRoute = ({ component: Component, token, ...rest }) => {
//   if (token) return <Route {...rest} component={Component} />;
//   return (props) => (
//     <Redirect
//       to={{
//         pathname: "/signin",
//         state: { from: props.location },
//       }}
//     />
//   );
// };

const RestrictedRoute = ({ component: Component, token, ...rest }) => {
  if (!token)
    return (
      <Redirect
        to={{
          pathname: "/signin",
        }}
      />
    );

  return <Route {...rest} component={Component} />;
};

const App = (props) => {
  const dispatch = useDispatch();
  const { themeColor, darkTheme, locale, isDirectionRTL } = useSelector(
    ({ settings }) => settings
  );
  const token = useSelector(selToken);
  const isDarkTheme = darkTheme;
  const {
    match,
    // , location
  } = props;

  // useEffect(() => {
  //   // window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
  //   // if (initURL === "") {
  //   //   dispatch(setInitUrl(props.history.location.pathname));
  //   // }
  //   const params = new URLSearchParams(props.location.search);

  //   if (params.has("theme-name")) {
  //     dispatch(setThemeColor(params.get("theme-name")));
  //   }
  //   if (params.has("dark-theme")) {
  //     dispatch(setDarkTheme());
  //   }
  // }, [
  //   dispatch,
  //   initURL,
  //   props.history.location.pathname,
  //   props.location.search,
  // ]);

  const getColorTheme = (themeColor, applyTheme) => {
    switch (themeColor) {
      case INDIGO: {
        applyTheme = createMuiTheme(indigoTheme);
        break;
      }
      case CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      case DARK_INDIGO: {
        applyTheme = createMuiTheme({ ...indigoTheme, direction: "rtl" });
        break;
      }
      case DARK_CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case DARK_AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DARK_DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case DARK_PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case DARK_BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DARK_DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case DARK_GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      default:
        createMuiTheme(indigoTheme);
    }
    return applyTheme;
  };

  let applyTheme = createMuiTheme(indigoTheme);
  if (isDarkTheme) {
    document.body.classList.add("dark-theme");
    applyTheme = createMuiTheme(darkTheme);
  } else {
    applyTheme = getColorTheme(themeColor, applyTheme);
  }

  // if (location.pathname === "/") {
  //   if (token === null) {
  //     return <Redirect to={"/signin"} />;
  //   } else if (initURL === "" || initURL === "/" || initURL === "/signin") {
  //     return <Redirect to={"/app/dashboard/settings"} />;
  //   } else {
  //     // return <Redirect to={initURL} />;
  //   }
  // }
  if (isDirectionRTL) {
    applyTheme.direction = "rtl";
    document.body.classList.add("rtl");
  } else {
    document.body.classList.remove("rtl");
    applyTheme.direction = "ltr";
  }

  // fix demo
  const currentAppLocale = AppLocale[locale.locale];
  // console.log("in indexf file");
  return (
    <ThemeProvider theme={applyTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <RTL>
            <div className="app-main">
              <Switch>
                <Redirect
                  exact
                  path={match.url}
                  to={`${match.url}dashboard/settings`}
                />
                <RestrictedRoute
                  path={`${match.url}dashboard`}
                  token={token}
                  component={AppLayout}
                />
                <Route path="/signin" component={SignIn} />
                <Route path="/signup" component={SignUp} />
                <Route path="/forgot-password" component={ForgetPassword} />
                <Route component={Notfound} />
                {/* <Route
                  component={asyncComponent(() =>
                    import("app/routes/extraPages/routes/404")
                  )}
                /> */}
              </Switch>
              <ToastContainer />
            </div>
          </RTL>
        </IntlProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;
