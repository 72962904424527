import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { BASE_URL } from "api/axiosConfig";
import * as R from "ramda";
import { Chip, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import getDateFns from "util/getDateFn";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from "react-router";
import img from "../../../../../../assets/images/placeholder.jpg";

const useStyles = makeStyles((theme) => ({
  iconEmpty: {
    height: 42,
    width: 80,
  },
  deletedChip: {
    width: 60,
  },
  activeChip: {
    backgroundColor: "#3fa83f !important",
    width: 60,
  },
}));

const UserTableCell = ({ user }) => {
  const propOrNA = R.propOr("N/A");
  const classes = useStyles();
  const history = useHistory();

  const handleDetailPage = (id) => {
    history.push(`user/${id}`);
  };

  const handleEditUser = (id) => {
    history.push(`user-edit/${id}`);
  };

  return (
    <React.Fragment>
      <tr tabIndex={-1}>
        <td>
          <div>
            <Avatar
              alt={user.name}
              src={
                user.image
                  ? `${BASE_URL}filemanager/download/public/${user.image}`
                  : img
              }
            />
          </div>
        </td>
        <td>
          <div className="user-detail">
            <h5 className="user-name">
              {propOrNA("first_name")(user)}{" "}
              {user.middle_name ? user.middle_name : ""}{" "}
              {propOrNA("last_name")(user)}{" "}
            </h5>
          </div>
        </td>
        <td>
          <div className="user-detail">
            <h5 className="user-name"> {propOrNA("username")(user)} </h5>
          </div>
        </td>

        <td>
          <div className="user-detail">
            <h5 className="user-name"> {propOrNA("email")(user)} </h5>
          </div>
        </td>
        {/* <td>
          <div className="user-detail">
            <Chip
              size="small"
              className={
                user.is_deleted ? classes.deletedChip : classes.activeChip
              }
              label={R.prop("is_deleted")(user) ? "Deleted" : "Active"}
              color={R.prop("is_deleted")(user) ? "secondary" : "primary"}
            />
          </div>
        </td> */}
        <td>
          <div className="user-detail">
            <h5>
              {user.last_login
                ? getDateFns("diffFromToday")(user.last_login)
                : "-"}
            </h5>
          </div>
        </td>
        <td>
          <Tooltip title="user detail">
            <IconButton
              onClick={() => handleDetailPage(user.id)}
              color="primary"
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </td>
        <td>
          <Tooltip title="edit user">
            <IconButton onClick={() => handleEditUser(user.id)} color="primary">
              <EditIcon />
            </IconButton>
          </Tooltip>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default UserTableCell;
