import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  assignHelper,
  createHelper,
  editHelper,
  getAllDirectory,
} from "../slice/helperThunks";
import { setDirectory } from "../slice/helpersSlice";
import Editor from "./Editor";

export const HelperModal = ({ directory, handleClose, handleOpenModal }) => {
  const [helper, setHelper] = useState({});
  const dispatch = useDispatch();

  const handleChangeEditor = (key, value) => {
    setHelper((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleChangeField = (name) => {
    return function (e) {
      setHelper((prevState) => ({ ...prevState, [name]: e.target.value }));
    };
  };

  const assignHelperId = (helperId) => {
    dispatch(assignHelper(helperId, directory?.id, handleCloseModal()));
  };
  const handleCreateHelper = () => {
    dispatch(createHelper(helper, assignHelperId));
  };
  const handleEditHelper = () => {
    dispatch(
      editHelper(helper, directory?.helper?.id, handleCloseModal())
    ).then((res) => dispatch(getAllDirectory("page=1&limit=10")));
  };
  const handleCloseModal = () => {
    handleClose();
    dispatch(setDirectory({}));
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={handleOpenModal}
      onClose={handleCloseModal}
      PaperProps={{
        style: {
          maxWidth: "1000px",
        },
      }}
    >
      <DialogTitle>Helper</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              defaultValue={directory?.helper?.title}
              onChange={handleChangeField("title")}
              label="Title"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              defaultValue={directory?.helper?.type}
              style={{ width: "200px" }}
              select
              label="Type"
              variant="outlined"
              onChange={handleChangeField("type")}
            >
              <MenuItem value={"Modal"}>Modal</MenuItem>
              <MenuItem value={"Tooltip"}>Tooltip</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Editor
              value={directory?.helper?.description}
              handleChange={handleChangeEditor}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal} variant="contained" color="default">
          Cancel
        </Button>
        <Button
          onClick={directory?.helper ? handleEditHelper : handleCreateHelper}
          variant="contained"
          color="primary"
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};
