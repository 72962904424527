import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  Modal,
  Typography,
  Box,
  Button,
  TextField,
  Input,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CardMedia,
  makeStyles,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  getAirlines,
  getBugReported,
  getFilteredBugs,
  getUsers,
  updatedBugReported,
} from "./slice/bugReportedThunk";
import moment from "moment";
import { AirlineLogo } from "../airlines/airlineList/AilrlineLogo";
import { Autocomplete, Pagination } from "@material-ui/lab";
import { setPaginationByAmountAndSize } from "util/list-fns";
import {
  selAirlineBugFilterSize,
  selAirlineBugTotalItems,
  selAirlinebugFilterPage,
  setAirlineBugFilterPage,
} from "./slice/bugReportedSlice";
import { getUser } from "actions";
import { Label } from "@material-ui/icons";
import { Image } from "app/routes/components/routes/Image";
import { useFileManager } from "hooks/useFileManager";
/* -------------------------------------------------------------------------- */
const statusEnums = {
  TODO: "Todo",
  RESOLVED: "Resolved",
};
/* -------------------------------------------------------------------------- */
const BugReport = () => {
  const classes = useStyles();
  const { onDownload } = useFileManager();
  const bugReportedListForFix = useSelector(
    (state) => state.bugReport.bugReportedList
  );
  const UpdatedListOfBugReportedReturnd = useSelector(
    (state) => state.bugReport.UpdatedListOfBugReported
  );
  const AirlineNameList = useSelector((state) => state.bugReport.airlineList);
  const AirlineUsersList = useSelector((state) => state.bugReport.usersList);
  console.log("AirlineList", AirlineUsersList);
  // console.log("airline***", AirlineNameList)
  const page = useSelector(selAirlinebugFilterPage);
  const size = useSelector(selAirlineBugFilterSize);
  const totalItems = useSelector(selAirlineBugTotalItems);

  const pagination = {
    cur: page,
    all: setPaginationByAmountAndSize(size, totalItems),
  };
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedBug, setSelectedBug] = useState({});
  const [rowId, setRowId] = useState("");
  const [resolvedNote, setResolvedNote] = useState("");

  const [status, setStatus] = useState("");
  const [airline, setAirline] = useState("");
  const [created_by, setCreated_by] = useState("");
  const [params, setParams] = React.useState({
    page: 1,
    limit: 99,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBugReported());
    dispatch(getAirlines());
    dispatch(getUsers());
  }, [dispatch]);
  useEffect(() => {
    const entries = Object.entries(params).filter(([value]) => value);
    const queryParam = entries
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
    dispatch(getFilteredBugs(queryParam));
  }, [params, dispatch]);

  // ----------------------final resoleved------------------
  const handleResolvedStatus = (rowId) => {
    return () => {
      dispatch(updatedBugReported(rowId, resolvedNote, handleCloseModal));
    };
  };
  // --------------------for tik resoleved---------------
  const handleOpenModal = (id) => {
    setRowId(id);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setResolvedNote("");
  };

  const handleChangeResolveNote = ({ target: { value } }) => {
    setResolvedNote(value);
  };
  // ----------------------for Veiw detail--------------
  const handleOpen = (id) => {
    const specialBugSelected = bugReportedListForFix.find(
      (item) => item.id === id
    );
    if (!specialBugSelected) return;
    setOpen(true);
    setSelectedBug(specialBugSelected);
  };
  const handleClose = () => setOpen(false);
  const handleChangePage = (e, page) => {
    dispatch(setAirlineBugFilterPage(page));
    dispatch(getBugReported());
  };
  // ======================F I L T E R=========================
  const handleSelectAirline = useCallback((e, value) => {
    if (value !== null) {
      setParams(({ airline, ...rest }) =>
        airline === "all"
          ? { ...rest, page: 1 }
          : { ...rest, airline: value.id, page: 1 }
      );
    } else {
      // dispatch(getBugReported());
      setParams(({ airline, ...rest }) =>
        airline === "all" ? { ...rest, page: 1 } : { ...rest, page: 1 }
      );
    }
  }, []);
  const handleSelectUsers = useCallback((e, value) => {
    console.log("value", value);
    if (value !== null) {
      setParams(({ created_by, ...rest }) =>
        created_by === "all"
          ? { ...rest, page: 1 }
          : { ...rest, created_by: value.id, page: 1 }
      );
    } else {
      setParams(({ created_by, ...rest }) =>
        created_by === "all" ? { ...rest, page: 1 } : { ...rest, page: 1 }
      );
    }
  }, []);

  const handleSelectedStatus = useCallback(({ target }) => {
    const { value } = target;
    setStatus(value);
    setParams(({ status, ...rest }) =>
      value === "all"
        ? { ...rest, page: 1 }
        : { ...rest, status: value, page: 1 }
    );
  }, []);

  const renderAttachments = () => {
    if (!selectedBug?.attachments?.length) return;
    return (
      <>
        <Typography variant="body1">
          <strong>Attachments:&nbsp; </strong>
        </Typography>
        <div className={classes.totalCardMedia}>
          {selectedBug.attachments?.map((attachment, index) => {
            return (
              <div className={classes.cardMedia}>
                <Image
                  onClick={() => onDownload(attachment)}
                  imageId={attachment}
                />
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <Box>
      <Paper style={{ marginBottom: "1%", padding: "1%", display: "flex" }}>
        <FormControl
          variant="outlined"
          style={{ width: "200px", marginRight: "1%" }}
        >
          <Autocomplete
            id="combo-box-demo"
            clearOnBlur={false}
            options={AirlineNameList}
            sx={{ width: 400 }}
            onChange={handleSelectAirline}
            renderInput={(params) => (
              <TextField {...params} label="Filter Airline" />
            )}
            getOptionLabel={(option) => `${option.name}`}
            renderOption={(option) => `${option.name}`}
            disableClearable={false}
          />
        </FormControl>
        <FormControl
          variant="outlined"
          style={{ width: "200px", marginRight: "1%" }}
        >
          <Autocomplete
            clearOnBlur={false}
            disableClearable={false}
            id="combo-box-demo"
            options={AirlineUsersList}
            sx={{ width: 400 }}
            onChange={handleSelectUsers}
            renderInput={(params) => (
              <TextField {...params} label="Filter User" />
            )}
            getOptionLabel={(option) =>
              `${option.first_name} ${option.last_name}`
            }
            renderOption={(option) =>
              `${option.first_name} ${option.last_name}`
            }
          />
        </FormControl>
        <FormControl
          variant="outlined"
          style={{ width: "120px", marginRight: "1%" }}
        >
          <InputLabel>Status</InputLabel>
          <Select value={status} onChange={handleSelectedStatus} label="Status">
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="Todo">Todo</MenuItem>
            <MenuItem value="Resolved">Resolved</MenuItem>
          </Select>
        </FormControl>
      </Paper>
      {/* ===================================End of Header=================================== */}
      <Paper elevation={5}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="center">Airline Name</TableCell>
                <TableCell align="center">Creator</TableCell>
                <TableCell align="center">Create Date</TableCell>
                <TableCell align="center">Description</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>

            {bugReportedListForFix.map((item) => (
              <TableBody>
                <TableRow>
                  <TableCell>
                    <AirlineLogo imageId={item.airline.logo} />
                  </TableCell>

                  <TableCell align="center">{item.airline.name}</TableCell>
                  <TableCell align="center">
                    {item.created_by.first_name}
                    {item.created_by.last_name}
                  </TableCell>
                  <TableCell align="center">
                    {moment(item.created_time).format("MM/DD/YYYY")}
                  </TableCell>
                  <TableCell align="center">{item.description}</TableCell>
                  <TableCell align="center">{item.status}</TableCell>
                  <TableCell align="center">
                    {/* <Link to='/dashboard/bug'  > */}
                    <IconButton
                      color="primary"
                      onClick={() => handleOpen(item.id)}
                    >
                      <i class="zmdi zmdi-eye"></i>
                    </IconButton>
                    {/* </Link> */}
                    {item.status == statusEnums.TODO && (
                      <IconButton
                        color="primary"
                        onClick={() => handleOpenModal(item.id)}
                      >
                        <i class="zmdi zmdi-check"></i>
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
          </Table>
        </TableContainer>
        <Pagination
          {...pagination}
          count={pagination.all}
          page={pagination.cur}
          shape="rounded"
          color="primary"
          onChange={handleChangePage}
        />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 800,
              bgcolor: "background.paper",
              // border: '2px solid',
              boxShadow: 14,
              p: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Bug Report Detail
            </Typography>
            <div id="modal-modal-description" sx={{ mt: 2 }}>
              <Typography variant="body1">
                <strong>Name:&nbsp; </strong> {selectedBug?.airline?.name}
              </Typography>
              <Typography variant="body1">
                <strong>Created By:&nbsp; </strong>
                {selectedBug?.created_by?.first_name}{" "}
                {selectedBug?.created_by?.last_name}{" "}
              </Typography>
              <Typography variant="body1">
                <strong>Created Time:&nbsp; </strong>
                {moment(selectedBug?.created_time).format("MM/DD/YYYY")}
                &nbsp;-&nbsp;
                {moment(selectedBug?.created_time).format("hh:mm:ss")}
              </Typography>
              <Typography variant="body1">
                <strong>ID:&nbsp; </strong>
                {selectedBug?.id}
              </Typography>
              <Typography variant="body1">
                <strong>Status:&nbsp; </strong>
                {selectedBug?.status}{" "}
              </Typography>
              {selectedBug?.status === statusEnums.RESOLVED && (
                <Typography variant="body1">
                  <strong>Resolved By:&nbsp; </strong>
                  {`${selectedBug?.resolved_by?.first_name} ${selectedBug?.resolved_by?.last_name}`}
                </Typography>
              )}
              {selectedBug?.status === statusEnums.RESOLVED && (
                <Typography variant="body1">
                  <strong>Resolved Time:&nbsp; </strong>
                  {moment(selectedBug?.resolved_date).format("MM/DD/YYYY")}
                  &nbsp;-&nbsp;
                  {moment(selectedBug?.resolved_date).format("hh:mm:ss")}
                </Typography>
              )}
              <Typography variant="body1">
                <strong>Email:&nbsp; </strong>
                {selectedBug?.created_by?.email}{" "}
              </Typography>
              <Typography variant="body1">
                <strong>Description:&nbsp; </strong>
                {selectedBug?.description}
              </Typography>
              <Typography variant="body1">
                <strong>User Agent:&nbsp; </strong>
                {selectedBug?.user_agent}{" "}
              </Typography>
              {renderAttachments()}
            </div>
          </Box>
        </Modal>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "30%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 500,
              bgcolor: "background.paper",
              boxShadow: 14,
              p: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Please enter a resolve note
            </Typography>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                gap: 24,
              }}
              pt={3}
            >
              <TextField
                variant="outlined"
                label="Resoled Note"
                type="text"
                value={resolvedNote}
                onChange={handleChangeResolveNote}
                multiline
                rows={5}
                className={classes.multiline}
              />

              <Button
                color="primary"
                variant="contained"
                disabled={!resolvedNote}
                onClick={handleResolvedStatus(rowId)}
              >
                Resolve
              </Button>
            </Box>
          </Box>
        </Modal>
      </Paper>
    </Box>
  );
};

export default BugReport;
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
  totalCardMedia: {
    position: "relative",
    overflowX: "auto",
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.common.white,
    height: "171px",
    padding: theme.spacing(0.5),
    // width: "100%",
  },
  cardMedia: {
    minWidth: 250,
    maxHeight: "130px",
    marginLeft: theme.spacing(1),
    marginBlock: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    maxWidth: 250,
  },
  multiline: {
    "& .MuiInputBase-root": {
      height: 90,
      "& .MuiOutlinedInput-inputMultiline": {
        height: 70,
      },
    },
  },
}));
