import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import "assets/vendors/style";
import "react-toastify/dist/ReactToastify.css";
import MainApp from "./MainApp";
import { initSentry } from "util/sentry";
const rootEl = document.getElementById("app-site");
initSentry();
ReactDOM.render(<MainApp />, rootEl);
