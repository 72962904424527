import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Chip, IconButton, Tooltip } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
// import TagsModal from "../Modals/TagsModal";
import Checkbox from "@material-ui/core/Checkbox";
import * as R from "ramda";
import { Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import getDateFns from "util/getDateFn";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import { useParams } from "react-router";
import AlertModal from "../../../../../../../components/AlertModal";
import { useDispatch, useSelector } from "react-redux";
import { deleteAdmin, getAirlineAdmins } from "../slices/adminThunks";
import { toast } from "react-toastify";
import {
  selAdminGroup,
  selAdminPage,
  selAdminsSize,
} from "../slices/adminSlice";
import HowToRegIcon from "@material-ui/icons/HowToReg";

const useStyles = makeStyles((theme) => ({
  iconEmpty: {
    height: 42,
    width: 80,
  },
  deletedChip: {
    width: 60,
  },
  activeChip: {
    backgroundColor: "#3fa83f !important",
    width: 60,
  },
}));

const UserTableCell = (props) => {
  const { data } = props;
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  let { id } = useParams();
  const group = useSelector(selAdminGroup);
  const listSize = useSelector(selAdminsSize);
  const page = useSelector(selAdminPage);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onAccept = () => {
    dispatch(deleteAdmin(data.user.id, onDeleteFinish));
  };

  const onDeleteFinish = () => {
    dispatch(getAirlineAdmins(id, group.id, page, listSize));
    toast.info("Delete User Successfully");
    handleClose();
  };

  const history = useHistory();
  const propOrNA = R.propOr("N/A");
  const classes = useStyles();

  const handleDetailClick = () => {
    history.push(`${id}/user-detail/${data.user.id}`);
  };
  const handleEditClick = () => {
    history.push(`${id}/user-edit/${data.user.id}`);
  };

  return (
    <>
      <tr tabIndex="-1">
        <td></td>
        <td>
          <div className="user-details">
            <h5 className="user-name">
              {" "}
              {propOrNA("first_name")(data.user) +
                " " +
                propOrNA("last_name")(data.user)}
            </h5>
          </div>
        </td>
        <td>
          <div className="user-details">
            <h5 className="user-name"> {propOrNA("username")(data.user)} </h5>
          </div>
        </td>
        <td>
          <div className="user-details">
            <h5 className="user-name"> {propOrNA("email")(data.user)} </h5>
          </div>
        </td>
        <td>
          <div className="user-details">
            <h5 className="user-name"> {propOrNA("known_as")(data)} </h5>
          </div>
        </td>

        <td>
          <div className="user-detail">
            <Chip
              size="small"
              className={
                data.user.is_deleted ? classes.deletedChip : classes.activeChip
              }
              label={R.prop("is_deleted")(data.user) ? "Deleted" : "Active"}
              color={R.prop("is_deleted")(data.user) ? "secondary" : "primary"}
            />
          </div>
        </td>
        <td>
          <div className="user-detail">
            <h5>
              {data.user.last_login
                ? getDateFns("diffFromToday")(data.user.last_login)
                : "-"}
            </h5>
          </div>
        </td>
        <td>
          <Tooltip title="delete user" aria-label="delete">
            <IconButton onClick={handleOpen} color="secondary">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </td>
        <td>
          <Link>
            <Tooltip title="show detail" aria-label="delete">
              <IconButton onClick={handleDetailClick} color="primary">
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          </Link>
        </td>
        <td>
          <Tooltip title="edit user" aria-label="edit">
            <IconButton onClick={handleEditClick} color="primary">
              <EditIcon />
            </IconButton>
          </Tooltip>
        </td>
        <td>
          <IconButton>
            <HowToRegIcon />
          </IconButton>
        </td>
      </tr>
      <AlertModal
        title={`Delete User`}
        subTitle={`Are you sure want delete ${data.user.first_name} ${data.user.last_name}?`}
        open={open}
        handleClose={handleClose}
        onAccept={onAccept}
      />
    </>
  );
};

export default UserTableCell;
