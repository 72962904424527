import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  directories: [],
  directory: {},
  total: 1,
};
//

const helpersSlice = createSlice({
  name: "helpers",
  initialState,
  reducers: {
    getDirectories: (state, action) => {
      state.directories = action.payload?.message;
      state.total = action.payload?.result_number;
      console.log(8888, action.payload);
    },
    setDirectory: (state, action) => {
      state.directory = action.payload;
    },
  },
});

export const { actions } = helpersSlice;
export const { getDirectories, setDirectory } = actions;

// selectors

export const directoryList = (state) => state.helper.directories;
export const directorySelected = (state) => state.helper.directory;
export const totalItem = (state) => state.helper.total;

const reducer = {
  helper: helpersSlice.reducer,
};

export default reducer;
