import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React from "react";

const Editor = ({ value, handleChange, ...props }) => {
  const handleChangePic = (value) => {
    const description = value.getData();
    handleChange("description", description);
  };

  return (
    <>
      <div className="App">
        <CKEditor
          editor={ClassicEditor}
          onReady={(editor) => {
            if (value) {
              editor.setData(decodeEntities(value));
            }
          }}
          onChange={(event, editor) => {
            handleChangePic(editor);
          }}
          {...props}
        />
      </div>
    </>
  );
};

export default Editor;

const decodeEntities = (function () {
  const element = document.createElement("div");

  function RichTextEditor(str) {
    if (str && typeof str === "string") {
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
      element.innerHTML = str;
      str = element.textContent;
      element.textContent = "";
    }

    return str;
  }

  return RichTextEditor;
})();
