import { IconButton, makeStyles } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";
const useStyles = makeStyles({
  item: {
    width: 150,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  itemDetails: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    fontSize: 18,
    textDecoration: "none",
  },
  itemIcon: {
    fontSize: 40,
  },
});
const SettingCategoryItem = (props) => {
  const classes = useStyles();
  const { child } = props;
  return (
    <div className={`mt-1 mb-1 ${classes.item}`}>
      <Link className={`jr-list-link ${classes.itemDetails}`} to={child.url}>
        <i className={child.icon} style={{ fontSize: 28 }} />
        <div className="mt-2">{child.title}</div>
      </Link>
    </div>
  );
};
export default SettingCategoryItem;
