import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 140,
  },
  item: {
    fontSize: 18,
    cursor: "pointer",
  },
}));

const ContainerHeader = (props) => {
  const classes = useStyles();
  const { title } = props;

  return (
    <div className="page-heading d-sm-flex flex-row justify-content-sm-between">
      <div className=" d-sm-flex flex-column justify-content-sm-between align-self-end">
        <h2 className="title">{title}</h2>
      </div>
      <div className="d-flex flex-column align-items-start">
        <div className="d-sm-flex flex-row justify-content-sm-between">
          <React.Fragment>
            <FormControl className={`${classes.formControl} mr-3`}>
              {/* <InputLabel id="demo-simple-select-label">
                Search by Name
              </InputLabel> */}
              {/* <Select>
                <MenuItem value={"all"}>All</MenuItem>
              </Select> */}
            </FormControl>
          </React.Fragment>
        </div>
      </div>
    </div>
  );
};

export default ContainerHeader;
