import React from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import store, { history } from "./store";

import App from "./containers/App";
import LoaderComp from "./store/loader/LoaderApp";

import { addTokens } from "./store/slices";
import { initProfile } from "store/slices/thunks";
import { ErrorBoundary } from "util/sentry";

const token = localStorage.getItem("token");
if (token) {
  const refresh = JSON.parse(localStorage.getItem("refresh"));
  // store.dispatch(addTokens({ token, refresh }));
  store.dispatch(initProfile({ token, refresh }));
}
const MainApp = () => (
  <>
    <Provider store={store}>
      <ErrorBoundary>
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="/" component={App} />
          </Switch>
        </ConnectedRouter>
      </ErrorBoundary>
    </Provider>
    <LoaderComp />
  </>
);

export default MainApp;
