import {
  Card,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { BASE_URL } from "api/axiosConfig";
import moment from "moment";
import * as R from "ramda";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import img from "../../../../../../assets/images/placeholder.jpg";
import { selCurrentUser } from "../slice/userSlice";
import { getUserById } from "../slice/userThunk";

const useStyles = makeStyles({
  deletedChip: {
    width: 60,
  },
  activeChip: {
    backgroundColor: "#3fa83f !important",
    width: 60,
  },
  keyName: {
    fontWeight: "bold",
  },
  itemsSize: {
    fontSize: 18,
  },
  profile: {
    height: "200px",
    width: "200px",
    borderRadius: "50%",
    border: "1px solid #000",
    objectFit: "contain",
  },
  media: {
    height: 200,
    width: 200,
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
    alignSelf: "center",
    borderRadius: 100,
  },
  root: {
    flexDirection: "column",
    justifyContent: "center",
    display: "flex",
  },
  center: {
    textAlign: "center",
  },
});

const UserDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const currentUser = useSelector(selCurrentUser);
  const { user, user_assignments } = currentUser;
  const classes = useStyles();
  const propOrNA = R.propOr("N/A");

  useEffect(() => {
    dispatch(getUserById(id));
  }, [id]);

  return (
    <React.Fragment>
      {user ? (
        <Grid container spacing={3}>
          <Grid item sm={4}>
            <Card className={classes.root}>
              <CardMedia
                className={classes.media}
                image={
                  user.image
                    ? `${BASE_URL}filemanager/download/public/${user.image}`
                    : img
                }
                title={user.fist_name}
              />
              <CardContent>
                <Typography className={classes.center} variant="h5">
                  {propOrNA("first_name")(user)}{" "}
                  {user.middle_name ? user.middle_name : ""}{" "}
                  {propOrNA("last_name")(user)}{" "}
                </Typography>
                <Grid container>
                  <Grid item sm={6}>
                    <div className="mt-2">
                      <span className={classes.keyName}>Username:</span>{" "}
                      {propOrNA("username")(user)}
                    </div>

                    <div className="mt-2">
                      <span className={classes.keyName}>Gender:</span>{" "}
                      {propOrNA("gender")(user)}
                    </div>
                    <div className="mt-2">
                      <span className={classes.keyName}>Place of Birth:</span>{" "}
                      {propOrNA("birth_place")(user)}
                    </div>
                    <div className="mt-2">
                      <span className={classes.keyName}>Date of Birth:</span>{" "}
                      {user.birth_date
                        ? moment.utc(user.birth_date).format("MM/DD/YYYY")
                        : "N/A"}
                    </div>
                  </Grid>
                  <Grid item sm={6}>
                    <div className="mt-2">
                      <span className={classes.keyName}>Phone:</span>
                      {user?.telephone?.number
                        ? user?.telephone?.code + user?.telephone?.number
                        : "N/A"}
                    </div>
                    <div className="mt-2">
                      <span className={classes.keyName}>Cell Phone:</span>
                      {user?.cell_phone?.number
                        ? user?.cell_phone?.code + user?.cell_phone?.number
                        : "N/A"}
                    </div>
                    <div className="mt-2">
                      <span className={classes.keyName}>Email:</span>{" "}
                      {propOrNA("email")(user)}
                    </div>
                    <div className="mt-2">
                      <span className={classes.keyName}>Nationality:</span>{" "}
                      {propOrNA("nationality")(user)}
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={8}>
            <div className="dashboard animated slideInUpTiny animation-duration-3">
              <div className="row mb-md-3">
                <div className="col-12">
                  <div className="jr-card">
                    <div className="table-responsive-material">
                      <table className="default-table table-unbordered table table-sm table-hover">
                        <thead className="th-border-b">
                          <tr>
                            <th> Airline </th>
                            <th> Group </th>
                            <th> Rating </th>
                            <th> Code </th>
                            <th> Known as </th>
                            <th> Home Base One </th>
                            <th> Home Base Two </th>
                            <th> Status </th>
                          </tr>
                        </thead>
                        <tbody>
                          {user_assignments?.map((user_assignment) => {
                            return (
                              <tr>
                                <td> {user_assignment?.airline?.name} </td>
                                <td> - </td>
                                <td> - </td>
                                <td> {user_assignment?.code} </td>
                                <td> {user_assignment?.known_as} </td>
                                <td>{user_assignment?.homebases?.[0]?.name}</td>
                                <td>{user_assignment?.homebases?.[1]?.name}</td>
                                <td>
                                  <Chip
                                    size="small"
                                    className={
                                      user_assignment?.is_deleted
                                        ? classes.deletedChip
                                        : classes.activeChip
                                    }
                                    label={
                                      R.prop("is_deleted")(user_assignment)
                                        ? "Deleted"
                                        : "Active"
                                    }
                                    color={
                                      R.prop("is_deleted")(user_assignment)
                                        ? "secondary"
                                        : "primary"
                                    }
                                  />{" "}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      ) : null}
    </React.Fragment>
  );
};

export default UserDetail;
