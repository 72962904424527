import ContainerHeader from "./components/UIs/ContainerHeader";
import React, { useEffect } from "react";
import AirlinesTable from "./airlineList/AirlinesTable";

const Airlines = () => {
  return (
    <React.Fragment>
      <ContainerHeader
        url="/dashboard/airline-create"
        showFilters={true}
        title="Airline"
        btnTitle="Add airline"
      />
      <AirlinesTable />
    </React.Fragment>
  );
};
export default Airlines;
